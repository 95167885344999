import React, { useEffect } from "react";

import AdminLayout from "./AdminLayout";
import AuthLayout from "./AuthLayout";
import FuelStaionLayout from "./FuelStationLayout";
import Loading from "../pages/Loading";

import { useSelector, useDispatch } from "react-redux";
import { appAuthenticationMiddleware } from "../redux/middleware/authenticationMiddleware";
import {
  goToLoading,
  goToAdmin,
  goToUser,
} from "../redux/reducers/NavigationReducer";

export default function RootLayout() {
  const nav = useSelector((state) => state.navigation);
  const auth = useSelector((state) => state.authentication);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(appAuthenticationMiddleware(auth.isLoggedIn));
  }, [auth.isLoggedIn, dispatch]);

  if (nav.ACTION_TYPE === goToLoading.toString()) {
    return <Loading />;
  } else if (nav.ACTION_TYPE === goToAdmin.toString()) {
    return <AdminLayout />;
  } else if (nav.ACTION_TYPE === goToUser.toString()) {
    return <FuelStaionLayout />;
  }

  return <AuthLayout />;
}
