import React from "react";
import { useNavigate } from "react-router-dom";
import { Center, VStack, Stack, Text, Button, useToast } from "native-base";
import { useDispatch, useSelector } from "react-redux";
import { CustomInput as Input } from "../../components /CustomInput";
import Swal from "sweetalert2";
import "sweetalert2/src/sweetalert2.scss";

import { createAttendant } from "../../redux/middleware/attendantMiddleware";
import {
  createAttendantSuccess,
  createAttendantError,
  resetActionType,
} from "../../redux/reducers/AttendantReducer";

export default function CreateAttendant() {
  const dispatch = useDispatch();
  const attendantState = useSelector((state) => state.attendants);
  const toast = useToast();
  const navigate = useNavigate();

  // form data
  const [fullname, setFullname] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");

  // additional form data
  const role = "attendant";
  const isVerified = false;
  const refId = `${Math.floor(Math.random() * 90000) + 10000}`;
  const totalSales = 0;
  const totalLitreSales = 0;

  const onCreateAttendant = () => {
    if (!fullname || !phone || !email || !password || !confirmPassword) {
      toast.show({
        title: "Fields cannot be empty",
        status: "warning",
        placement: "top",
      });
      return;
    } else if (password !== confirmPassword) {
      toast.show({
        title: "Passwords do not match",
        status: "warning",
        placement: "top",
      });
      return;
    }

    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, create attendant!",
      cancelButtonText: "No, cancel!",
    }).then((result) => {
      if (result.value) {
        dispatch(
          createAttendant(
            fullname,
            phone,
            email.toLocaleLowerCase(),
            password,
            role,
            isVerified,
            refId,
            parseFloat(totalSales),
            parseFloat(totalLitreSales)
          )
        );
      }
    });
  };

  React.useEffect(() => {
    if (attendantState.ACTION_TYPE === createAttendantSuccess.toString()) {
      toast.show({
        title: attendantState.createAttendantMessage,
        status: "success",
        placement: "top",
      });
      dispatch(resetActionType());
      navigate("/attendants");
    } else if (attendantState.ACTION_TYPE === createAttendantError.toString()) {
      toast.show({
        title: attendantState.createAttendantMessage,
        status: "danger",
        placement: "top",
      });
      dispatch(resetActionType());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attendantState.ACTION_TYPE]);

  return (
    <Center py={10}>
      <VStack
        w={["90%","60%"]}
        shadow="3"
        bg="white"
        rounded="md"
        overflow="hidden"
      >
        <Center bg="teal.500" p={4}>
          <Text fontSize="2xl" color="white">
            CREATE STAFF
          </Text>
        </Center>

        <Stack py={10} alignItems="center" space={5}>
          <Input label="Full Name" value={fullname} onChangeText={setFullname} />
          <Input label="Phone" value={phone} onChangeText={setPhone} />
          <Input label="Email" value={email} onChangeText={setEmail} />
          <Input
            label="Password"
            value={password}
            onChangeText={setPassword}
            type="password"
          />
          <Input
            label="Confirm Password"
            value={confirmPassword}
            onChangeText={setConfirmPassword}
            type="password"
          />

          <Button
            colorScheme="emerald"
            onPress={onCreateAttendant}
            isLoading={attendantState.isCreateAttendantLoading}
            _loading={{ bg: "emerald.500" }}
            isLoadingText="Creating Attendant..."
          >
            CREATE ATTENDANT
          </Button>
        </Stack>
      </VStack>
    </Center>
  );
}
