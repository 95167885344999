import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import {
  Center,
  VStack,
  Stack,
  Text,
  Button,
  useToast,
  HStack,
  Select,
  CheckIcon,
  FormControl,
} from "native-base";
import { CustomInput as Input } from "../components /CustomInput";

import {
  resetActionType,
  addStaffDetailError,
  addStaffDetailSuccess,
} from "../redux/reducers/StaffReducer";
import {
  addStaffDetails,
  getAllStaffGroups,
  getAllCars,
} from "../redux/middleware/staffMiddleware";
import { useDispatch, useSelector } from "react-redux";

import Swal from "sweetalert2";
import "sweetalert2/src/sweetalert2.scss";

export default function AddStaffDetails() {
  const location = useLocation();

  const staff = useSelector((state) => state.staff);
  const dispatch = useDispatch();
  const toast = useToast();
  const navigate = useNavigate();

  //   location values
  const staffId = location.state.localId;
  const email = location.state.email;
  const totalAmountSpent = 0;
  const totalLitres = 0;
  const isDisabled = false;
  const role = "staff";
  const paymentPin = "";

  // form data
  const [fullname, setFullname] = useState("");
  const [phone, setPhone] = useState("");
  const [carNumber, setCarNumber] = useState("");
  const [group, setGroup] = useState({});

  React.useEffect(() => {
    dispatch(getAllStaffGroups());
    dispatch(getAllCars());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (staff.ACTION_TYPE === addStaffDetailError.toString()) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: staff.addStaffDetailsMessage,
        confirmButtonText: "OK",
      }).then(() => {
        dispatch(resetActionType());
      });
    }
    if (staff.ACTION_TYPE === addStaffDetailSuccess.toString()) {
      Swal.fire({
        icon: "success",
        title: "Success",
        text: staff.addStaffDetailsMessage,
      }).then(() => {
        dispatch(resetActionType());
        navigate(`/`);
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [staff.ACTION_TYPE]);

  const onSubmit = () => {
    if (!fullname || !phone || !carNumber || !group) {
      toast.show({
        title: "Please fill all the fields",
        status: "warning",
        placement: "top",
      });
    } else if (phone.match(/^\d{10}$/) === null) {
      toast.show({
        title: "Please enter a valid phone number",
        status: "warning",
        placement: "top",
      });
    } else {
      const staff = {
        staffId,
        email,
        fullname,
        phone,
        carNumber,
        spendableBalance: parseFloat(group.allocatedFunds),
        allocatedFunds: parseFloat(group.allocatedFunds),
        canExceed: group.canExceed,
        canEnterCarNumber: group.canEnterCarNumber,
        groupName: group.groupName,
        totalAmountSpent,
        totalLitres,
        remainingBalance: parseFloat(group.allocatedFunds),
        isDisabled,
        role,
        paymentPin,
        getPaymentAlert: false,
        canTransferBalance: false,
      };

      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, add staff!",
      }).then((result) => {
        if (result.value) {
          dispatch(addStaffDetails(staff, staffId));
        }
      });
    }
  };

  return (
    <Center py={10}>
      <VStack w="60%" shadow="3">
        <Center bg="red.500" p={4}>
          <Text fontSize="2xl" color="white">
            ADD STAFF DETAILS
          </Text>
        </Center>

        <Stack pt={5} space={5} px={10}>
          <HStack px={5} space={5}>
            <Text fontWeight="bold">STAFF EMAIL:</Text>

            <Text>{location.state.email}</Text>
          </HStack>

          <HStack px={5} space={5}>
            <Text fontWeight="bold">STAFF LOCALID:</Text>

            <Text>{location.state.localId}</Text>
          </HStack>
        </Stack>

        <Stack py={10} alignItems="center" space={5}>
          <Input
            label="Full Name"
            bg="red.200"
            value={fullname}
            onChangeText={setFullname}
          />
          <Input
            label="Telephone Number"
            helperText='Please input a 10 digit number (e.g. "0240000000")'
            bg="red.200"
            value={phone}
            onChangeText={setPhone}
          />

          <FormControl w={{ base: "90%", md: "80%" }}>
            <FormControl.Label>Car Number</FormControl.Label>
            <Select
              selectedValue={carNumber}
              bg="red.200"
              borderWidth="0"
              accessibilityLabel="Car Number"
              placeholder="Car Number"
              _selectedItem={{
                bg: "teal.600",
                endIcon: <CheckIcon size="5" />,
              }}
              mt={1}
              onValueChange={(itemValue) => {
                setCarNumber(
                  staff.allCars.find((car) => car.carNumber === itemValue)
                    .carNumber
                );
              }}
            >
              {staff.allCars.map((item) => (
                <Select.Item label={item.carNumber} value={item.carNumber} />
              ))}
            </Select>
          </FormControl>

          <FormControl w={{ base: "90%", md: "80%" }}>
            <FormControl.Label>Staff Group</FormControl.Label>
            <Select
              selectedValue={group.groupName}
              bg="red.200"
              borderWidth="0"
              accessibilityLabel="Choose group"
              placeholder="Choose Group"
              _selectedItem={{
                bg: "teal.600",
                endIcon: <CheckIcon size="5" />,
              }}
              mt={1}
              onValueChange={(itemValue) => {
                setGroup(
                  staff.allStaffGroups.find(
                    (group) => group.groupName === itemValue
                  )
                );
              }}
            >
              {staff.allStaffGroups.map((item) => (
                <Select.Item label={item.groupName} value={item.groupName} />
              ))}
            </Select>
          </FormControl>

          <Button
            colorScheme="emerald"
            onPress={() => onSubmit()}
            isLoading={staff.isAddStaffDetailLoading}
            _loading={{ bg: "emerald.500" }}
            isLoadingText="Creating Staff..."
          >
            ADD DETAILS
          </Button>
        </Stack>
      </VStack>
    </Center>
  );
}
