import React from "react";
import {
  Box,
  Text,
  HStack,
  Center,
  Pressable,
  Button,
  Stack,
} from "native-base";
import TimeAgo from "react-timeago";

export default function ValidateCard({
  purchaseDate,
  validatedDate,
  fullname,
  carNumber,
  amountSpent,
  transactionId,
  source,
  litres,
  pricePerLitres,
  comment,
  onComment,
}) {
  return (
    <Box
      pt={5}
      bg="gray.100"
      mx={5}
      rounded="md"
      mb={5}
      alignItems="center"
      overflow="hidden"
    >
      <HStack alignItems="center" justifyContent="space-between" w="85%">
        <TimeAgo
          live={true}
          date={purchaseDate}
          style={{ fontStyle: "Questrial", fontSize: ".8rem" }}
        />
        <Text fontSize="xs">{transactionId}</Text>
      </HStack>

      <Stack
        flexDir={{ base: "column", md: "row" }}
        my={2}
        px={5}
        space={5}
        justifyContent="space-between"
        alignItems="center"
        flexWrap="wrap"
        w="100%"
      >
        <Pressable
          bg="blue.100"
          onPress={() => source && window.open(source, "_blank")}
        >
          <img
            alt="meter"
            src={source}
            style={{
              maxWidth: 200,
              minWidth: 200,
              maxHeight: 200,
              minHeight: 200,
              borderRadius: 10,
              objectFit: "cover",
            }}
          />
        </Pressable>

        <Stack
          flex={{ base: null, md: 1 }}
          space={2}
          m={{ base: 0, md: 5 }}
          w="100%"
        >
          <HStack
            alignItems="center"
            mb={2}
            justifyContent="space-between"
            w="100%"
            space={5}
          >
            <Text>Amount</Text>
            <Text fontWeight="bold" textAlign={"right"}>
              GH₵ {parseFloat(amountSpent).toFixed(2)}
            </Text>
          </HStack>

          <HStack
            alignItems="center"
            mb={2}
            justifyContent="space-between"
            w="100%"
          >
            <Text>Car Number</Text>
            <Text fontWeight="bold" textAlign={"right"}>
              {carNumber}
            </Text>
          </HStack>

          <HStack
            alignItems="center"
            mb={2}
            justifyContent="space-between"
            w="100%"
          >
            <Text>Customer</Text>
            <Text fontWeight="bold" textAlign={"right"}>
              {fullname}
            </Text>
          </HStack>

          <HStack
            alignItems="center"
            mb={2}
            justifyContent="space-between"
            w="100%"
          >
            <Text>Litres</Text>
            <Text fontWeight="bold" textAlign={"right"}>
              {parseFloat(litres).toFixed(2)} Ltrs
            </Text>
          </HStack>

          <HStack
            alignItems="center"
            mb={2}
            justifyContent="space-between"
            w="100%"
          >
            <Text>Price Per Litres</Text>
            <Text fontWeight="bold" textAlign={"right"}>
              GH₵ {parseFloat(pricePerLitres).toFixed(2)}
            </Text>
          </HStack>
        </Stack>
      </Stack>

      <Center mt={3} mb={5}>
        {comment && <Button onPress={onComment}>see comment</Button>}
      </Center>

      <Center bg="teal.100" px={5} py="3" w="100%">
        <Text fontSize="xs" color="green.700" textAlign="center">
          Validated on {validatedDate}
        </Text>
      </Center>
    </Box>
  );
}
