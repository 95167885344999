import React from "react";
import { Link } from "react-router-dom";
import { useWindowDimensions } from "../customHooks/useWindowDimensions";
import {
  HStack,
  Pressable,
  Text,
  useBreakpointValue,
  Button,
} from "native-base";
import {
  ProSidebar,
  SidebarContent,
  SidebarHeader,
  Menu,
  MenuItem,
  SidebarFooter,
  SubMenu,
} from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import {
  FiBook,
  FiPlusSquare,
  FiMenu,
  FiLogOut,
  FiChevronLeft,
  FiUserCheck,
} from "react-icons/fi";
import { RiDashboardLine } from "react-icons/ri";
import { HiUserGroup } from "react-icons/hi";
import { BsTable } from "react-icons/bs";
import { FaCarSide } from "react-icons/fa";

// redux
import { useDispatch } from "react-redux";
import { logoutMiddleware } from "../redux/middleware/authenticationMiddleware";

import FullHeight from "react-full-height";

export default function Sidebar() {
  const { height } = useWindowDimensions();

  const dispatch = useDispatch();

  const changeCollapse = useBreakpointValue({
    base: true,
    lg: false,
  });

  const [collapsed, setCollapsed] = React.useState(false);

  return (
    <FullHeight canExceed h={height}>
      <ProSidebar
        style={{ height: "100%", fontFamily: "Questrial", zIndex: 100 }}
        // collapsed={changeCollapse ^ collapsed}
      >
        <SidebarHeader>
          <HStack py={5} px={5} alignItems="center">
            <Pressable px={1}>
              <FiMenu size={25} />
            </Pressable>
            <Text pl={2} color="white">
              {changeCollapse ^ collapsed ? "" : "ADMIN PANEL"}
            </Text>
          </HStack>
        </SidebarHeader>

        <SidebarContent>
          <Menu iconShape="square">
            <MenuItem icon={<RiDashboardLine />}>
              DASHBOARD
              <Link to="/" />
            </MenuItem>
          </Menu>

          <Menu iconShape="square">
            <SubMenu icon={<HiUserGroup />} title="MANAGE STAFF">
              <MenuItem icon={<FiBook />}>
                ALL STAFF
                <Link to="/staff" />
              </MenuItem>

              <MenuItem icon={<FiPlusSquare />}>
                CREATE NEW STAFF
                <Link to="/add-staff" />
              </MenuItem>
            </SubMenu>
          </Menu>

          <Menu iconShape="square">
            <SubMenu title="MANAGE STAFF GROUP" icon={<FiUserCheck />}>
              <MenuItem icon={<FiUserCheck />}>
                STAFF GROUPS
                <Link to="/staffGroup" />
              </MenuItem>

              <MenuItem icon={<FiPlusSquare />}>
                CREATE STAFF GROUP
                <Link to="/createstaffgroup" />
              </MenuItem>
            </SubMenu>
          </Menu>

          <Menu iconShape="square">
            <SubMenu title="MANAGE FLEET" icon={<FaCarSide />}>
              <MenuItem icon={<FaCarSide />}>
                FLEET
                <Link to="/fleet" />
              </MenuItem>
              <MenuItem icon={<FiPlusSquare />}>
                ADD NEW CAR TO FLEET
                <Link to="/add-car" />
              </MenuItem>
            </SubMenu>
          </Menu>

          <Menu iconShape="square">
            <SubMenu title="REPORTS" icon={<BsTable />}>
              <MenuItem icon={<FiBook />}>
                SALES REPORT
                <Link to="/report-sales" />
              </MenuItem>
              <MenuItem icon={<FiBook />}>
                ALLOCATION REPORT
                <Link to="/report-allocations" />
              </MenuItem>
              <MenuItem icon={<FiBook />}>
                ATTENDANT SALES DETAILS
                <Link to="/sales-details" />
              </MenuItem>
              <MenuItem icon={<FiBook />}>
                ADMIN ACTIVITY LOG
                <Link to="/report-admin-activity-log" />
              </MenuItem>
            </SubMenu>
          </Menu>

          <Menu iconShape="square" onClick={() => dispatch(logoutMiddleware())}>
            <MenuItem icon={<FiLogOut />}>LOG OUT</MenuItem>
          </Menu>
        </SidebarContent>

        {/* <SidebarFooter>
          <Menu>
            <Button
              w="10"
              ml={5}
              variant="subtle"
              colorScheme="gray"
              color="black"
              onPress={() => setCollapsed(!collapsed)}
            >
              <FiChevronLeft size={20} />
            </Button>
          </Menu>
        </SidebarFooter> */}
      </ProSidebar>
    </FullHeight>
  );
}
