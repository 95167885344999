import React from "react";

import { useNavigate } from "react-router-dom";

import { Center, VStack, Stack, Text, Button, useToast } from "native-base";
import { CustomInput as Input } from "../components /CustomInput";

// redux

import { useDispatch, useSelector } from "react-redux";
import { createCar } from "../redux/middleware/staffMiddleware";
import {
  addCarError,
  addCarSuccess,
  resetActionType,
} from "../redux/reducers/StaffReducer";

import Swal from "sweetalert2";

export default function AddCar() {
  const navigate = useNavigate();
  const toast = useToast();
  const dispatch = useDispatch();
  const staffState = useSelector((state) => state.staff);
  const authState = useSelector((state) => state.authentication);

  const [carModel, setCarModel] = React.useState("");
  const [carNumber, setCarNumber] = React.useState("");

  const onAddCar = () => {
    if (carModel === "" || carNumber === "") {
      toast.show({
        description: "Please fill all fields",
        status: "warning",
        placement: "top",
        duration: 3000,
      });
      return;
    }

    const data = {
      carModel,
      carNumber,
      dateCreated: new Date(),
    };

    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",

      cancelButtonColor: "#d33",
    }).then((result) => {
      if (result.value) {
        dispatch(createCar(data, authState.currentUser.id));
      }
    });
  };

  React.useEffect(() => {
    if (staffState.ACTION_TYPE === addCarSuccess.toString()) {
      Swal.fire({
        title: "Success",
        text: staffState.addCarMessage,
        icon: "success",
        confirmButtonText: "OK",
      }).then(() => {
        dispatch(resetActionType());
        navigate("/fleet");
      });
    } else if (staffState.ACTION_TYPE === addCarError.toString()) {
      Swal.fire({
        title: "Error",
        text: staffState.addCarMessage,
        icon: "error",
        confirmButtonText: "OK",
      }).then(() => {
        dispatch(resetActionType());
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [staffState.ACTION_TYPE]);

  return (
    <Center py={10}>
      <VStack
        w={{ base: "90%", md: "70%" }}
        shadow="3"
        bg="white"
        rounded="md"
        overflow="hidden"
      >
        <Center bg="teal.500" p={4}>
          <Text fontSize="2xl" color="white">
            ADD CAR
          </Text>
        </Center>

        <Stack py={10} alignItems="center" space={5}>
          <Input
            label="Car Model"
            value={carModel}
            onChangeText={setCarModel}
          />

          <Input
            label="Car Number"
            value={carNumber}
            onChangeText={setCarNumber}
          />

          <Button
            colorScheme="emerald"
            onPress={onAddCar}
            isLoading={staffState.isAddCarLoading}
            _loading={{ bg: "emerald.500" }}
            isLoadingText="Adding Car..."
          >
            ADD CAR
          </Button>
        </Stack>
      </VStack>
    </Center>
  );
}
