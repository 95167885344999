import { Button, HStack, Input } from "native-base";

import { MdSearch } from "react-icons/md";

// Blatant "inspiration" from https://codepen.io/Jacqueline34/pen/pyVoWr
function convertArrayOfObjectsToCSV(array) {
  let result;

  const columnDelimiter = ",";
  const lineDelimiter = "\n";
  const keys = Object.keys(array[0]);

  result = "";
  result += keys.join(columnDelimiter);
  result += lineDelimiter;

  array.forEach((item) => {
    let ctr = 0;
    keys.forEach((key) => {
      if (ctr > 0) result += columnDelimiter;

      result += item[key];
      // eslint-disable-next-line no-plusplus
      ctr++;
    });
    result += lineDelimiter;
  });

  return result;
}

// Blatant "inspiration" from https://codepen.io/Jacqueline34/pen/pyVoWr
export function downloadCSV(array) {
  const link = document.createElement("a");
  let csv = convertArrayOfObjectsToCSV(array);
  if (csv == null) return;

  const filename = "export.csv";

  if (!csv.match(/^data:text\/csv/i)) {
    csv = `data:text/csv;charset=utf-8,${csv}`;
  }

  link.setAttribute("href", encodeURI(csv));
  link.setAttribute("download", filename);
  link.click();
}

export const Export = ({ onExport }) => (
  <Button onPress={onExport} colorScheme="green">
    Export to CSV
  </Button>
);

export const FilterComponent = ({ filterText, onFilter, onClear }) => (
  <HStack>
    <Input
      id="search"
      type="text"
      placeholder="search users"
      aria-label="Search Input"
      value={filterText}
      onChange={onFilter}
      borderRadius={0}
      borderTopLeftRadius="sm"
      borderBottomLeftRadius="sm"
      _focus={{ borderColor: "gray.200" }}
    />
    <Button
      zIndex={1}
      onClick={onClear}
      isDisabled
      _disabled={{bg: "gray.500"}}
      borderRadius={0}
      colorScheme="red"
      borderTopRightRadius="sm"
      borderBottomRightRadius="sm"
    >
      <MdSearch size={20} color="white" />
    </Button>
  </HStack>
);
