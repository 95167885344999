import React from "react";
import { Link } from "react-router-dom";
import { useWindowDimensions } from "../customHooks/useWindowDimensions";
import {
  HStack,
  Pressable,
  Text,
  Button,
  useBreakpointValue,
} from "native-base";
import {
  ProSidebar,
  SidebarContent,
  SidebarHeader,
  Menu,
  MenuItem,
  SidebarFooter,
  SubMenu,
} from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import {
  FiPlusSquare,
  FiMenu,
  FiBook,
  FiLogOut,
  FiChevronLeft,
  FiUser,
} from "react-icons/fi";
import { BsTable } from "react-icons/bs";
import { RiDashboardLine } from "react-icons/ri";

// redux
import { useDispatch } from "react-redux";
import { logoutMiddleware } from "../redux/middleware/authenticationMiddleware";

import FullHeight from "react-full-height";
import { HiUserGroup } from "react-icons/hi";

export default function Sidebar() {
  const { height } = useWindowDimensions();

  const dispatch = useDispatch();

  const changeCollapse = useBreakpointValue({
    base: true,
    lg: false,
  });

  const [collapsed, setCollapsed] = React.useState(false);

  return (
    <FullHeight canExceed h={height}>
      <ProSidebar
        style={{ height: "100%", fontFamily: "Questrial" }}
        // collapsed={changeCollapse ^ collapsed}
      >
        <SidebarHeader>
          <HStack py={5} px={5} alignItems="center">
            <Pressable px={1}>
              <FiMenu size={25} />
            </Pressable>
            <Text pl={2} color="white">
              {changeCollapse ^ collapsed ? "" : "ADMIN PANEL"}
            </Text>
          </HStack>
        </SidebarHeader>

        <SidebarContent>
          <Menu iconShape="square">
            <MenuItem icon={<RiDashboardLine />}>
              Dashboard
              <Link to="/" />
            </MenuItem>
          </Menu>

          <Menu iconShape="square">
            <SubMenu title="Manage Staff" icon={<HiUserGroup />}>
              <Menu iconShape="square">
                <MenuItem icon={<FiUser />}>
                  Manage Attendants
                  <Link to="/attendants" />
                </MenuItem>
              </Menu>

              <Menu iconShape="square">
                <MenuItem icon={<FiPlusSquare />}>
                  Add New Admin <Link to="/add-admin" />
                </MenuItem>
              </Menu>

              <Menu iconShape="square">
                <MenuItem icon={<FiPlusSquare />}>
                  Add New Attendants <Link to="/add-attendant" />
                </MenuItem>
              </Menu>
            </SubMenu>
          </Menu>

          <Menu iconShape="square">
            <SubMenu title="Reports" icon={<BsTable />}>
              <MenuItem icon={<FiBook />}>
                Sales Report
                <Link to="/sales-report" />
              </MenuItem>

              <MenuItem icon={<FiBook />}>
                Sales Details
                <Link to="/sales-details" />
              </MenuItem>
            </SubMenu>
          </Menu>

          <Menu iconShape="square" onClick={() => dispatch(logoutMiddleware())}>
            <MenuItem icon={<FiLogOut />}>Logout</MenuItem>
          </Menu>
        </SidebarContent>
        {/* <SidebarFooter>
          <Menu>
            <Button
              w="10"
              ml={5}
              variant="subtle"
              colorScheme="gray"
              color="black"
              onPress={() => setCollapsed(!collapsed)}
            >
              <FiChevronLeft size={20} />
            </Button>
          </Menu>
        </SidebarFooter> */}
      </ProSidebar>
    </FullHeight>
  );
}
