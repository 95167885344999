import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router";
import {
  Center,
  VStack,
  Stack,
  Text,
  Button,
  useToast,
  FormControl,
  CheckIcon,
  Select,
  HStack,
  Pressable,
} from "native-base";
import { CustomInput as Input } from "../components /CustomInput";
import Swal from "sweetalert2";

// redux imports
import { useDispatch, useSelector } from "react-redux";
import {
  updateStaffDetails,
  blockStaff,
  unblockStaff,
  getAllStaffGroups,
} from "../redux/middleware/staffMiddleware";
import {
  resetActionType,
  updateStaffError,
  updateStaffSuccess,
  blockStaffError,
  blockStaffSuccess,
  unblockStaffError,
  unblockStaffSuccess,
} from "../redux/reducers/StaffReducer";

export default function EditStaff() {
  const navigate = useNavigate();
  const location = useLocation();
  const toast = useToast();
  const dispatch = useDispatch();
  const staffState = useSelector((state) => state.staff);
  const authState = useSelector((state) => state.authentication);

  //form state
  const [fullname, setFullname] = useState(location.state.fullname);
  const [phone, setPhone] = useState(location.state.phone);
  const [carNumber, setCarNumber] = useState(location.state.carNumber);

  //  set data
  const staffId = location.state.staffId;
  const staffBlocked = location.state.isDisabled;
  const [group, setGroup] = useState({
    groupName: location.state.groupName,
    allocatedFunds: location.state.allocatedFunds,
    canExceed: location.state.canExceed,
    canTransferBalance: location.state.canTransferBalance,
    canEnterCarNumber: location.state.canEnterCarNumber,
    getPaymentAlert: location.state.getPaymentAlert,
  });
  const [canExceed, setCanExceed] = useState(group.canExceed);
  const [canTransferBalance, setCanTransferBalance] = useState(
    group.canTransferBalance
  );
  const [canEnterCarNumber, setCanEnterCarNumber] = useState(
    group.canEnterCarNumber
  );

  React.useEffect(() => {
    dispatch(getAllStaffGroups());
  }, []);

  React.useLayoutEffect(() => {}, [
    canExceed,
    canTransferBalance,
    canEnterCarNumber,
    fullname,
    phone,
    carNumber,
  ]);

  useEffect(() => {
    if (staffState.ACTION_TYPE === updateStaffSuccess.toString()) {
      Swal.fire({
        icon: "success",
        title: "Success",
        text: staffState.updateStaffMessage,
        confirmButtonText: "OK",
      }).then(() => {
        dispatch(resetActionType());
        navigate("/staff");
        return;
      });
    } else if (staffState.ACTION_TYPE === updateStaffError.toString()) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: staffState.updateStaffMessage,
        confirmButtonText: "OK",
      }).then(() => {
        dispatch(resetActionType());
        return;
      });
    } else if (staffState.ACTION_TYPE === blockStaffSuccess.toString()) {
      Swal.fire({
        icon: "success",
        title: "Success",
        text: staffState.blockStaffMessage,
        confirmButtonText: "OK",
      }).then(() => {
        dispatch(resetActionType());
        navigate("/staff");
      });
    } else if (staffState.ACTION_TYPE === blockStaffError.toString()) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: staffState.blockStaffMessage,
        confirmButtonText: "OK",
      }).then(() => {
        dispatch(resetActionType());
        return;
      });
    } else if (staffState.ACTION_TYPE === unblockStaffSuccess.toString()) {
      Swal.fire({
        icon: "success",
        title: "Success",
        text: staffState.unblockStaffMessage,
        confirmButtonText: "OK",
      }).then(() => {
        dispatch(resetActionType());
        navigate("/staff");
      });
    } else if (staffState.ACTION_TYPE === unblockStaffError.toString()) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: staffState.unblockStaffMessage,
        confirmButtonText: "OK",
      }).then(() => {
        dispatch(resetActionType());
        return;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [staffState.ACTION_TYPE]);

  const onUpdateStaff = () => {
    if (!fullname || !phone || !carNumber) {
      toast.show({
        title: "Please fill all the fields",
        status: "warning",
        placement: "top",
      });
      return;
    }

    const data = {
      fullname: fullname,
      phone: phone,
      carNumber: carNumber,
      allocatedFunds: group.allocatedFunds,
      groupName: group.groupName,
      canExceed,
      canTransferBalance,
      canEnterCarNumber,
    };

    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, update it!",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(updateStaffDetails(staffId, data, authState.currentUser.id));
      }
    });
  };

  const onBlockStaff = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, block staff!",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(
          blockStaff(staffId, authState.currentUser.id, location.state.fullname)
        );
      }
    });
  };

  const onUnblockStaff = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, unblock staff!",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(
          unblockStaff(
            staffId,
            authState.currentUser.id,
            location.state.fullname
          )
        );
      }
    });
  };

  return (
    <Center py={10}>
      <VStack
        w={{ base: "90%", md: "70%" }}
        shadow="3"
        bg="white"
        rounded="md"
        overflow="hidden"
      >
        <Center bg="teal.500" p={4}>
          <Text fontSize="2xl" color="white">
            EDIT STAFF
          </Text>

          <Button
            position="absolute"
            left={5}
            variant="outline"
            colorScheme="gray"
            _text={{ color: "white" }}
            onPress={() => navigate("/staff")}
          >
            GO BACK
          </Button>

          {!staffBlocked ? (
            <Button
              isLoading={staffState.isBlockStaffLoading}
              isLoadingText="Blocking Staff..."
              _loading={{ color: "red.500" }}
              position="absolute"
              right={5}
              colorScheme="red"
              onPress={onBlockStaff}
            >
              BLOCK STAFF
            </Button>
          ) : (
            <Button
              isLoading={staffState.isBlockStaffLoading}
              isLoadingText="Unblocking Staff..."
              _loading={{ color: "blue.500" }}
              position="absolute"
              right={5}
              colorScheme="blue"
              onPress={onUnblockStaff}
            >
              UNBLOCK STAFF
            </Button>
          )}
        </Center>

        <Stack py={10} alignItems="center" space={5}>
          <Input
            label="Full Name"
            value={fullname}
            onChangeText={setFullname}
            disabled={staffBlocked}
          />
          <Input
            label="Phone Number"
            value={phone}
            onChangeText={setPhone}
            disabled={staffBlocked}
          />

          <FormControl w={{ base: "90%", md: "80%" }}>
            <FormControl.Label>Car Number</FormControl.Label>
            <Select
              selectedValue={carNumber}
              bg="red.200"
              borderWidth="0"
              accessibilityLabel="Car Number"
              placeholder="Car Number"
              _selectedItem={{
                bg: "teal.600",
                endIcon: <CheckIcon size="5" />,
              }}
              mt={1}
              onValueChange={(itemValue) => {
                setCarNumber(
                  staffState.allCars.find((car) => car.carNumber === itemValue)
                    .carNumber
                );
              }}
            >
              {staffState.allCars.map((item) => (
                <Select.Item label={item.carNumber} value={item.carNumber} />
              ))}
            </Select>
          </FormControl>

          <FormControl w={{ base: "90%", md: "80%" }}>
            <FormControl.Label>Select Group</FormControl.Label>
            <Select
              selectedValue={group.groupName}
              bg="red.200"
              borderWidth="0"
              accessibilityLabel="Choose group"
              placeholder="Choose Group"
              _selectedItem={{
                bg: "teal.600",
                endIcon: <CheckIcon size="5" />,
              }}
              mt={1}
              onValueChange={(itemValue) => {
                console.log(
                  staffState.allStaffGroups.find(
                    (group) => group.groupName === itemValue
                  )
                );

                setGroup(
                  staffState.allStaffGroups.find(
                    (group) => group.groupName === itemValue
                  )
                );

                setCanExceed(
                  staffState.allStaffGroups.find(
                    (group) => group.groupName === itemValue
                  ).canExceed
                );

                setCanTransferBalance(
                  staffState.allStaffGroups.find(
                    (group) => group.groupName === itemValue
                  ).canTransferBalance
                );

                setCanEnterCarNumber(
                  staffState.allStaffGroups.find(
                    (group) => group.groupName === itemValue
                  ).canEnterCarNumber
                );
              }}
            >
              {staffState.allStaffGroups.map((item) => (
                <Select.Item label={item.groupName} value={item.groupName} />
              ))}
            </Select>
          </FormControl>

          <HStack w={{ base: "90%", md: "80%" }} space={4} alignItems="center">
            <Text>Can exceed</Text>
            <Pressable
              onPress={() => setCanExceed(!canExceed)}
              bg={canExceed ? "green.500" : "red.500"}
              borderRadius="sm"
              px={5}
              py={0.5}
            >
              <Text color="white">{canExceed ? "yes" : "no"}</Text>
            </Pressable>
          </HStack>

          <HStack w={{ base: "90%", md: "80%" }} space={4}>
            <Text>Can transfer balance</Text>
            <Pressable
              onPress={() => setCanTransferBalance(!canTransferBalance)}
              bg={canTransferBalance ? "green.500" : "red.500"}
              borderRadius="sm"
              px={5}
              py={0.5}
            >
              <Text color="white">{canTransferBalance ? "yes" : "no"}</Text>
            </Pressable>
          </HStack>

          <HStack w={{ base: "90%", md: "80%" }} space={4}>
            <Text>Can enter car number</Text>
            <Pressable
              onPress={() => setCanEnterCarNumber(!canEnterCarNumber)}
              bg={canEnterCarNumber ? "green.500" : "red.500"}
              borderRadius="sm"
              px={5}
              py={0.5}
            >
              <Text color="white">{canEnterCarNumber ? "yes" : "no"}</Text>
            </Pressable>
          </HStack>

          <Button
            mt={5}
            isLoading={staffState.isUpdateStaffLoading}
            isLoadingText="Updating Staff"
            _loading={{ bg: "teal.500" }}
            onPress={onUpdateStaff}
            disabled={staffBlocked}
          >
            UPDATE STAFF
          </Button>
        </Stack>
      </VStack>
    </Center>
  );
}
