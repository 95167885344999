/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

import {
  Center,
  VStack,
  Stack,
  Text,
  Button,
  useToast,
} from "native-base";
import { CustomInput as Input } from "../../components /CustomInput";

import { useDispatch, useSelector } from "react-redux";
import { createAdmin } from "../../redux/middleware/attendantMiddleware";
import {
  resetActionType,
  createAdminSuccess,
  createAdminError,
} from "../../redux/reducers/AttendantReducer";

import Swal from "sweetalert2";
import "sweetalert2/src/sweetalert2.scss";

export default function CreateAdmin() {
  const dispatch = useDispatch();
  const attendants = useSelector((state) => state.attendants);
  const navigate = useNavigate();
  const toast = useToast();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const role = "user";

  useEffect(() => {
    if (attendants.ACTION_TYPE === createAdminSuccess.toString()) {
      Swal.fire({
        title: "Success",
        text: attendants.createStaffMessage,
        icon: "success",
        confirmButtonText: "Done",
      }).then(() => {
        dispatch(resetActionType());
        navigate("/");
      });
    }
    if (attendants.ACTION_TYPE === createAdminError.toString()) {
      Swal.fire({
        title: "Error",
        text: attendants.createStaffMessage,
        icon: "error",
        confirmButtonText: "OK",
      }).then(() => {
        dispatch(resetActionType());
      });
    }
  }, [attendants.ACTION_TYPE]);

  const onCreateStaff = () => {
    if (!email || !password || !confirmPassword) {
      toast.show({
        title: "Field cannot be empty!",
        status: "danger",
        duration: 9000,
        placement: "top",
      });
      return;
    } else if (
      email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/) === null
    ) {
      toast.show({
        title: "Please input valid email!",
        status: "danger",
        duration: 9000,
        placement: "top",
      });
      return;
    } else if (password !== confirmPassword) {
      toast.show({
        title: "Password does not match!",
        status: "danger",
        duration: 9000,
        placement: "top",
      });
      return;
    } else if (password.length < 6) {
      toast.show({
        title: "Password must be at least 6 characters!",
        status: "danger",
        duration: 9000,
        placement: "top",
      });
      return;
    }

    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "YES, CREATE IT!",
      cancelButtonText: "CANCEL",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(createAdmin(email, password, role));
      }
    });
  };

  return (
    <Center py={10}>
      <VStack
        w={{ base: "90%", md: "70%" }}
        shadow="3"
        bg="white"
        rounded="md"
        overflow="hidden"
      >
        <Center bg="teal.500" p={4}>
          <Text fontSize="2xl" color="white">
            CREATE ADMIN ACCOUNT
          </Text>
        </Center>

        <Stack py={10} alignItems="center" space={5}>
          <Input label="Staff Email" value={email} onChangeText={setEmail} />
          <Input
            label="Password"
            value={password}
            onChangeText={setPassword}
            type="password"
          />
          <Input
            label="Confirm Password"
            value={confirmPassword}
            onChangeText={setConfirmPassword}
            type="password"
          />

          <Button
            colorScheme="emerald"
            onPress={onCreateStaff}
            isLoading={attendants.isCreateAdminLoading}
            _loading={{ bg: "emerald.500" }}
            isLoadingText="Creating Staff..."
          >
            CREATE ADMIN
          </Button>
        </Stack>
      </VStack>
    </Center>
  );
}
