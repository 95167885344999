import React from "react";
import { useNavigate, useLocation } from "react-router-dom";

import {
  Center,
  VStack,
  Stack,
  Text,
  Button,
  useToast,
  FormControl,
  Checkbox,
} from "native-base";
import { CustomInput as Input } from "../components /CustomInput";

import Swal from "sweetalert2";
import "sweetalert2/src/sweetalert2.scss";

import { useDispatch, useSelector } from "react-redux";
import { updateStaffGroup } from "../redux/middleware/staffMiddleware";
import {
  updateStaffGroupSuccess,
  updateStaffGroupError,
  resetActionType,
} from "../redux/reducers/StaffReducer";

const EditStaffGroup = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const location = useLocation();
  const staffState = useSelector((state) => state.staff);
    const authState = useSelector((state) => state.authentication);
  const dispatch = useDispatch();



  const [groupName, setGroupName] = React.useState(location.state.groupName);
  const [allocatedFunds, setAllocatedFunds] = React.useState(
    location.state.allocatedFunds
  );
  const [canExceed, setCanExceed] = React.useState(location.state.canExceed);
  const [canEnterCarNumber, setCanEnterCarNumber] = React.useState(
    location.state.canEnterCarNumber
  );
  const [canTransferBalance, setCanTransferBalance] = React.useState(
    location.state.canTransferBalance || false
  );

  const handleSubmit = () => {
    if (groupName === "" || allocatedFunds === "") {
      toast.show({
        description: "Please fill all the fields",
        status: "warning",
        placement: "top",
      });
      return;
    }

    Swal.fire({
      title: "Are you sure?",
      text: "This will affect every staff that belongs to this group!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "YES, UPDATE IT!",
      cancelButtonText: "CANCEL",
    }).then((result) => {
      if (result.isConfirmed) {
        const data = {
          groupName,
          allocatedFunds,
          canExceed,
          canTransferBalance,
          canEnterCarNumber,
        };

        dispatch(
          updateStaffGroup(location.state.id, data, authState.currentUser.id)
        );
      }
    });
  };

  React.useEffect(() => {
    if (staffState.ACTION_TYPE === updateStaffGroupSuccess.toString()) {
      Swal.fire({
        title: "Success",
        text: staffState.createStaffGroupMessage,
        icon: "success",
        confirmButtonText: "OK",
      }).then(() => {
        dispatch(resetActionType());
        navigate("/staffgroup");
      });
    } else if (staffState.ACTION_TYPE === updateStaffGroupError.toString()) {
      Swal.fire({
        title: "Error",
        text: staffState.createStaffGroupMessage,
        icon: "error",
        confirmButtonText: "OK",
      }).then(() => {
        dispatch(resetActionType());
      });
    }
  }, [
    staffState.ACTION_TYPE,
    navigate,
    dispatch,
    staffState.createStaffGroupMessage,
  ]);



  return (
    <Center py={10}>
      <VStack
        w={{ base: "90%", md: "70%" }}
        shadow="3"
        bg="white"
        rounded="md"
        overflow="hidden"
      >
        <Center bg="teal.500" p={4}>
          <Text fontSize="2xl" color="white">
            EDIT STAFF GROUP
          </Text>
        </Center>

        <Stack py={10} alignItems="center" space={5}>
          <Input
            label="Group Name"
            value={groupName}
            onChangeText={setGroupName}
          />
          <Input
            label="Funds Allocatable"
            value={allocatedFunds}
            onChangeText={setAllocatedFunds}
          />
          <FormControl w={{ base: "90%", md: "80%" }} flexDirection={"row"}>
            <FormControl.Label>Can Exceed</FormControl.Label>
            <Checkbox
              value={canExceed}
              defaultIsChecked={canExceed}
              onChange={() => setCanExceed(!canExceed)}
              color="teal.500"
            />
          </FormControl>

          <FormControl w={{ base: "90%", md: "80%" }} flexDirection={"row"}>
            <FormControl.Label>Can Enter Car Number</FormControl.Label>
            <Checkbox
              value={canEnterCarNumber}
              defaultIsChecked={canEnterCarNumber}
              onChange={() => setCanEnterCarNumber(!canEnterCarNumber)}
              color="teal.500"
            />
          </FormControl>

          <FormControl w={{ base: "90%", md: "80%" }} flexDirection={"row"}>
            <FormControl.Label>Can Transfer Balance</FormControl.Label>
            <Checkbox
              value={canTransferBalance}
              defaultIsChecked={canTransferBalance}
              onChange={() => setCanTransferBalance(!canTransferBalance)}
              color="teal.500"
            />
          </FormControl>

          <Button
            colorScheme="emerald"
            onPress={handleSubmit}
            // isLoading={staffState.isUpdateStaffGroupLoading}
            _loading={{ bg: "emerald.500" }}
            isLoadingText="Creating Staff..."
          >
            UPDATE GROUP
          </Button>
        </Stack>
      </VStack>
    </Center>
  );
};

export default EditStaffGroup;
