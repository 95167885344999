import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router";
import {
  Center,
  Text,
  Stack,
  VStack,
  HStack,
  FlatList,
  Pressable,
  Box,
  Button,
  Tooltip,
} from "native-base";
import ExpenseCard from "../components /ExpenseCard";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { CSVLink } from "react-csv";
import empty from "../assets/empty.png";
import Swal from "sweetalert2";

// redux imports
import { useSelector, useDispatch } from "react-redux";
import { getStaffExpense } from "../redux/middleware/staffMiddleware";
import { FiChevronLeft } from "react-icons/fi";
import { MdFileDownload } from "react-icons/md";

// ! convert to date
const convertDate = (value) => {
  try {
    return (
      value.toDate().toString().split(" ")[1] +
      " " +
      value.toDate().toString().split(" ")[2] +
      " " +
      value.toDate().toString().split(" ")[3] +
      " " +
      value.toDate().toString().split(" ")[4]
    );
  } catch (error) {
    console.log(error);
    return "";
  }
};

export default function ViewStaffExpenses() {
  const navigate = useNavigate();
  const location = useLocation();
  const staffState = useSelector((state) => state.staff);
  const dispatch = useDispatch();

  const [dateRange, setDateRange] = React.useState([null, null]);
  const [startDate, endDate] = dateRange;

  useEffect(() => {
    dispatch(getStaffExpense(location.state.staffId));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {}, [staffState.ACTION_TYPE]);

  const ExampleCustomInput = React.forwardRef(({ value, onClick }, ref) => (
    <Button
      variant="outline"
      onPress={onClick}
      ref={ref}
      colorScheme="gray"
      _text={{ color: "white" }}
      pr={8}
    >
      {value ? value : "Select Date Range"}
    </Button>
  ));

  return (
    <Center py={5}>
      <VStack
        w={["95%", "75%"]}
        h="95vh"
        shadow="3"
        bg="white"
        rounded="md"
        overflow="hidden"
      >
        <Box bg="red.600" p={4}>
          <Center pt={5} pb={3}>
            <Pressable
              position="absolute"
              left="5"
              onPress={() => navigate("/staff")}
            >
              <FiChevronLeft color="white" size={20} />
            </Pressable>

            <Box position="absolute" right={5}>
              <CSVLink
                data={
                  startDate
                    ? staffState.staffExpense
                        .filter(
                          (expense) =>
                            new Date(convertDate(expense.purchaseDate)) >=
                              startDate &&
                            new Date(convertDate(expense.purchaseDate)) <=
                              endDate
                        )
                        .map((expense) => {
                          return {
                            "Amount Spent": expense.amountSpent,
                            "Amount Remaining": expense.amountRemaining,
                            "Attendant ID": expense.attendantId,
                            "Car Number": expense.carNumber,
                            "Purchase Date": convertDate(expense.purchaseDate),
                            transactionId: expense.transactionId,
                            "Staff Name": location.state.fullname,
                            "Attendant Name": expense.attendantName,
                            "Attendant Number": expense.attendantNumber,
                          };
                        })
                    : staffState.staffExpense.map((expense) => {
                        return {
                          "Amount Spent": expense.amountSpent,
                          "Amount Remaining": expense.amountRemaining,
                          "Attendant ID": expense.attendantId,
                          "Car Number": expense.carNumber,
                          "Purchase Date": convertDate(expense.purchaseDate),
                          transactionId: expense.transactionId,
                          "Staff Name": location.state.fullname,
                          "Attendant Name": expense.attendantName,
                        };
                      })
                }
                filename={`${location.state.fullname}-expense.csv`}
              >
                <Tooltip label="Export to Excel">
                  <Center bg="emerald.500" p={2} rounded="md">
                    <MdFileDownload size={20} color="white" />
                  </Center>
                </Tooltip>
              </CSVLink>
            </Box>
          </Center>
          <Stack
            flexDirection={{ base: "column", md: "row" }}
            w="100%"
            pt={4}
            px={{ base: 3, md: 5 }}
            justifyContent={{ base: "center", md: "space-between" }}
            alignItems={{ base: "center", md: "center" }}
          >
            <Text fontSize="xl" color="white">
              {location.state.fullname}
            </Text>
            <DatePicker
              selectsRange={true}
              startDate={startDate}
              endDate={endDate}
              onChange={(update) => {
                setDateRange(update);
              }}
              customInput={<ExampleCustomInput />}
              withPortal
              isClearable
            />
          </Stack>
        </Box>

        <FlatList
          zIndex={-1}
          py={5}
          data={
            startDate
              ? staffState.staffExpense
                  .filter(
                    (expense) =>
                      new Date(convertDate(expense.purchaseDate)) >=
                        startDate &&
                      new Date(convertDate(expense.purchaseDate)) <=
                        new Date(new Date(endDate).setHours(23, 59, 59, 999))
                  )
                  .sort((a, b) => {
                    return (
                      new Date(convertDate(b.purchaseDate)) -
                      new Date(convertDate(a.purchaseDate))
                    );
                  })
              : staffState.staffExpense
          }
          ListEmptyComponent={
            <Center h="55vh">
              <Text>Nothing was found</Text>
            </Center>
          }
          renderItem={({ item }) => (
            <ExpenseCard
              id={item.id}
              spendableBalance={item.spendableBalance}
              amountSpent={item.amountSpent}
              amountRemaining={item.amountRemaining}
              purchaseDate={new Date(
                item.purchaseDate.seconds * 1000
              ).toUTCString()}
              source={item.imageUrl ? { uri: item.imageUrl } : empty}
              attendantId={item.attendantId}
              litres={item.litres}
              attendantName={item.attendantName}
              pricePerLitres={item.pricePerLitres}
              comment={item.comment}
              onComment={() =>
                Swal.fire({
                  text: item.comment,
                  confirmButtonText: "Done",
                })
              }
            />
          )}
        />
        <HStack
          bg="red.600"
          p={4}
          justifyContent={"space-between"}
          alignItems={"center"}
          flexWrap={"wrap"}
        >
          <Text color="white">
            Total Amount Spent: GHC{" "}
            {parseFloat(location.state.totalAmountSpent).toFixed(2)}
          </Text>
          <Text color="white">
            Remaining Balance: GHC{" "}
            {parseFloat(location.state.remainingBalance).toFixed(2)}
          </Text>
        </HStack>
      </VStack>
    </Center>
  );
}
