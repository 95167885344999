import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import RootLayout from "./layout/RootLayout";


// Redux imports
import { Provider } from "react-redux";
import store, { persistor } from './redux/store';
import { PersistGate } from "redux-persist/integration/react";

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router>
          <RootLayout />
        </Router>
      </PersistGate>
    </Provider>
  );
}

export default App;
