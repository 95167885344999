import React from "react";
import { Routes, Route, Navigate } from "react-router";

import Dashboard from "../pages/FuelStation/Dashboard";
import Attendants from '../pages/FuelStation/Atendants'
import CreateAttendant from "../pages/FuelStation/CreateAttendant";
import SalesDetails from "../pages/FuelStation/SalesDetails";
import SalesReport from '../pages/reports/SalesReport';
import CreateAdmin from "../pages/FuelStation/CreateAdmin";
import ExportSalesToPDF from "../pages/ExportSalesToPDF";
import EditAttendant from "../pages/FuelStation/EditAttendant";


import { HStack, ScrollView } from "native-base";
import Sidebar from "../components /FuelStationSidebar";

export default function FuelStaionLayout() {
  return (
    <HStack bg="gray.200">
      <Sidebar />

      <ScrollView flex={1}>
        <Routes>
          <Route path="/" element={<Dashboard />} />
          <Route path="/attendants" element={<Attendants />} />
          <Route path="/add-attendant" element={<CreateAttendant />} />
          <Route path="/sales-details" element={<SalesDetails />} />
          <Route path="/sales-report" element={<SalesReport />} />
          <Route path="/add-admin" element={<CreateAdmin />} />
          <Route path="/export-sales-to-pdf" element={<ExportSalesToPDF />} />
          <Route path="/edit-attendant/:id" element={<EditAttendant />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </ScrollView>
    </HStack>
  );
}
