import axios from "axios";
import {
  createStaffStart,
  createStaffSuccess,
  createStaffError,
  updateStaffStart,
  updateStaffSuccess,
  updateStaffError,
  getStaffStart,
  getStaffSuccess,
  getStaffError,
  addStaffDetailStart,
  addStaffDetailSuccess,
  addStaffDetailError,
  updateSpendingLimitStart,
  updateSpendingLimitSuccess,
  updateSpendingLimitError,
  resetSpentAmountStart,
  resetSpentAmountSuccess,
  resetSpentAmountError,
  getStaffExpenseStart,
  getStaffExpenseSuccess,
  getStaffExpenseError,
  blockStaffStart,
  blockStaffSuccess,
  blockStaffError,
  unblockStaffStart,
  unblockStaffSuccess,
  unblockStaffError,
  createStaffGroupStart,
  createStaffGroupSuccess,
  createStaffGroupError,
  updateStaffGroupError,
  updateStaffGroupStart,
  updateStaffGroupSuccess,
  getStaffGroup,
  addCarStart,
  addCarSuccess,
  addCarError,
  editCarStart,
  editCarSuccess,
  editCarError,
  getAllCarsStart,
  getAllCarsSuccess,
  getAllCarsError,
  deleteUserStart,
  deleteUserSuccess,
  deleteUserError,
} from "../reducers/StaffReducer";

import { db, auth } from "../../firebase/firebase-config";
import {
  collection,
  getDocs,
  updateDoc,
  setDoc,
  addDoc,
  doc,
  query,
  where,
  writeBatch,
  deleteDoc,
} from "@firebase/firestore";

import { sendPasswordResetEmail, deleteUser } from "firebase/auth";

import { writeActivity } from "./authenticationMiddleware";

const APIKEY = process.env.REACT_APP_API_KEY;
const CREATE_USER_URL = `https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=`;
const staffCollection = collection(db, "staff");
const staffGroupCollection = collection(db, "staff-groups");
const carsCollection = collection(db, "cars");

// create staff account
export const createStaff = (
  email,
  password,
  isAdmin,
  role,
  adminId,
  canUseApp
) => {
  return async (dispatch) => {
    dispatch(createStaffStart());

    return axios({
      method: "POST",
      url: `${CREATE_USER_URL}${APIKEY}`,
      data: {
        email: email,
        password: password,
        returnSecureToken: true,
      },
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        const staffId = res.data.localId;
        const staff = {
          staffId,
          email,
          fullname: "",
          phone: "",
          carNumber: " ",
          spendableBalance: 0,
          allocatedFunds: 0,
          canExceed: false,
          canEnterCarNumber: false,
          groupName: "",
          totalAmountSpent: 0,
          totalLitres: 0,
          remainingBalance: 0,
          isDisabled: false,
          role: "staff",
          paymentPin: "",
          getPaymentAlert: false,
          canTransferBalance: false,
        };

        console.log(res);
        dispatch(createStaffSuccess(res.data));

        if (isAdmin === true) {
          dispatch(createDashboardUser(email, role));
          console.log("created user wo maate", adminId);
          dispatch(
            writeActivity(adminId, {
              activity: `Created new admin with email ${email}`,
              date: new Date(),
            })
          );
          return;
        } else if (canUseApp === true) {
          dispatch(createDashboardUser(email, role));
          dispatch(addStaffDetails(staff, staffId));
          writeActivity(adminId, {
            activity: `Created new admin with email ${email} who can use the app`,
            date: new Date(),
          });
          return;
        } else {
          dispatch(addStaffDetails(staff, staffId));
          dispatch(
            writeActivity(adminId, {
              activity: `Created new staff with email ${email}`,
              date: new Date(),
            })
          );
        }
      })
      .catch((err) => {
        console.log(err.message);
        dispatch(createStaffError({ message: err.message }));
      });
  };
};

// add staff details -> also creates database record of staff collection
export const addStaffDetails = (staff, id) => {
  return async (dispatch) => {
    dispatch(addStaffDetailStart());

    try {
      const addStaffDetails = async () => {
        // await addDoc(staffCollection, staff, id);
        await setDoc(doc(db, "staff", id), staff).then(() => {
          sendPasswordResetEmail(auth, staff.email)
            .then(() => {
              dispatch(
                addStaffDetailSuccess({
                  message: "Staff details added successfully",
                })
              );
            })
            .catch((error) => {
              const errorMessage = error.message;
              dispatch(addStaffDetailError({ message: errorMessage }));
            });
        });
      };

      await addStaffDetails();
    } catch (error) {
      dispatch(addStaffDetailError({ message: error.message }));
    }
  };
};

// gets all staff documents from staff collection
export const getAllStaff = () => {
  return async (dispatch) => {
    dispatch(getStaffStart());

    try {
      const getStaff = async () => {
        const data = await getDocs(staffCollection);
        const allStaffData = data.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
        dispatch(getStaffSuccess(allStaffData));
      };
      getStaff();
    } catch (err) {
      dispatch(getStaffError({ message: err.message }));
    }
  };
};

// allocat funds -> reset staff balance
export const updateSpendingLimit = (staffId, spendableBalance) => {
  return async (dispatch) => {
    dispatch(updateSpendingLimitStart());

    try {
      const allocateFunds = async () => {
        const staff = await doc(db, "staff", staffId);

        await updateDoc(staff, {
          spendableBalance: spendableBalance,
        });
      };
      await allocateFunds();
      dispatch(
        updateSpendingLimitSuccess({
          message: "Spending Limit updated successfully",
        })
      );
    } catch (error) {
      dispatch(updateSpendingLimitError({ message: error.message }));
    }
  };
};

// reset amount spend by staff in staff collection -> new and in use
export const resetSpentAmount = (
  staffId,
  spendableBalance,
  allocatedBalance
) => {
  return async (dispatch) => {
    dispatch(resetSpentAmountStart());

    try {
      const resetSpentAmount = async () => {
        const staff = await doc(db, "staff", staffId);

        await updateDoc(staff, {
          spendableBalance: spendableBalance + allocatedBalance,
        });
      };
      await resetSpentAmount();
      dispatch(
        resetSpentAmountSuccess({
          message: "Amount Spent reset successfully",
        })
      );
    } catch (error) {
      dispatch(resetSpentAmountError({ message: error.message }));
    }
  };
};

// reset spendableBalance in staff collection -> //* new middleware
export const resetSpendableBalance = (
  staffId,
  staffName,
  remainingBalance,
  allocatedBalance,
  allocationGroup,
  adminId,
  comment
) => {
  return async (dispatch) => {
    dispatch(resetSpentAmountStart());

    try {
      const resetSpentAmount = async () => {
        const staff = await doc(db, "staff", staffId);

        await updateDoc(staff, {
          spendableBalance:
            parseFloat(remainingBalance) + parseFloat(allocatedBalance),
          remainingBalance:
            parseFloat(remainingBalance) + parseFloat(allocatedBalance),
        }).then(() => {
          // create allocation record in staff-allocations collection
          const staffAllocation = {
            staffId: staffId,
            staffName: staffName,
            allocatedBalance: allocatedBalance,
            initialBalance: remainingBalance,
            currentBalance:
              parseFloat(remainingBalance) + parseFloat(allocatedBalance),
            allocatedDate: new Date(),
            group: allocationGroup,
            comment: comment,
          };
          addDoc(collection(db, "staff-allocations"), staffAllocation);
        });
      };
      await resetSpentAmount().then(() =>
        dispatch(
          writeActivity(adminId, {
            activity: `Allocated GH₵ ${allocatedBalance}  for ${staffName}`,
            date: new Date(),
          })
        )
      );
      dispatch(
        resetSpentAmountSuccess({
          message: "Staff Balance Updated Successfully",
        })
      );
    } catch (error) {
      dispatch(resetSpentAmountError({ message: error.message }));
    }
  };
};

// update staff details -> also updates database record of staff collection
export const updateStaffDetails = (staffId, updateStaffData, adminId) => {
  return async (dispatch) => {
    dispatch(updateStaffStart());

    try {
      const updateStaff = async () => {
        const staff = await doc(db, "staff", staffId);

        await updateDoc(staff, updateStaffData);
      };
      await updateStaff().then(() => {
        dispatch(
          writeActivity(adminId, {
            activity: `Edited staff ${updateStaffData.fullname}`,
            date: new Date(),
          })
        );
      });
      dispatch(
        updateStaffSuccess({
          message: "Staff details updated successfully",
        })
      );
    } catch (error) {
      dispatch(updateStaffError({ message: error.message }));
    }
  };
};

// get staff expenses -> gets staff exoebse frin staff expense collection
export const getStaffExpense = (staffId) => {
  return async (dispatch) => {
    dispatch(getStaffExpenseStart());

    try {
      const q = query(
        collection(db, "staff-expenses"),
        where("staffId", "==", staffId)
        // orderBy("amountRemaining", "asc")
      );

      const getQuerySnapshot = async () => {
        const querySnapshot = await getDocs(q);

        const staffExpenseData = querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));

        dispatch(
          getStaffExpenseSuccess(
            staffExpenseData.sort(
              (a, b) => a.amountRemaining - b.amountRemaining
            )
          )
        );
      };
      getQuerySnapshot();
    } catch (err) {
      dispatch(getStaffExpenseError({ message: err.message }));
    }
  };
};

// block staff -> block staff from login
export const blockStaff = (staffId, adminId, fullname) => {
  return async (dispatch) => {
    dispatch(blockStaffStart());

    try {
      const blockStaff = async () => {
        const staff = await doc(db, "staff", staffId);

        await updateDoc(staff, {
          isDisabled: true,
        });
      };
      await blockStaff().then(() => {
        dispatch(
          writeActivity(adminId, {
            activity: `Block staff ${fullname}`,
            date: new Date(),
          })
        );
      });
      dispatch(
        blockStaffSuccess({
          message: "Staff blocked successfully",
        })
      );
    } catch (error) {
      dispatch(blockStaffError({ message: error.message }));
    }
  };
};

// unblock staff -> unblock staff from login
export const unblockStaff = (staffId, adminId, fullname) => {
  return async (dispatch) => {
    dispatch(unblockStaffStart());

    try {
      const unblockStaff = async () => {
        const staff = await doc(db, "staff", staffId);

        await updateDoc(staff, {
          isDisabled: false,
        });
      };
      await unblockStaff().then(() => {
        dispatch(
          writeActivity(adminId, {
            activity: `Unblocked staff ${fullname}`,
            date: new Date(),
          })
        );
      });
      dispatch(
        unblockStaffSuccess({
          message: "Staff unblocked successfully",
        })
      );
    } catch (error) {
      dispatch(unblockStaffError({ message: error.message }));
    }
  };
};

//  create staff group -> create staff group in staff group collection
export const createStaffGroup = (data, adminId) => {
  return async (dispatch) => {
    dispatch(createStaffGroupStart());

    //  create staff group collection and add staff group details
    try {
      const createStaffGroup = async () => {
        const staffGroup = await collection(db, "staff-groups");

        await addDoc(staffGroup, data);
      };
      await createStaffGroup().then(() => {
        dispatch(
          writeActivity(adminId, {
            activity: `Created new staff group ${data.groupName}`,
            date: new Date(),
          })
        );
      });
      dispatch(
        createStaffGroupSuccess({
          message: "Staff group created successfully",
        })
      );
    } catch (error) {
      dispatch(createStaffGroupError({ message: error.message }));
    }
  };
};

// updateStaff group
export const updateStaffGroup = (
  staffGroupId,
  updateStaffGroupData,
  adminId
) => {
  return async (dispatch) => {
    dispatch(updateStaffGroupStart());

    try {
      const updateStaffGroup = async () => {
        const staffGroup = await doc(db, "staff-groups", staffGroupId);

        await updateDoc(staffGroup, updateStaffGroupData);
      };
      await updateStaffGroup()
        .then(() => {
          const updateStaff = async () => {
            const q = query(
              collection(db, "staff"),
              where("groupName", "==", updateStaffGroupData.groupName)
            );

            const staff = await getDocs(q);

            staff.forEach((d) => {
              const batch = writeBatch(db);
              const staffRef = doc(db, "staff", d.id);
              batch.update(staffRef, updateStaffGroupData);
              batch.commit();
            });
          };

          updateStaff()
            .then(() => {
              dispatch(
                writeActivity(adminId, {
                  activity: `Edited staff group ${updateStaffGroupData.groupName}`,
                  date: new Date(),
                })
              );
            })
            .finally(() => {
              dispatch(
                updateStaffGroupSuccess({
                  message: "Staff group updated successfully",
                })
              );
            });
        })
        .catch((error) => {
          dispatch(updateStaffGroupError({ message: error.message }));
        });
    } catch (error) {
      dispatch(updateStaffGroupError({ message: error.message }));
    }
  };
};

// get all staff groups -> get all staff groups from staff group collection
export const getAllStaffGroups = () => {
  return async (dispatch) => {
    try {
      const getStaffGroups = async () => {
        const data = await getDocs(staffGroupCollection);

        const staffGroupsData = data.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));

        dispatch(getStaffGroup(staffGroupsData));
        // console.log(staffGroupsData)
      };
      getStaffGroups();
    } catch (err) {
      console.log(err.message);
    }
  };
};

// add user to users collection
export const createDashboardUser = (email, role) => {
  return async (dispatch) => {
    try {
      const addUser = async () => {
        const users = await collection(db, "users");

        await addDoc(users, {
          email,
          role,
          dateCreated: new Date(),
        });
      };
      await addUser();
    } catch (error) {
      dispatch(createStaffError({ message: error.message }));
    }
  };
};

// add car to cars collection
export const createCar = (data, adminId) => {
  return async (dispatch) => {
    dispatch(addCarStart());

    try {
      const addCar = async () => {
        const cars = await collection(db, "cars");

        await addDoc(cars, data);
      };
      await addCar().then(() => {
        dispatch(
          writeActivity(adminId, {
            activity: `Added new car ${data.carModel} to Fleet`,
            date: new Date(),
          })
        );
      });
      dispatch(
        addCarSuccess({
          message: "Car added successfully",
        })
      );
    } catch (error) {
      dispatch(addCarError({ message: error.message }));
    }
  };
};

//  edit car -> edit car in cars collection

export const editCar = (carId, updateCarData, oldCarNumber, adminId) => {
  return async (dispatch) => {
    dispatch(editCarStart());

    try {
      const editCar = async () => {
        const car = await doc(db, "cars", carId);

        await updateDoc(car, updateCarData);
      };
      await editCar().then(() => {
        const updateCar = async () => {
          const q = query(
            collection(db, "staff"),
            where("carNumber", "==", oldCarNumber)
          );

          const staff = await getDocs(q);

          staff.forEach((d) => {
            console.log(d.id, " => ", d.data());

            const batch = writeBatch(db);
            const staffRef = doc(db, "staff", d.id);
            batch.update(staffRef, { carNumber: updateCarData.carNumber });
            batch.commit();
          });
        };

        updateCar()
          .then(() => {
            dispatch(
              writeActivity(adminId, {
                activity: `Edited car ${updateCarData.carModel} to Fleet`,
                date: new Date(),
              })
            );
          })
          .finally(() => {
            dispatch(
              editCarSuccess({
                message: "Car edited successfully",
              })
            );
          });
      });
    } catch (error) {
      dispatch(editCarError({ message: error.message }));
    }
  };
};

// get all cars from car collection
export const getAllCars = () => {
  return async (dispatch) => {
    dispatch(getAllCarsStart());

    try {
      const getCars = async () => {
        const data = await getDocs(carsCollection);

        const cars = data.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));

        dispatch(getAllCarsSuccess(cars));
      };
      getCars();
    } catch (err) {
      console.log(err.message);
      dispatch(getAllCarsError({ message: err.message }));
    }
  };
};

