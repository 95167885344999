import React, { useState, useEffect } from "react";
import { useWindowDimensions } from "../../customHooks/useWindowDimensions";
import {
  Center,
  Stack,
  Heading,
  Button,
  useToast,
  IconButton,
  Text,
} from "native-base";
import { FiEye, FiEyeOff } from "react-icons/fi";

import { CustomInput as Input } from "../../components /CustomInput";

import { useDispatch, useSelector } from "react-redux";
import {
  loginError,
  resetActionType,
} from "../../redux/reducers/AuthenticationReducer";

import { loginMiddleware } from "../../redux/middleware/authenticationMiddleware";

export default function Login() {
  const { height } = useWindowDimensions();

  const [username, setUsername] = useState();
  const [password, setPassword] = useState();

  const [showPassword, setShowPassword] = useState(false);

  const dispatch = useDispatch();
  const auth = useSelector((state) => state.authentication);
  const toast = useToast();

  useEffect(() => {
    if (auth.ACTION_TYPE === loginError.toString()) {
      toast.show({
        title: auth.loginMessage,
        status: "error",
        placement: "top",
      });
      dispatch(resetActionType());
      return;
    }
  }, [auth.ACTION_TYPE]);

  const onLogin = () => {
    if (!username || !password) {
      toast.show({
        title: "Please fill all the fields!",
        status: "error",
        placement: "top",
      });
      return;
    }

    dispatch(loginMiddleware(username, password));
  };

  return (
    <Center h={height} bg="gray.800">
      <Stack
        bg="gray.100"
        px={8}
        py={20}
        w={{ base: "85%", md: "45%" }}
        alignItems="center"
        space={5}
      >
        <Heading textAlign="center" color="black" mb={5}>
          ADMIN LOGIN
        </Heading>

        <Input label="Email" value={username} onChangeText={setUsername} />
        <Input
          label="Password"
          value={password}
          secureTextEntry={!showPassword}
          type={showPassword ? "text" : "password"}
          onChangeText={setPassword}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              onLogin();
            }
          }}
          inputRightElement={
            <IconButton
              icon={showPassword ? <FiEye size={20} /> : <FiEyeOff size={20} />}
              onPress={() => setShowPassword(!showPassword)}
            />
          }
        />

        <Button
          size="lg"
          w={{ base: "90%", md: "80%" }}
          mt={5}
          colorScheme="emerald"
          isLoading={auth.isLoginLoading}
          _loading={{ bg: "emerald.500" }}
          isLoadingText="Logging in..."
          onPress={() => onLogin()}
        >
          LOGIN
        </Button>
      </Stack>
    </Center>
  );
}
