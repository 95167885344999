import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  Center,
  VStack,
  Stack,
  Text,
  HStack,
  Box,
  Pressable,
  Spinner,
} from "native-base";

import "sweetalert2/src/sweetalert2.scss";

import { useDispatch, useSelector } from "react-redux";
import { getAllStaffGroups } from "../redux/middleware/staffMiddleware";

function Item({ label, value }) {
  return (
    <HStack
      space="5"
      flexWrap={"wrap"}
      alignItems={{ base: "flex-start", md: "center" }}
      justifyContent={{ md: "space-between" }}
    >
      <Text flex={5}>{label}</Text>
      <Text>{value}</Text>
    </HStack>
  );
}

export default function StaffGroups() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const staff = useSelector((state) => state.staff);
  const [isLoading, setIsLoading] = useState(false);

  React.useEffect(() => {
    setIsLoading(true);
    dispatch(getAllStaffGroups());
    setIsLoading(false);
  }, []);

  return (
    <Center py={10}>
      <VStack
        w={{ base: "90%", md: "70%" }}
        shadow="3"
        bg="white"
        rounded="md"
        overflow="hidden"
      >
        <VStack bg="teal.500" p={4}>
          <Text fontSize="2xl" color="white">
            STAFF GROUP
          </Text>
        </VStack>
        <Stack p={5} space={5}>
          {isLoading && <Spinner />}
          {staff.allStaffGroups.map((staffGroup) => (
            <Box p={5} rounded="md" bg="amber.100">
              <Item label="Group Name" value={staffGroup.groupName} />
              <Item
                label="Allocatable Funds"
                value={"GH₵" + staffGroup.allocatedFunds}
              />
              <Item
                label="Can Exceed"
                value={staffGroup.canExceed ? "Yes" : "No"}
              />
              <Item
                label="Can Enter Car Number"
                value={staffGroup.canEnterCarNumber ? "Yes" : "No"}
              />
              <Item
                label="Can Transfer Balance"
                value={staffGroup.canTransferBalance ? "Yes" : "No"}
              />
              <Center>
                <Pressable
                  mt={2}
                  px="2"
                  py={1}
                  bg="green.500"
                  rounded="sm"
                  w="20%"
                  alignItems="center"
                  onPress={() => {
                    navigate(`/editstaffgroup/${staffGroup.id}`, {
                      state: staffGroup,
                    });
                  }}
                >
                  <Text fontSize="xs" color="white">
                    EDIT
                  </Text>
                </Pressable>
              </Center>
            </Box>
          ))}
        </Stack>
      </VStack>
    </Center>
  );
}
