import { createSlice } from "@reduxjs/toolkit";

const INITIAL_STATE = {
  ACTION_TYPE: "",

  isGetAttendantsLoading: false,
  isValidateAttendantLoading: false,
  isBlockAttendantLoading: false,
  isCreateAttendantLoading: false,
  isCreateAdminLoading: false,
  isEditAttendantLoading: false,

  getAttendantsMessage: "",
  validateAttendantMessage: "",
  blockAttendantMessage: "",
  createAttendantMessage: "",
  createAdminMessage: "",
  editAttendantMessage: "",

  attendants: [],
};

export const AttendantReducer = createSlice({
  name: "attendants",
  initialState: INITIAL_STATE,
  reducers: {
    getAttendantsStart: (state) => {
      state.ACTION_TYPE = getAttendantsStart.toString();
      state.isGetAttendantsLoading = true;
      state.getAttendantsMessage = "";
    },
    getAttendantsSuccess: (state, action) => {
      state.ACTION_TYPE = getAttendantsSuccess.toString();
      state.isGetAttendantsLoading = false;
      state.attendants = action.payload;
    },

    getAttendantsError: (state, action) => {
      state.ACTION_TYPE = getAttendantsError.toString();
      state.isGetAttendantsLoading = false;
      state.getAttendantsMessage = action.payload.message;
    },

    validateAttendantStart: (state) => {
      state.ACTION_TYPE = validateAttendantStart.toString();
      state.isValidateAttendantLoading = true;
      state.validateAttendantMessage = "";
    },

    validateAttendantSuccess: (state, action) => {
      state.ACTION_TYPE = validateAttendantSuccess.toString();
      state.isValidateAttendantLoading = false;
      state.validateAttendantMessage = action.payload.message;
    },

    validateAttendantError: (state, action) => {
      state.ACTION_TYPE = validateAttendantError.toString();
      state.isValidateAttendantLoading = false;
      state.validateAttendantMessage = action.payload.message;
    },

    blockAttendantStart: (state) => {
      state.ACTION_TYPE = blockAttendantStart.toString();
      state.isBlockAttendantLoading = true;
      state.blockAttendantMessage = "";
    },

    blockAttendantSuccess: (state, action) => {
      state.ACTION_TYPE = blockAttendantSuccess.toString();
      state.isBlockAttendantLoading = false;
      state.blockAttendantMessage = action.payload.message;
    },

    blockAttendantError: (state, action) => {
      state.ACTION_TYPE = blockAttendantError.toString();
      state.isBlockAttendantLoading = false;
      state.blockAttendantMessage = action.payload.message;
    },

    createAttendantStart: (state) => {
      state.ACTION_TYPE = createAttendantStart.toString();
      state.isCreateAttendantLoading = true;
      state.createAttendantMessage = "";
    },

    createAttendantSuccess: (state, action) => {
      state.ACTION_TYPE = createAttendantSuccess.toString();
      state.isCreateAttendantLoading = false;
      state.createAttendantMessage = action.payload.message;
    },

    createAttendantError: (state, action) => {
      state.ACTION_TYPE = createAttendantError.toString();
      state.isCreateAttendantLoading = false;
      state.createAttendantMessage = action.payload.message;
    },

    createAdminStart: (state) => {
      state.ACTION_TYPE = createAdminStart.toString();
      state.isCreateAdminLoading = true;
      state.createAdminMessage = "";
    },

    createAdminSuccess: (state, action) => {
      state.ACTION_TYPE = createAdminSuccess.toString();
      state.isCreateAdminLoading = false;
      state.createAdminMessage = action.payload.message;
    },

    createAdminError: (state, action) => {
      state.ACTION_TYPE = createAdminError.toString();
      state.isCreateAdminLoading = false;
      state.createAdminMessage = action.payload.message;
    },

    editAttendantStart: (state) => {
      state.ACTION_TYPE = editAttendantStart.toString();
      state.isEditAttendantLoading = true;
      state.editAttendantMessage = "";
    },

    editAttendantSuccess: (state, action) => {
      state.ACTION_TYPE = editAttendantSuccess.toString();
      state.isEditAttendantLoading = false;
      state.editAttendantMessage = action.payload.message;
    },

    editAttendantError: (state, action) => {
      state.ACTION_TYPE = editAttendantError.toString();
      state.isEditAttendantLoading = false;
      state.editAttendantMessage = action.payload.message;
    },



    resetActionType: (state) => {
      state.ACTION_TYPE = resetActionType.toString();
    },
  },
});

export const {
  getAttendantsStart,
  getAttendantsSuccess,
  getAttendantsError,
  resetActionType,
  validateAttendantStart,
  validateAttendantSuccess,
  validateAttendantError,
  blockAttendantStart,
  blockAttendantSuccess,
  blockAttendantError,
  createAttendantStart,
  createAttendantSuccess,
  createAttendantError,
  createAdminStart,
  createAdminSuccess,
  createAdminError,
  editAttendantStart,
  editAttendantSuccess,
  editAttendantError,
} = AttendantReducer.actions;
export default AttendantReducer.reducer;
