import React, { useEffect, useCallback } from "react";
import { useNavigate } from "react-router";
import {
  Center,
  useToast,
  HStack,
  Button,
  Modal,
  useDisclose,
  Text,
  Select,
  CheckIcon,
  IconButton,
  Menu,
} from "native-base";
import DataTable from "react-data-table-component";
import { customStyles } from "../customStyles";
import { allStaffColumn } from "../utils/columns";
import { FilterComponent } from "../utils/export";
import TableMenu from "../components /TableMenu";
import Swal from "sweetalert2";

import { db } from "../firebase/firebase-config";
import { collection, onSnapshot, query, where } from "@firebase/firestore";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";

// redux imports
import {
  getAllStaff,
  getAllStaffGroups,
  resetSpendableBalance,
} from "../redux/middleware/staffMiddleware";
import {
  getStaffError,
  resetSpentAmountSuccess,
  resetSpentAmountError,
  resetActionType,
} from "../redux/reducers/StaffReducer";

import { useDispatch, useSelector } from "react-redux";
import { FaWindowClose } from "react-icons/fa";

export default function Dashboard() {
  const dispatch = useDispatch();
  const staffState = useSelector((state) => state.staff);
  const authState = useSelector((state) => state.authentication);
  const toast = useToast();
  const navigate = useNavigate();

  const [groups, setGroups] = React.useState([]);
  const [group] = React.useState({});
  const [allocationData, setAllocationData] = React.useState([]);
  const { isOpen, onOpen, onClose } = useDisclose();
  const [staffData, setStaffData] = React.useState([]);

  const getStaffCallback = useCallback(() => {
    dispatch(getAllStaff());
    dispatch(getAllStaffGroups());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [staffState.ACTION_TYPE]);

  //   send reset password email
  const resetPassword = (email) => {
    const auth = getAuth();

    Swal.fire({
      title: "Are you sure?",
      text: `You about to send a password reset email to ${email}`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
    }).then((result) => {
      if (result.isConfirmed) {
        sendPasswordResetEmail(auth, email)
          .then(() => {
            // Password reset email sent!
            // ..
            toast.show({
              title: "Success",
              description: "Password reset email sent!",
              status: "success",
              placement: "top",
            });
          })
          .catch((error) => {
            const errorMessage = error.message;
            // ..
            toast.show({
              title: "Error",
              description: errorMessage,
              status: "danger",
              placement: "top",
            });
          });
      }
    });
  };

  useEffect(() => {
    getStaffCallback();

    setGroups(
      staffState?.allStaffGroups?.map((group) => {
        return {
          name: group.groupName,
        };
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useLayoutEffect(() => {
    const q = query(
      collection(db, "staff"),
      where("email", "!=", "test@donewelllife.com.gh")
    );

    const unsubScribe = onSnapshot(q, (snapshot) => {
      const staff = [];
      snapshot.forEach((doc) => {
        staff.push(doc.data());
      });

      setStaffData(staff);
    });

    return () => {
      unsubScribe();
    };
  }, []);

  useEffect(() => {
    if (staffState.ACTION_TYPE === getStaffError.toString()) {
      toast.show({
        title: staffState.ERROR_MESSAGE,
        status: "danger",
        placement: "top",
        duration: 3000,
      });
      dispatch(resetActionType());
    } else if (staffState.ACTION_TYPE === resetSpentAmountSuccess.toString()) {
      Swal.fire({
        icon: "success",
        title: "Success",
        text: staffState.resetSpentAmountMessage,
      });
      getStaffCallback();
      dispatch(resetActionType());
      onClose();
    } else if (staffState.ACTION_TYPE === resetSpentAmountError.toString()) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: staffState.resetSpentAmountMessage,
        confirmButtonText: "OK",
      });
      getStaffCallback();
      dispatch(resetActionType());
      onClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [staffState.ACTION_TYPE]);

  // table stactures
  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);

  const filteredItems = staffData.filter(function (item) {
    return (
      (item.fullname &&
        item.fullname.toLowerCase().includes(filterText.toLowerCase())) ||
      (item.email &&
        item.email.toLowerCase().includes(filterText.toLowerCase())) ||
      (item.phone &&
        item.phone.toLowerCase().includes(filterText.toLowerCase())) ||
      (item.carNumber &&
        item.carNumber.toLowerCase().includes(filterText.toLowerCase())) ||
      (item.groupName &&
        item.groupName.toLowerCase().includes(filterText.toLowerCase()))
    );
  });

  const allocateFunds = () => {
    if (
      allocationData && // 👈 null and undefined check
      Object.keys(allocationData).length === 0
    ) {
      toast.show({
        title: "Error",
        description: "Please select a group",
        status: "error",
        placement: "top",
      });
      return;
    }
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
    }).then((result) => {
      if (result.isConfirmed) {
        filteredItems.forEach((item, index) => {
          if (item.groupName === allocationData.groupName) {
            dispatch(
              resetSpendableBalance(
                item.staffId,
                item.fullname,
                item.remainingBalance,
                allocationData.allocatedFunds,
                item.groupName,
                authState.currentUser.id,
                `group ${allocationData.groupName} allocation to ${
                  item.fullname
                } on ${new Date().toLocaleString()}`
              )
            );
          }
        });
      } else {
        Swal.fire("Cancelled", "", "error");
        onClose();
      }
    });
  };

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
      }
    };
    return (
      <HStack justifyContent="space-between" w="100%">
        <HStack space={5} flexWrap={"wrap"}>
          {/* <Export
            onExport={() =>
              filteredItems.length > 0
                ? downloadCSV(filteredItems)
                : toast.show({
                    title: "No Data to Export",
                    status: "danger",
                    duration: 3000,
                    placement: "top",
                  })
            }
          /> */}

          <Button
            onPress={() => {
              onOpen();
            }}
          >
            ALLOCATE FUNDS
          </Button>
        </HStack>

        <FilterComponent
          onFilter={(e) => setFilterText(e.target.value)}
          onClear={handleClear}
          filterText={filterText}
        />
      </HStack>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterText, resetPaginationToggle]);

  console.log(filteredItems.find((item) => item.fullname === "Test User"));

  return (
    <Center py={20}>
      <div
        className=" shadow pt-3 col-11 bg-white"
        style={{ borderRadius: 10, overflow: "hidden" }}
      >
        <DataTable
          data={filteredItems}
          columns={[
            {
              name: "Actions",
              selector: (row) => row.title,
              sortable: true,
              cell: (row, index) => (
                <div data-tag="allowRowEvents">
                  <div style={{ cursor: "pointer" }}>
                    <TableMenu
                      viewText="ALLOCATE FUNDS"
                      editText="EDIT STAFF"
                      expenseText="VIEW EXPENSE"
                      onView={() =>
                        navigate(`/allocate-funds/${row.staffId}`, {
                          // find the staff id and navigate to the allocate funds page
                          state: filteredItems.find(
                            (item) => item.staffId === row.staffId
                          ),
                        })
                      }
                      onEdit={() =>
                        navigate(`/edit-staff/${row.staffId}`, {
                          state: filteredItems.find(
                            (item) => item.staffId === row.staffId
                          ),
                        })
                      }
                      onResetPassword={() => resetPassword(row.email)}
                      onExpense={() =>
                        navigate(`/view-staff-expenses/${row.staffId}`, {
                          state: filteredItems.find(
                            (item) => item.staffId === row.staffId
                          ),
                        })
                      }
                    />
                  </div>
                </div>
              ),
            },
            ...allStaffColumn,
          ]}
          pagination
          paginationResetDefaultPage={resetPaginationToggle}
          subHeader
          subHeaderComponent={subHeaderComponentMemo}
          progressPending={staffState.isGetAllStaffLoading}
          responsive
          highlightOnHover
          pointerOnHover
          customStyles={customStyles}
        />
      </div>

      <Modal isOpen={isOpen}>
        <Modal.Content>
          <Center bg="white.100" p={10}>
            <IconButton
              variant="ghost"
              onPress={onClose}
              position="absolute"
              top={2}
              right={5}
              icon={<FaWindowClose color="red" size={20} />}
            />
            <Text mt={4}>SELECT A GROUP TO ALLOCATE FUNDS</Text>
            <Select
              mt={5}
              selectedValue={allocationData.groupName}
              bg="emerald.200"
              accessibilityLabel="Choose Group"
              placeholder="Choose Group"
              _selectedItem={{
                bg: "teal.600",
                endIcon: <CheckIcon size="5" />,
              }}
              onValueChange={(itemValue) => {
                setAllocationData(
                  staffState.allStaffGroups.find(
                    (group) => group.groupName === itemValue
                  )
                );
              }}
            >
              {groups.map((group) => (
                <Select.Item label={group.name} value={group.name} />
              ))}
            </Select>

            <Button
              mt={5}
              colorScheme="green"
              onPress={() => allocateFunds(allocateFunds?.groupName)}
              isLoading={staffState.isResetAmountSpentLoading}
              _loading={{ color: "green.500" }}
            >
              ALLOCATE FUNDS {group.name}
            </Button>

            <Text
              textAlign={"center"}
              fontSize="xs"
              px={2}
              color="red.600"
              mt={10}
            >
              NB: This action will affect every member in the group, if you wish
              to allocate funds to an individual, please select allocate funds
              from actions
            </Text>
          </Center>
        </Modal.Content>
      </Modal>
    </Center>
  );
}
