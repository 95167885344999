import React, { useState, useEffect, useLayoutEffect } from "react";
import { useLocation, useNavigate } from "react-router";
import {
  Center,
  VStack,
  Text,
  HStack,
  Button,
  useToast,
  Link,
  TextArea,
  FormControl,
} from "native-base";
import { CustomInput as Input } from "../components /CustomInput";
import Swal from "sweetalert2";

// redux imports
import { useDispatch, useSelector } from "react-redux";
import {
  resetSpendableBalance,
  getAllStaffGroups,
} from "../redux/middleware/staffMiddleware";
import {
  resetActionType,
  resetSpentAmountError,
  resetSpentAmountSuccess,
} from "../redux/reducers/StaffReducer";

export default function AllocateFunds() {
  const location = useLocation();
  const navigate = useNavigate();
  const staffState = useSelector((state) => state.staff);
  const authState = useSelector((state) => state.authentication);
  const dispatch = useDispatch();
  const toast = useToast();

  const group = staffState.allStaffGroups.find(
    (group) => group.groupName === location.state.groupName
  );

  const [amount, setAmount] = useState(group.allocatedFunds);
  const [comment, setComment] = useState("");

  console.log(amount);

  const allocateFunds = () => {
    if (amount.match(/^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/) == null) {
      toast.show({
        description: "Please enter a valid amount",
        status: "error",
        placement: "top",
      });
      return;
    }

    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(
          resetSpendableBalance(
            location.state.staffId,
            location.state.fullname,
            location.state.remainingBalance,
            amount,
            group.groupName,
            authState.currentUser.id,
            comment
          )
        );
      } else {
        Swal.fire("Cancelled", "", "error");
      }
    });
  };

  useEffect(() => {
    if (staffState.ACTION_TYPE === resetSpentAmountSuccess.toString()) {
      Swal.fire({
        icon: "success",
        title: "Success",
        text: staffState.resetSpentAmountMessage,
        confirmButtonText: "OK",
      }).then(() => {
        dispatch(resetActionType());
        navigate("/staff");
        return;
      });
    } else if (staffState.ACTION_TYPE === resetSpentAmountError.toString()) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: staffState.resetSpentAmountMessage,
        confirmButtonText: "OK",
      }).then(() => {
        dispatch(resetActionType());
        return;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [staffState.ACTION_TYPE]);

  useLayoutEffect(() => {
    dispatch(getAllStaffGroups());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Center py={10}>
      <VStack
        w={{ base: "90%", md: "70%" }}
        shadow="3"
        bg="white"
        rounded="md"
        overflow="hidden"
      >
        <Center bg="teal.500" p={4}>
          <Button
            variant="outline"
            colorScheme="gray"
            _text={{ color: "white" }}
            position="absolute"
            left="5"
            onPress={() => navigate("/staff")}
          >
            GO BACK
          </Button>
          <Text fontSize={["lg", "2xl"]} color="white" ml={10}>
            ALLOCATE FUNDS
          </Text>
        </Center>

        <Center py={5} px={10}>
          <HStack w="100%" space={5} justifyContent={"space-between"}>
            <Text fontSize={["md", "lg"]}>Staff Name</Text>
            <Text fontSize={["md", "lg"]}>{location.state.fullname}</Text>
          </HStack>

          <HStack w="100%" space={5} justifyContent={"space-between"}>
            <Text fontSize={["md", "lg"]}>Remaining Balance</Text>
            <Text fontSize={["md", "lg"]}>
              GHS {parseFloat(location.state.remainingBalance).toFixed(2)}
            </Text>
          </HStack>

          <HStack w="100%" space={5} justifyContent={"space-between"}>
            <Text fontSize={["md", "lg"]} fontWeight="bold">
              Group Name
            </Text>
            <Text fontSize={["md", "lg"]} fontWeight="bold">
              {location.state.groupName}
            </Text>
          </HStack>

          <HStack w="100%" space={5} justifyContent={"space-between"}>
            <Text fontSize={["md", "lg"]} fontWeight="bold">
              Default Allocation Amount
            </Text>
            <Text fontSize={["md", "lg"]} fontWeight="bold">
              GH₵ {parseFloat(group.allocatedFunds).toFixed(2)}
            </Text>
          </HStack>
        </Center>

        <Center py={5} px={10}>
          <Input
            label="Enter Amount"
            placeholder="Enter Amount"
            keyboardType="number-pad"
            value={amount}
            onChangeText={(text) => {
              setAmount(text);
            }}
          />

          <FormControl w={{ base: "90%", md: "80%" }} mt={5}>
            <FormControl.Label>Enter Comment</FormControl.Label>
            <TextArea
              placeholder="enter commnet"
              value={comment}
              onChangeText={(text) => {
                setComment(text);
              }}
            />
          </FormControl>
        </Center>

        <Center my={5}>
          <Button
            onPress={allocateFunds}
            isLoading={staffState.isResetAmountSpentLoading}
            _loading={{ color: "green.500" }}
          >
            ALLOCATE FUNDS
          </Button>
        </Center>
        <Center mt={4} mb={5}>
          <Link _text={{ color: "cyan.500" }} href="/staffgroup">
            you can edit the group data here
          </Link>
        </Center>
      </VStack>
    </Center>
  );
}
