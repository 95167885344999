import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Center,
  VStack,
  Text,
  Button,
  HStack,
  Menu,
  Pressable,
  Box,
  FlatList,
  Tooltip,
} from "native-base";
import { FiChevronDown, FiChevronLeft } from "react-icons/fi";
import { db } from "../../firebase/firebase-config";
import { collection, onSnapshot, query, where } from "@firebase/firestore";
import ValidateCard from "../../components /ValidateCard";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { CSVLink } from "react-csv";
import empty from "../../assets/empty.png";
import { MdFileDownload } from "react-icons/md";
import Swal from "sweetalert2";

// ! convert to date
const convertDate = (value) => {
  try {
    return (
      value.toDate().toString().split(" ")[1] +
      " " +
      value.toDate().toString().split(" ")[2] +
      " " +
      value.toDate().toString().split(" ")[3] +
      " " +
      value.toDate().toString().split(" ")[4]
    );
  } catch (error) {
    console.log(error);
    return "";
  }
};



const Salesdetails = () => {
  const navigate = useNavigate();
  // dashboard states
  const [attendantSales, setAttendantSales] = React.useState([]);
  const [allAttendantSales, setAllAttendantSales] = React.useState([]);

  // selection state
  const [selectedAttendant, setSelectedAttendant] = React.useState();
  const [filteredDataByAttendantId, setFilteredDataByAttendantId] =
    React.useState([]);
  // const [filteredDataByFactor, setFilteredDataByFactor] = React.useState([]);

  // date state
  const [dateRange, setDateRange] = React.useState([null, null]);
  const [startDate, endDate] = dateRange;

  //  listen to snapshop of attendants
  React.useLayoutEffect(() => {
    const q = query(
      collection(db, "attendant"),
      where("isVerified", "==", true),
      where("refId", "!=", "40048")
    );

    const unsubScribe = onSnapshot(q, (snapshot) => {
      const attendantList = [];
      snapshot.forEach((doc) => {
        const name = doc.data().fullname;
        const value = doc.data().totalSales;
        const id = doc.data().refId;

        attendantList.push({ name: name, value: value, id: id });
      });
      setAttendantSales(attendantList);
    });

    return () => {
      unsubScribe();
    };
  }, []);

  // listen to snapshot for attendant sales data
  React.useLayoutEffect(() => {
    const q = query(
      collection(db, "attendant-sales"),
      where("pinConfirmed", "==", true)
    );

    const unsubScribe = onSnapshot(q, (snapshot) => {
      const data = snapshot.docs.map((doc) => ({
        ...doc.data(),
      }));

      setAllAttendantSales(data);
    });

    return () => {
      unsubScribe();
    };
  }, []);

  const ExampleCustomInput = React.forwardRef(({ value, onClick }, ref) => (
    <Button
      variant="outline"
      onPress={onClick}
      ref={ref}
      colorScheme="gray"
      _text={{ color: "white" }}
      pr={8}
    >
      {value ? value : "Select Date Range"}
    </Button>
  ));

  React.useLayoutEffect(() => {}, [filteredDataByAttendantId]);

  return (
    <Center py={5}>
      <VStack
        w={["95%", "75%"]}
        shadow="3"
        bg="white"
        rounded="md"
        overflow="hidden"
      >
        <Box bg="teal.500" p={4}>
          <Center pb={3}>
            <Pressable
              position="absolute"
              left={0}
              _pressed={{ opacity: 0.5 }}
              onPress={() => navigate(-1)}
              zIndex={1}
            >
              <FiChevronLeft color="white" size={20} />
            </Pressable>
            <Text fontSize="xl" color="white">
              SALES DETAILS
            </Text>

            <Box position="absolute" right={5}>
              <CSVLink
                data={
                  startDate
                    ? filteredDataByAttendantId
                        .filter(
                          (expense) =>
                            new Date(convertDate(expense.purchaseDate)) >=
                              startDate &&
                            new Date(convertDate(expense.purchaseDate)) <=
                              endDate
                        )
                        .map((expense) => {
                          return {
                            "Fuel Purchase Amount": expense.amountSpent,
                            "Customer Balance Remaining":
                              expense.remainingBalance,
                            "Customer Name": expense.fullname,
                            "Customer Email": expense.email,
                            "Attendant ID": expense.attendantId,
                            "Cunstomer Car Number": expense.carNumber,
                            "Purchase Date": convertDate(expense.purchaseDate),
                            "Validation Date": convertDate(
                              expense.validationTime
                            ),
                            "Transaction ID": expense.transactionId,
                            "Attendant Name": expense.attendantName,
                            Litres: expense.litres,
                            "Price Per Litres": expense.pricePerLitres,
                          };
                        })
                    : filteredDataByAttendantId.map((expense) => {
                        return {
                          "Fuel Purchase Amount": expense.amountSpent,
                          "Customer Balance Remaining":
                            expense.remainingBalance,
                          "Customer Name": expense.fullname,
                          "Customer Email": expense.email,
                          "Attendant ID": expense.attendantId,
                          "Cunstomer Car Number": expense.carNumber,
                          "Purchase Date": convertDate(expense.purchaseDate),
                          "Validation Date": convertDate(
                            expense.validationTime
                          ),
                          "Transaction ID": expense.transactionId,
                          "Attendant Name": expense.attendantName,
                          Litres: expense.litres,
                          "Price Per Litres": expense.pricePerLitres,
                        };
                      })
                }
                filename={`${filteredDataByAttendantId[0]?.attendantName}-sales.csv`}
              >
                <Tooltip label="Export to Excel">
                  <Center bg="white" p={2} rounded="md">
                    <MdFileDownload size={20} color="green" />
                  </Center>
                </Tooltip>
              </CSVLink>
            </Box>
          </Center>
          <HStack flexWrap="wrap" justifyContent="space-between">
            <Menu
              trigger={(triggerProps) => {
                return (
                  <Pressable
                    accessibilityLabel="More options menu"
                    {...triggerProps}
                    flexDirection="row"
                    alignItems="center"
                  >
                    <Text fontSize="lg" color="white" mr={1}>
                      {selectedAttendant || "Select Attendant"}
                    </Text>
                    <FiChevronDown color="white" size={20} />
                  </Pressable>
                );
              }}
            >
              {attendantSales.map((attendant) => {
                return (
                  <Menu.Item
                    onPress={() => {
                      setDateRange([]);
                      setSelectedAttendant(attendant.name);
                      //   filter allAttendantSales by attendant id
                      setFilteredDataByAttendantId(
                        allAttendantSales.filter(
                          (attendantSales) =>
                            attendantSales.attendantId === attendant.id
                        )
                      );
                    }}
                  >
                    {attendant.name}
                  </Menu.Item>
                );
              })}
            </Menu>

            <DatePicker
              selectsRange={true}
              startDate={startDate}
              endDate={endDate}
              onChange={(update) => {
                setDateRange(update);
              }}
              customInput={<ExampleCustomInput />}
              withPortal
              isClearable
            />
          </HStack>
        </Box>
        <FlatList
          h="72vh"
          zIndex={-1}
          pt={5}
          data={
            startDate
              ? filteredDataByAttendantId
                  .filter(
                    (expense) =>
                      new Date(convertDate(expense.validationTime)) >=
                        startDate &&
                      new Date(convertDate(expense.validationTime)) <=
                        new Date(new Date(endDate).setHours(23, 59, 59, 999))
                  )
                  .sort((a, b) => {
                    return (
                      new Date(convertDate(b.validationTime)) -
                      new Date(convertDate(a.validationTime))
                    );
                  })
              : filteredDataByAttendantId.sort((a, b) => {
                  return (
                    new Date(convertDate(b.validationTime)) -
                    new Date(convertDate(a.validationTime))
                  );
                })
          }
          ListEmptyComponent={
            <Center h="72vh">
              <Text fontSize="lg" color="gray.500">
                NOTHING TO SHOW YET
              </Text>
              <Text color="gray.400">
                Select an attendant to see his/her sales details.
              </Text>
              <Text color="gray.400">
                You can filter the sales by date range.
              </Text>
            </Center>
          }
          renderItem={({ item }) => (
            <ValidateCard
              fullname={item.fullname}
              carNumber={item.carNumber}
              transactionId={item.transactionId}
              amountSpent={item.amountSpent}
              isDeclined={item.isDeclined}
              isValidated={item.isValidated}
              purchaseDate={new Date(
                item.purchaseDate.seconds * 1000
              ).toUTCString()}
              validatedDate={new Date(
                item.validationTime.seconds * 1000
              ).toUTCString()}
              source={item.imageUrl === "" ? empty : item.imageUrl}
              litres={item.litres}
              pricePerLitres={item.pricePerLitres}
              comment={item.comment}
              onComment={() =>
                Swal.fire({
                  text: item.comment,
                  confirmButtonText: "Done",
                })
              }
            />
          )}
        />
        <HStack
          justifyContent={"space-between"}
          bg="teal.500"
          p={2}
          px={10}
          alignItems="flex-end"
        >
          <Text fontSize="lg" color="white">
            Total Sales: GHC{" "}
            {startDate
              ? filteredDataByAttendantId
                  .filter(
                    (expense) =>
                      new Date(convertDate(expense.validationTime)) >=
                        startDate &&
                      new Date(convertDate(expense.validationTime)) <=
                        new Date(new Date(endDate).setHours(23, 59, 59, 999))
                  )
                  .reduce((a, b) => +a + +b.amountSpent, 0)
              : filteredDataByAttendantId.length > 0
              ? filteredDataByAttendantId
                  .reduce((a, b) => +a + +b.amountSpent, 0)
                  .toFixed(2)
              : 0}
          </Text>

          {/* // * checks if there is start Date, use data filtered by date else use raw data */}
          <Text fontSize="lg" color="white">
            Total Litres:{" "}
            {startDate
              ? filteredDataByAttendantId
                  .filter(
                    (expense) =>
                      new Date(convertDate(expense.validationTime)) >=
                        startDate &&
                      new Date(convertDate(expense.validationTime)) <=
                        new Date(new Date(endDate).setHours(23, 59, 59, 999))
                  )
                  .reduce((a, b) => +a + +b.litres, 0)
              : filteredDataByAttendantId.length > 0
              ? filteredDataByAttendantId
                  .reduce((a, b) => +a + +b.litres, 0)
                  .toFixed(2)
              : 0}{" "}
            Ltrs
          </Text>
        </HStack>
      </VStack>
    </Center>
  );
};

export default Salesdetails;
