import React from "react";
import { Center, HStack, Text, Box, Image, Button } from "native-base";
import TimeAgo from "react-timeago";
export default function ExpenseCard({
  purchaseDate,
  amountRemaining,
  amountSpent,
  spendableBalance,
  attendantId,
  attendantName,
  source,
  id,
  litres,
  pricePerLitres,
  comment,
  onComment,
}) {
  return (
    <Box
      bg="red.100"
      p={4}
      rounded="lg"
      w="80%"
      mb={5}
      alignItems="center"
      alignSelf="center"
    >
      <HStack alignItems="center" justifyContent="space-between" w="90%">
        <TimeAgo
          live={true}
          date={purchaseDate}
          style={{ fontStyle: "Questrial", fontSize: ".8rem" }}
        />
        <Text>{id}</Text>
      </HStack>

      <HStack alignItems="center" w="100%">
        <Box flex={4} p={4}>
          <Image source={source} w="100%" h={150} bg="red.100" rounded="sm" />
        </Box>
        <Box flex={6} mt={3}>
          <HStack
            alignItems="center"
            mb={2}
            justifyContent="space-between"
            w="90%"
          >
            <Text mr={2}>Amount</Text>
            <Text fontWeight="bold" textAlign={"right"}>
              GH₵ {parseFloat(amountSpent).toFixed(2)}
            </Text>
          </HStack>

          <HStack
            alignItems="center"
            mb={2}
            justifyContent="space-between"
            w="90%"
          >
            <Text mr={2}>Litres</Text>
            <Text fontWeight="bold" textAlign={"right"}>
              {parseFloat(litres).toFixed(2)} Ltrs
            </Text>
          </HStack>

          <HStack
            alignItems="center"
            mb={2}
            justifyContent="space-between"
            w="90%"
          >
            <Text mr={2}>Price Per Litres</Text>
            <Text fontWeight="bold" textAlign={"right"}>
              GH₵ {parseFloat(pricePerLitres).toFixed(2)}
            </Text>
          </HStack>

          <HStack
            alignItems="center"
            mb={2}
            justifyContent="space-between"
            w="90%"
          >
            <Text mr={2}>Purchased on</Text>
            <Text fontWeight="bold" textAlign={"right"}>
              {purchaseDate}
            </Text>
          </HStack>

          <HStack
            alignItems="center"
            mb={2}
            justifyContent="space-between"
            w="90%"
          >
            <Text mr={2}>Served by</Text>
            <Text fontWeight="bold" textAlign={"right"}>
              {attendantName}
            </Text>
          </HStack>
        </Box>
      </HStack>

      <Center mt={3}>
        {comment && <Button onPress={onComment}>see comment</Button>}
      </Center>
    </Box>
  );
}
