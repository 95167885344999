import React from "react";
import { useNavigate, useLocation } from "react-router";
import {
  HStack,
  Button,
  ScrollView,
  Text,
  Box,
  Heading,
  Image,
} from "native-base";
import { FiChevronLeft } from "react-icons/fi";
import dlogo from "../assets/donewelllife-logo.svg";
import rlogo from "../assets/readyoil.jpeg";
import "../css/table.css";
import { salesColumn } from "../utils/columns";
import ReactToPrint from "react-to-print";

const ExportSalesToPDF = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;

  const salesRef = React.useRef();

  return (
    <ScrollView stickyHeaderIndices={[0]}>
      <HStack justifyContent={"space-between"} bg="gray.100" p={3} w="100%">
        <Button onPress={() => navigate(-1)}>
          <FiChevronLeft color="white" size="20" />
        </Button>

        <ReactToPrint
          trigger={() => (
            <button
              style={{
                border: "none",
                padding: 10,
                borderRadius: 5,
                background: "#2DB7D4",
              }}
            >
              <Text color="white">SAVE AS PDF</Text>
            </button>
          )}
          content={() => salesRef.current}
        />
      </HStack>
      <Box pb={10} bg="white" ref={salesRef}>
        <ScrollView
          // contentContainerStyle={{ py: 5, px: 5, }}
          p={10}
        >
          {/* title block */}
          <HStack
            w="100%"
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Image source={dlogo} size="md" resizeMode="contain" h="10" />
            <Box alignItems={"center"}>
              <Heading>{state.title}</Heading>
              <Text color='gray.400' fontSize='xs'>
                ({new Date(state.period.startDate).toDateString()} -{" "}
                {new Date(state.period.endDate).toDateString()} )
              </Text>
            </Box>
            <Image source={rlogo} size="md" resizeMode="contain" h="10" />
          </HStack>

          {/* table block */}
          <HStack pt={5} justifyContent={"flex-end"} w="100%">
            <Box>
              <Heading fontSize="lg" color="green.700" textAlign={"right"}>
                TOTAL AMOUNT: GH₵{" "}
                {state.data.reduce((a, b) => +a + +b.amountSpent, 0).toFixed(2)}
              </Heading>
              <Heading fontSize="lg" color="green.700" textAlign={"right"}>
                TOTAL LITRES:{" "}
                {state.data.reduce((a, b) => +a + +b.litres, 0).toFixed(2)} Ltrs
              </Heading>
            </Box>
          </HStack>
          <table className="styled-table" style={{ marginTop: 50 }}>
            <thead>
              <tr>
                {salesColumn.map((item, index) => {
                  return <th key={index}>{item.name}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {state.data.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>
                      <Text>{item.transactionId}</Text>
                    </td>
                    <td>
                      <Text>
                        {new Date(
                          item.purchaseDate.seconds * 1000
                        ).toUTCString()}
                      </Text>
                    </td>
                    <td>
                      <Text>
                        {new Date(
                          item.validationTime.seconds * 1000
                        ).toUTCString()}
                      </Text>
                    </td>
                    <td>
                      <Text>GH₵ {parseFloat(item.amountSpent).toFixed(2)}</Text>
                    </td>
                    <td>
                      <Text>{item.litres.toFixed(2)} L</Text>
                    </td>
                    <td>
                      <Text>GH₵ {item.pricePerLitres.toFixed(2)}</Text>
                    </td>
                    <td>
                      <Text>{item.fullname}</Text>
                    </td>
                    <td>
                      <Text>{item.attendantName}</Text>
                    </td>
                    <td>
                      <Text>{item.carNumber}</Text>
                    </td>
                  </tr>
                );
              })}
            </tbody>
            <tfoot>
              <tr>
                <td colspan="12">
                  <Text>number of records {state.data.length}</Text>
                </td>
              </tr>
            </tfoot>
          </table>
        </ScrollView>
      </Box>
    </ScrollView>
  );
};

export default ExportSalesToPDF;
