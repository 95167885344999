// ! convert to date
const convertDate = (value) => {
  try {
    return (
      value.toDate().toString().split(" ")[1] +
      " " +
      value.toDate().toString().split(" ")[2] +
      " " +
      value.toDate().toString().split(" ")[3]
    );
  } catch (error) {
    console.log(error);
    return "";
  }
};

const formatDate = (date) => {
  try {
    return (
      date.toString().split(" ")[1] +
      " " +
      date.toString().split(" ")[2] +
      " " +
      date.toString().split(" ")[3]
    );
  } catch (error) {
    console.log(error);
    return "";
  }
};

// get first day and last day of current week
export const getFirstDayOfWeek = () => {
  const today = new Date();
  const day = today.getDay();
  const diff = today.getDate() - day + (day === 0 ? -6 : 1);
  const firstDay = new Date(today.setDate(diff));
  return formatDate(firstDay);
};

export const getLastDayOfWeek = () => {
  const today = new Date();
  const day = today.getDay();
  const diff = today.getDate() - day + (day === 0 ? -6 : 1);
  const firstDay = new Date(today.setDate(diff));
  const lastDay = new Date(firstDay.setDate(firstDay.getDate() + 6));
  return formatDate(lastDay);
};

// get first day and last day of current month
export const getFirstDayOfMonth = () => {
  const today = new Date();
  const firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
  return formatDate(firstDay);
};

export const getLastDayOfMonth = () => {
  const today = new Date();
  const lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);
  return formatDate(lastDay);
};

// get first day and last day of current year
export const getFirstDayOfYear = () => {
  const today = new Date();
  const firstDay = new Date(today.getFullYear(), 0, 1);
  return formatDate(firstDay);
};

export const getLastDayOfYear = () => {
  const today = new Date();
  const lastDay = new Date(today.getFullYear(), 11, 31);
  return formatDate(lastDay);
};
