import React from "react";
import { useNavigate } from "react-router-dom";

import {
  Center,
  VStack,
  Stack,
  Text,
  Button,
  useToast,
  FormControl,
  Checkbox,
} from "native-base";
import { CustomInput as Input } from "../components /CustomInput";

import Swal from "sweetalert2";
import "sweetalert2/src/sweetalert2.scss";

import { useDispatch, useSelector } from "react-redux";
import { createStaffGroup } from "../redux/middleware/staffMiddleware";
import {
  createStaffGroupSuccess,
  createStaffGroupError,
  resetActionType,
} from "../redux/reducers/StaffReducer";

const Createstaffgroup = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const staffState = useSelector((state) => state.staff);
  const authState = useSelector((state) => state.authentication);
  const dispatch = useDispatch();

  const [groupName, setGroupName] = React.useState("");
  const [allocatedFunds, setAllocatedFunds] = React.useState("");
  const [canExceed, setCanExceed] = React.useState(false);
  const [canEnterCarNumber, setCanEnterCarNumber] = React.useState(false);
  const [canTransferBalance, setCanTransferBalance] = React.useState(false);

  const handleSubmit = () => {
    if (groupName === "" || allocatedFunds === "") {
      toast.show({
        description: "Please fill all the fields",
        status: "warning",
        placement: "top",
      });
      return;
    }
    if (allocatedFunds.match(/^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/) == null) {
      toast.show({
        title: "Value not a number",
        description: "Please enter a valid amount for funds allocatable",
        status: "error",
        placement: "top",
      });
      return;
    }

    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "YES, CREATE IT!",
      cancelButtonText: "CANCEL",
    }).then((result) => {
      if (result.isConfirmed) {
        const data = {
          groupName,
          allocatedFunds,
          canExceed,
          canEnterCarNumber,
          canTransferBalance,
        };

        dispatch(createStaffGroup(data, authState.currentUser.id));
      }
    });
  };

  React.useEffect(() => {
    if (staffState.ACTION_TYPE === createStaffGroupSuccess.toString()) {
      Swal.fire({
        title: "Success",
        text: staffState.createStaffGroupMessage,
        icon: "success",
        confirmButtonText: "OK",
      }).then(() => {
        dispatch(resetActionType());
        navigate("/staffgroup");
      });
    } else if (staffState.ACTION_TYPE === createStaffGroupError.toString()) {
      Swal.fire({
        title: "Error",
        text: staffState.createStaffGroupMessage,
        icon: "error",
        confirmButtonText: "OK",
      }).then(() => {
        dispatch(resetActionType());
      });
    }
  }, [
    staffState.ACTION_TYPE,
    navigate,
    dispatch,
    staffState.createStaffGroupMessage,
  ]);

  // console.log(staffState);

  return (
    <Center py={10}>
      <VStack
        w={{ base: "90%", md: "70%" }}
        shadow="3"
        bg="white"
        rounded="md"
        overflow="hidden"
      >
        <Center bg="teal.500" p={4}>
          <Text fontSize="2xl" color="white">
            CREATE STAFF GROUP
          </Text>
        </Center>

        <Stack py={10} alignItems="center" space={5}>
          <Input
            label="Group Name"
            value={groupName}
            onChangeText={setGroupName}
          />
          <Input
            label="Funds Allocatable"
            value={allocatedFunds}
            onChangeText={setAllocatedFunds}
          />
          <FormControl w={{ base: "90%", md: "80%" }} flexDirection={"row"}>
            <FormControl.Label>Can Exceed</FormControl.Label>
            <Checkbox
              value={canExceed}
              onChange={() => setCanExceed(!canExceed)}
              color="teal.500"
            />
          </FormControl>

          <FormControl w={{ base: "90%", md: "80%" }} flexDirection={"row"}>
            <FormControl.Label>Can Enter Car Number</FormControl.Label>
            <Checkbox
              value={canEnterCarNumber}
              onChange={() => setCanEnterCarNumber(!canEnterCarNumber)}
              color="teal.500"
            />
          </FormControl>

          <FormControl w={{ base: "90%", md: "80%" }} flexDirection={"row"}>
            <FormControl.Label>Can Enter Transfer Balance</FormControl.Label>
            <Checkbox
              value={canTransferBalance}
              onChange={() => setCanTransferBalance(!canTransferBalance)}
              color="teal.500"
            />
          </FormControl>

          <Button
            colorScheme="emerald"
            onPress={handleSubmit}
            isLoading={staffState.isCreateStaffGroupLoading}
            _loading={{ bg: "emerald.500" }}
            isLoadingText="Creating Staff..."
          >
            CREATE GROUP
          </Button>
        </Stack>
      </VStack>
    </Center>
  );
};

export default Createstaffgroup;
