import React from "react";
import { Routes, Route, Navigate } from "react-router";

import Staff from "../pages/Staff";
import CreateStaff from "../pages/CreateStaff";
import AddStaffDetails from "../pages/AddStaffDetails";
import EditStaff from "../pages/EditStaff";
import AllocateFunds from "../pages/AllocateFunds";
import ViewStaffExpenses from "../pages/ViewStaffExpenses";
import Dashboard from "../pages/FuelStation/Dashboard";
import SalesDetails from "../pages/FuelStation/SalesDetails";
import Createstaffgroup from "../pages/CreateStaffGroup";
import EditStaffGroup from "../pages/EditStaffGroup";
import StaffGroup from "../pages/StaffGroup";
import SalesReport from "../pages/reports/SalesReport";
import AllocationsReport from "../pages/reports/AllocationsReport";
import AdminActivityLog from "../pages/reports/AdminActivityLog";
import ExportSalesToPDF from "../pages/ExportSalesToPDF";
import ExportAllocationsToPDF from "../pages/ExportAllocationsToPDF";
import AddCar from "../pages/AddCar";
import Fleet from "../pages/Fleet";
import EditCar from "../pages/EditCar";

import { Box, Hidden, ScrollView } from "native-base";
import Sidebar from "../components /Sidebar";
import Navbar from "../components /Navbar";

export default function AdminLayout() {
  return (
    <Box bg="gray.100" flexDirection={{ base: "column", md: "row" }}>
      <Hidden from="base" till="md">
        <Sidebar />
      </Hidden>

      <ScrollView flex={1} stickyHeaderIndices={[1]}>
        <Hidden from="md">
          <Navbar />
        </Hidden>

        <Routes>
          <Route path="/" element={<Dashboard />} />
          <Route path="/staff" element={<Staff />} />
          <Route path="/add-staff" element={<CreateStaff />} />
          <Route path="/add-staff-details/:id" element={<AddStaffDetails />} />
          <Route path="/allocate-funds/:id" element={<AllocateFunds />} />
          <Route
            path="/view-staff-expenses/:id"
            element={<ViewStaffExpenses />}
          />
          <Route path="/sales-details" element={<SalesDetails />} />
     
          <Route path="/edit-staff/:id" element={<EditStaff />} />
          <Route path="/createstaffgroup" element={<Createstaffgroup />} />
          <Route path="/editstaffgroup/:id" element={<EditStaffGroup />} />
          <Route path="/staffgroup" element={<StaffGroup />} />

          <Route path="/report-sales" element={<SalesReport />} />
          <Route path="/report-allocations" element={<AllocationsReport />} />
          <Route
            path="/report-admin-activity-log"
            element={<AdminActivityLog />}
          />

          <Route path="/export-sales-to-pdf" element={<ExportSalesToPDF />} />
          <Route
            path="/export-allocations-to-pdf"
            element={<ExportAllocationsToPDF />}
          />

          <Route path="/add-car" element={<AddCar />} />
          <Route path="/fleet" element={<Fleet />} />
          <Route path="/edit-car/:id" element={<EditCar />} />

          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </ScrollView>
    </Box>
  );
}
