import React from "react";
// eslint-disable-next-line no-unused-vars
import { useNavigate, Link } from "react-router";
import {
  Center,
  HStack,
  Button,
  Heading,
  Menu,
  Pressable,
  Text,
  Tooltip,
  Spinner,
  Stack,
} from "native-base";
import DataTable from "react-data-table-component";
import { customStyles } from "../../customStyles";
import { salesColumn } from "../../utils/columns";
import { FilterComponent } from "../../utils/export";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { CSVLink } from "react-csv";
import { MdFileDownload, MdFilterAlt } from "react-icons/md";
import { BsFillCalendar3WeekFill, BsFillFilePdfFill } from "react-icons/bs";

// firestore imports
import { db } from "../../firebase/firebase-config";
import { collection, onSnapshot, query, where } from "@firebase/firestore";

// date filters
import {
  yesterday,
  lastWeekStart,
  lastWeekEnd,
  lastMonthStart,
  lastMonthEnd,
  thisMonthStart,
  thisMonthEnd,
  lastYearStart,
  lastYearEnd,
  thisYearStart,
  thisYearEnd,
  convertDate,
} from "../../utils/datefilters";

export default function SalesReport() {
  const [salesData, setSalesData] = React.useState([]);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = React.useState(false);

  React.useLayoutEffect(() => {
    const q = query(
      collection(db, "attendant-sales"),
      where("isValidated", "==", true),
      where("isDeclined", "==", false),
      where("pinConfirmed", "==", true),
      where("refId", "!=", "40048")
    );

    const unsubScribe = onSnapshot(q, (snapshot) => {
      setIsLoading(true);
      const sales = [];
      snapshot.forEach((doc) => {
        const data = doc.data();

        sales.push(data);
      });
      // setNewAttendants(attendantList);
      setSalesData(sales);
      setIsLoading(false);
    });

    return () => {
      unsubScribe();
    };
  }, []);

  // console.log(salesData);

  console.log(thisYearEnd());

  // table structure
  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);

  // date state
  const [dateRange, setDateRange] = React.useState([
    thisMonthStart().setHours(0, 0, 0, 0),
    thisMonthEnd().setHours(23, 59, 59, 999),
  ]);
  const [startDate, endDate] = dateRange;

  const ExampleCustomInput = React.forwardRef(({ value, onClick }, ref) => (
    <Tooltip label="Open Calendar to select range">
      <Button
        onPress={onClick}
        ref={ref}
        colorScheme="blue"
        _text={{ color: "white" }}
        pr={7}
      >
        {value ? value : <BsFillCalendar3WeekFill size="20" color="white" />}
      </Button>
    </Tooltip>
  ));

  const dateFilteredData = startDate
    ? salesData.filter(
        (expense) =>
          new Date(convertDate(expense.validationTime)) >= startDate &&
          new Date(convertDate(expense.validationTime)) <=
            new Date(new Date(endDate).setHours(23, 59, 59, 999))
      )
    : salesData.filter(
        (expense) =>
          new Date(convertDate(expense.validationTime)) >=
            thisMonthStart().setHours(0, 0, 0, 0) &&
          new Date(convertDate(expense.validationTime)) <=
            thisMonthEnd().setHours(23, 59, 59, 999)
      );

  //  thisMonthStart().setHours(0, 0, 0, 0),
  //                   thisMonthEnd().setHours(23, 59, 59, 999),

  const filteredItems = dateFilteredData
    .filter(function (item) {
      return (
        (item.fullname &&
          item.fullname.toLowerCase().includes(filterText.toLowerCase())) ||
        (item.email &&
          item.email.toLowerCase().includes(filterText.toLowerCase())) ||
        (item.attendantName &&
          item.attendantName
            .toLowerCase()
            .includes(filterText.toLowerCase())) ||
        (item.transactionId &&
          item.transactionId.toLowerCase().includes(filterText.toLowerCase()))
      );
    })
    .sort((a, b) => {
      return (
        new Date(convertDate(b.validationTime)) -
        new Date(convertDate(a.validationTime))
      );
    });

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
      }
    };
    return (
      <HStack justifyContent="space-between" w="100%">
        <HStack space={2}></HStack>

        <FilterComponent
          onFilter={(e) => setFilterText(e.target.value)}
          onClear={handleClear}
          filterText={filterText}
        />
      </HStack>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterText, resetPaginationToggle]);

  return (
    <Center py={5}>
      <Stack
        flexDir={{ base: "column", md: "row" }}
        w="100%"
        px={10}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Heading py={5}>Sales Report</Heading>

        <HStack space={3}>
          <Center py={2} px={5} bg="white" mb={3} rounded="md" shadow={1}>
            <Text fontSize="xs" color="green.400">
              TOTAL AMOUNT
            </Text>
            <Heading fontSize="lg" color="green.700">
              GH₵{" "}
              {filteredItems
                .reduce((a, b) => +a + +b.amountSpent, 0)
                .toFixed(2)}
            </Heading>
          </Center>

          <Center py={2} px={5} bg="white" mb={3} rounded="md" shadow={1}>
            <Text fontSize="xs" color="green.400">
              TOTAL LITRES
            </Text>
            <Heading fontSize="lg" color="green.700">
              {filteredItems.reduce((a, b) => +a + +b.litres, 0).toFixed(2)}{" "}
              Ltrs
            </Heading>
          </Center>
        </HStack>
      </Stack>

      {isLoading ? (
        <Center h="70vh">
          <Spinner color="red.500" />
          <Text color="gray.400">fetching report...</Text>
        </Center>
      ) : (
        <div
          className=" shadow pt-3 col-11 bg-white"
          style={{ borderRadius: 10, overflow: "hidden", zIndex: "-1" }}
        >
          {/* download and filter heavy components */}
          <HStack justifyContent="space-between" px={4} zIndex="100">
            <HStack space={4}>
              <CSVLink
                data={filteredItems.map((item) => {
                  return {
                    "Transaction ID": item.transactionId,
                    "Attendant Name": item.attendantName,
                    "Purchased By": item.fullname,
                    "Purchase Date": convertDate(item.purchaseDate),
                    "Confirmation Date": convertDate(item.validationTime),
                    "Amount Purchased": item.amountSpent,
                    Litres: item.litres,
                    "Price Per Litre": item.pricePerLitres,
                  };
                })}
                filename={"sales-report.csv"}
              >
                <Tooltip label="Export to Excel">
                  <Center bg="emerald.500" p={2} rounded="md">
                    <MdFileDownload size={20} color="white" />
                  </Center>
                </Tooltip>
              </CSVLink>

              <Tooltip label="Export to PDF">
                <Pressable
                  onPress={() =>
                    navigate("/export-sales-to-pdf", {
                      state: {
                        title: "Sales Report",
                        data: filteredItems,
                        key: "sales",
                        period: {
                          startDate,
                          endDate,
                        },
                      },
                    })
                  }
                >
                  <Center bg="red.500" p={2} rounded="md">
                    <BsFillFilePdfFill size={20} color="white" />
                  </Center>
                </Pressable>
              </Tooltip>
            </HStack>

            <HStack space={3} alignItems={"center"}>
              <Menu
                trigger={(triggerProps) => {
                  return (
                    <Tooltip label="Filter date">
                      <Pressable
                        bg="cyan.500"
                        p={2}
                        rounded="md"
                        accessibilityLabel="More options menu"
                        {...triggerProps}
                      >
                        <MdFilterAlt size={20} color="white" />
                      </Pressable>
                    </Tooltip>
                  );
                }}
              >
                <Menu.Item
                  px={5}
                  onPress={() => {
                    setDateRange([
                      new Date(),
                      new Date().setHours(23, 59, 59, 999),
                    ]);
                  }}
                >
                  Today
                </Menu.Item>
                <Menu.Item
                  px={5}
                  onPress={() => {
                    setDateRange([
                      yesterday().setHours(0, 0, 0, 0),
                      yesterday().setHours(23, 59, 59, 999),
                    ]);
                  }}
                >
                  Yesterday
                </Menu.Item>
                <Menu.Item
                  px={5}
                  onPress={() => {
                    setDateRange([
                      lastWeekStart().setHours(0, 0, 0, 0),
                      lastWeekEnd().setHours(23, 59, 59, 999),
                    ]);
                  }}
                >
                  Last 7 days
                </Menu.Item>
                <Menu.Item
                  px={5}
                  onPress={() => {
                    setDateRange([
                      lastMonthStart().setHours(0, 0, 0, 0),
                      lastMonthEnd().setHours(23, 59, 59, 999),
                    ]);
                  }}
                >
                  Last Month
                </Menu.Item>
                <Menu.Item
                  px={5}
                  onPress={() => {
                    setDateRange([
                      thisMonthStart().setHours(0, 0, 0, 0),
                      thisMonthEnd().setHours(23, 59, 59, 999),
                    ]);
                  }}
                >
                  This Month
                </Menu.Item>
                <Menu.Item
                  px={5}
                  onPress={() => {
                    setDateRange([
                      lastYearStart().setHours(0, 0, 0, 0),
                      lastYearEnd().setHours(23, 59, 59, 999),
                    ]);
                  }}
                >
                  Last Year
                </Menu.Item>
                <Menu.Item
                  px={5}
                  onPress={() => {
                    setDateRange([
                      thisYearStart().setHours(0, 0, 0, 0),
                      thisYearEnd().setHours(23, 59, 59, 999),
                    ]);
                  }}
                >
                  This Year
                </Menu.Item>
                <Menu.Item
                  px={5}
                  onPress={() => {
                    setDateRange([null, null]);
                  }}
                >
                  All Time
                </Menu.Item>
              </Menu>

              <DatePicker
                selectsRange={true}
                startDate={startDate}
                endDate={endDate}
                onChange={(update) => {
                  setDateRange(update);
                }}
                customInput={<ExampleCustomInput />}
                withPortal
                isClearable
              />
            </HStack>
          </HStack>

          {/* data table */}
          <DataTable
            data={filteredItems}
            columns={salesColumn}
            pagination
            paginationResetDefaultPage={resetPaginationToggle}
            subHeader
            subHeaderComponent={subHeaderComponentMemo}
            responsive
            highlightOnHover
            pointerOnHover
            customStyles={customStyles}
          />
        </div>
      )}
    </Center>
  );
}
