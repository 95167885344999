import { Text, Box, Popover, Button } from "native-base";

export const allStaffColumn = [
  {
    name: "Full Name",
    selector: (row) => row.fullname,
    sortable: true,
    style: {
      justifyContent: "left",
    },
  },

  {
    name: "Staff Email",
    selector: (row) => row.email,
    sortable: true,
  },
  {
    name: "Telephone Number",
    selector: (row) => row.phone,
    sortable: true,
  },
  {
    name: "Car Number",
    selector: (row) => row.carNumber,
    sortable: true,
  },
  {
    name: "Group",
    selector: (row) => row.groupName,
    sortable: true,
  },

  {
    name: "Available Balance",
    selector: (row) => `GH₵ ${parseFloat(row.remainingBalance).toFixed(2)}`,
    sortable: true,
  },
  {
    name: "Total Spent",
    selector: (row) => `GH₵ ${parseFloat(row.totalAmountSpent).toFixed(2)}`,
    sortable: true,
  },
  {
    name: "Blocked",
    selector: (row) => (row.isDisabled === true ? "Yes" : "No"),
    sortable: true,
  },
];

export const attendantsColumn = [
  {
    name: "Full Name",
    selector: (row) => row.fullname,
    sortable: true,
  },
  {
    name: "ID",
    selector: (row) => row.refId,
    sortable: true,
  },
  {
    name: "Email",
    selector: (row) => row.email,
    sortable: true,
  },
  {
    name: "Telephone",
    selector: (row) => row.phone,
    sortable: true,
  },
  {
    name: "Validated",
    selector: (row) =>
      row.isVerified === true ? (
        <Box px={5} py={1} bg="green.500" rounded="xs">
          <Text color="white">YES</Text>
        </Box>
      ) : (
        <Box px={5} py={1} bg="red.500" rounded="xs">
          <Text color="white">NO</Text>
        </Box>
      ),
    sortable: true,
  },
  {
    name: "Total Sales",
    selector: (row) => `GHC ${row.totalSales}`,
    sortable: true,
  },
];

// ! convert to date
const convertDate = (value) => {
  try {
    return (
      value.toDate().toString().split(" ")[1] +
      " " +
      value.toDate().toString().split(" ")[2] +
      " " +
      value.toDate().toString().split(" ")[3] +
      " " +
      value.toDate().toString().split(" ")[4]
    );
  } catch (error) {
    console.log(error);
    return "";
  }
};

export const salesColumn = [
  {
    name: "Transaction ID",
    selector: (row) => row.transactionId,
    sortable: true,
  },
  {
    name: "Purchase Date",
    selector: (row) => convertDate(row.purchaseDate),
    sortable: true,
    grow: 2,
  },
  {
    name: "Confirmation Date",
    selector: (row) => convertDate(row.validationTime),
    sortable: true,
    grow: 2,
  },
  {
    name: "Amount Purchased",
    selector: (row) => `GH₵ ${parseFloat(row.amountSpent).toFixed(2)}`,
    sortable: true,
    grow: 1,
  },
  {
    name: "Litres",
    selector: (row) => `${parseFloat(row.litres).toFixed(2)} L`,
    sortable: true,
    grow: 1,
  },
  {
    name: "Price Per Litres",
    selector: (row) => `GH₵ ${parseFloat(row.pricePerLitres).toFixed(2)}`,
    sortable: true,
    grow: 1,
  },
  {
    name: "Purchased By",
    selector: (row) => row.fullname,
    sortable: true,
  },
  {
    name: "Attendant",
    selector: (row) => row.attendantName,
    sortable: true,
  },
  {
    name: "Car Number",
    selector: (row) => row.carNumber,
    sortable: true,
  },
];

export const allocaationsColumn = [
  {
    name: "Comments",
    selector: (row) => row?.comment,
    sortable: true,
    cell: (row) => (
      <Popover
        trigger={(triggerProps) => {
          return (
            <Button
              {...triggerProps}
              size="xs"
              colorScheme="blue"
              variant={"outline"}
            >
              see comment
            </Button>
          );
        }}
      >
        <Popover.Content accessibilityLabel="See Comment" w="56">
          <Popover.Arrow />
          <Popover.CloseButton />
          <Popover.Header>Comment</Popover.Header>
          <Popover.Body>
            {row?.comment ?? "No comment available"}
          </Popover.Body>
        </Popover.Content>
      </Popover>
    ),
  },
  {
    name: "Allocation Date",
    selector: (row) => convertDate(row.allocatedDate),
    sortable: true,
    grow: 2,
  },
  {
    name: "Allocated To",
    selector: (row) => row.staffName,
    sortable: true,
  },
  {
    name: "Amount Allocated",
    selector: (row) => `GH₵ ${parseFloat(row.allocatedBalance).toFixed(2)}`,
    sortable: true,
  },
  {
    name: "Balance Brought Forward",
    selector: (row) => `GH₵ ${parseFloat(row.initialBalance).toFixed(2)}`,
    sortable: true,
  },
  {
    name: "Current Balance",
    selector: (row) => `GH₵ ${parseFloat(row.currentBalance).toFixed(2)}`,
  },
  {
    name: "Group",
    selector: (row) => row.group,
    sortable: true,
  },
];
