import { createSlice } from "@reduxjs/toolkit";

const INITIAL_STATE = {
  ACTION_TYPE: "",

  isLoginLoading: false,
  isLogOutLoading: false,

  loginMessage: "",

  isLoggedIn: false,

  currentUser: {},
};

const authenticationSlice = createSlice({
  name: "authentication",
  initialState: INITIAL_STATE,
  reducers: {
    loginStart: (state) => {
      state.ACTION_TYPE = loginStart.toString();
      state.isLoginLoading = true;
    },

    loginSuccess: (state, action) => {
      state.ACTION_TYPE = loginSuccess.toString();
      state.isLoginLoading = false;
      state.isLoggedIn = true;
    },

    loginError: (state, action) => {
      state.ACTION_TYPE = loginError.toString();
      state.isLoginLoading = false;
      state.loginMessage = action.payload.message;
    },

    logoutStart: (state) => {
      state.ACTION_TYPE = logoutStart.toString();
      state.isLogOutLoading = true;
    },

    logoutSuccess: (state) => {
      state.ACTION_TYPE = logoutSuccess.toString();
      state.isLogOutLoading = false;
      state.isLoggedIn = false;
    },

    logoutError: (state, action) => {
      state.ACTION_TYPE = logoutError.toString();
      state.isLogOutLoading = false;
      state.loginMessage = action.payload.message;
    },

    resetActionType: (state) => {
      state.ACTION_TYPE = resetActionType.toString();
    },

    setCurrentUser: (state, action) => {
      state.ACTION_TYPE = setCurrentUser.toString();
      state.currentUser = action.payload;
    }
  },
});

export const {
  loginStart,
  loginSuccess,
  loginError,
  logoutStart,
  logoutSuccess,
  logoutError,
  resetActionType,
  setCurrentUser,
} = authenticationSlice.actions;
export default authenticationSlice.reducer;
