import axios from "axios";
import {
  getAttendantsStart,
  getAttendantsSuccess,
  getAttendantsError,
  validateAttendantStart,
  validateAttendantSuccess,
  validateAttendantError,
  blockAttendantStart,
  blockAttendantSuccess,
  blockAttendantError,
  createAttendantStart,
  createAttendantSuccess,
  createAttendantError,
  createAdminStart,
  createAdminSuccess,
  createAdminError,
  editAttendantStart,
  editAttendantSuccess,
  editAttendantError,
} from "../reducers/AttendantReducer";

import { db } from "../../firebase/firebase-config";
import {
  collection,
  getDocs,
  updateDoc,
  setDoc,
  doc,
  writeBatch,
  query,
  where,
  addDoc,
} from "@firebase/firestore";

const APIKEY = process.env.REACT_APP_API_KEY;
const CREATE_USER_URL = `https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=`;
const attendantsCollection = collection(db, "attendant");

// get all station attendants
export const getAllAttendants = () => {
  return async (dispatch) => {
    dispatch(getAttendantsStart());
    try {
      const getAttendants = async () => {
        const data = getDocs(attendantsCollection);
        const attendantsData = (await data).docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
        dispatch(getAttendantsSuccess(attendantsData));
      };
      getAttendants();
    } catch (error) {
      dispatch(getAttendantsError({ message: error.message }));
    }
  };
};

// validate attendant
export const validateAttendant = (attendantId, refId) => {
  return async (dispatch) => {
    dispatch(validateAttendantStart());

    try {
      const validateAttendant = async () => {
        const attendant = await doc(attendantsCollection, attendantId);

        await updateDoc(attendant, {
          isVerified: true,
        });
      };

      await validateAttendant()
        .then(() => {
          const updateAttendantSales = async () => {
            const q = await query(
              collection(db, "attendant-sales"),
              where("refId", "==", refId)
            );

            const attendant = await getDocs(q);

            attendant.forEach((d) => {
              console.log(d.data());

              const batch = writeBatch(db);
              const staffRef = doc(db, "attendant-sales", d.id);
              batch.update(staffRef, {
                isVerified: true,
              });
              batch.commit();
            });
          };

          updateAttendantSales().then(() => {
            dispatch(
              validateAttendantSuccess({
                message: "Attendant validated successfully",
              })
            );
          });
        })
        .catch((error) => {
          dispatch(validateAttendantError({ message: error.message }));
        });
      dispatch(
        validateAttendantSuccess({
          message: "Attendant validated successfully",
        })
      );
    } catch (error) {
      dispatch(validateAttendantError({ message: error.message }));
    }
  };
};

// block attendant
export const blockAttendant = (attendantId, refId) => {
  console.log(attendantId, refId);

  return async (dispatch) => {
    dispatch(blockAttendantStart());

    try {
      const blockAttendant = async () => {
        const attendant = await doc(attendantsCollection, attendantId);

        await updateDoc(attendant, {
          isVerified: false,
        });
      };

      await blockAttendant()
        .then(() => {
          const updateAttendantSales = async () => {
            const q = await query(
              collection(db, "attendant-sales"),
              where("refId", "==", refId)
            );

            const attendant = await getDocs(q);

            attendant.forEach((d) => {
              console.log(d.data());

              const batch = writeBatch(db);
              const staffRef = doc(db, "attendant-sales", d.id);
              batch.update(staffRef, {
                isVerified: false,
              });
              batch.commit();
            });
          };

          updateAttendantSales().then(() => {
            dispatch(
              blockAttendantSuccess({
                message: "Attendant blocked successfully",
              })
            );
          });
        })
        .catch((error) => {
          dispatch(blockAttendantError({ message: error.message }));
        });
    } catch (error) {
      dispatch(blockAttendantError({ message: error.message }));
    }
  };
};

// create attendant
export const createAttendant = (
  fullname,
  phone,
  email,
  password,
  role,
  isVerified,
  refId,
  totalSales,
  totalLitreSales
) => {
  return async (dispatch) => {
    dispatch(createAttendantStart());

    return axios({
      method: "POST",
      url: `${CREATE_USER_URL}${APIKEY}`,
      data: {
        email: email,
        password: password,
        returnSecureToken: true,
      },
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        const attendantId = res.data.localId;
        const attendantDetails = {
          fullname: fullname,
          phone: phone,
          email: email,
          role: role,
          isVerified: isVerified,
          attendantId: attendantId,
          refId: refId,
          totalSales: totalSales,
          totalLitreSales: totalLitreSales,
        };

        try {
          const addAttendantDetails = async () => {
            await setDoc(doc(db, "attendant", attendantId), attendantDetails);
          };
          addAttendantDetails();
          dispatch(
            createAttendantSuccess({
              message: `account created successfully`,
            })
          );
        } catch (error) {
          dispatch(createAttendantError({ message: error.message }));
        }
      })
      .catch((err) => {
        console.log(err.message);
        dispatch(createAttendantError({ message: err.message }));
      });
  };
};

//  create admin
export const createAdmin = (email, password, role) => {
  return async (dispatch) => {
    dispatch(createAdminStart());

    return axios({
      method: "POST",
      url: `${CREATE_USER_URL}${APIKEY}`,
      data: {
        email: email,
        password: password,
        returnSecureToken: true,
      },
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        dispatch(createAdminSuccess(res.data));

        dispatch(createDashboardUser(email, password, role));
      })
      .catch((err) => {
        console.log(err.message);
        dispatch(createAdminError({ message: err.message }));
      });
  };
};

// add user to users collection
export const createDashboardUser = (email, role) => {
  return async (dispatch) => {
    try {
      const addUser = async () => {
        const users = await collection(db, "users");

        await addDoc(users, {
          email,
          role,
          dateCreated: new Date(),
        });
      };
      await addUser();
    } catch (error) {
      dispatch(createAdminError({ message: error.message }));
    }
  };
};

// edit attendant details

export const editAttendant = (id, data) => {
  return async (dispatch) => {
    dispatch(editAttendantStart());

    try {
      const updateStaff = async () => {
        const attendant = await doc(db, "attendant", id);

        await updateDoc(attendant, data);
      };
      await updateStaff();
      dispatch(
        editAttendantSuccess({
          message: "attendant details updated successfully",
        })
      );
    } catch (error) {
      dispatch(editAttendantError({ message: error.message }));
    }
  };
};
