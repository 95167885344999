import React from "react";
import { FormControl, Input } from "native-base";

export function CustomInput(props) {
  return (
    <FormControl w={{ base: "90%", md: "80%" }}>
      <FormControl.Label _text={{ color: "black" }}>
        {props.label}
      </FormControl.Label>
      <FormControl.HelperText>
        {props.helperText}
      </FormControl.HelperText>

      <Input {...props} InputRightElement={props.inputRightElement} />
    </FormControl>
  );
}
