import { HStack, Text, Pressable, Menu, Divider } from "native-base";
import React from "react";
import { FiMenu } from "react-icons/fi";
import { useNavigate } from "react-router";

import {
  FiBook,
  FiPlusSquare,
  FiLogOut,
  FiUserCheck,
} from "react-icons/fi";
import { RiDashboardLine } from "react-icons/ri";
import { HiBookOpen, HiUserGroup } from "react-icons/hi";
import { BsTable } from "react-icons/bs";
import { FaCarSide } from "react-icons/fa";

// redux
import { useDispatch } from "react-redux";
import { logoutMiddleware } from "../redux/middleware/authenticationMiddleware";

export default function Navbar() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  return (
    <HStack
      bg="black"
      p={5}
      justifyContent={"space-between"}
      alignItems={"center"}
    >
      <Text color="white">ADMIN PANEL</Text>

      <Menu
        w={"200px"}
        bg="gray.100"
        trigger={(triggerProps) => (
          <Pressable px={1} {...triggerProps}>
            <FiMenu size={25} color="white" />
          </Pressable>
        )}
      >
        <Menu.Item onPress={() => navigate("/")} flexDirection={"row"}>
          <RiDashboardLine style={{ marginRight: 5 }} />
          Dashboard
        </Menu.Item>

        <Divider bg="black" />

        <Menu.Item onPress={() => navigate("/staff")} flexDirection={"row"}>
          <HiUserGroup style={{ marginRight: 5 }} />
          All Staff
        </Menu.Item>
        <Menu.Item
          onPress={() => navigate("/staffGroup")}
          flexDirection={"row"}
        >
          <FiUserCheck style={{ marginRight: 5 }} />
          Staff Groups
        </Menu.Item>
        <Menu.Item onPress={() => navigate("/fleet")} flexDirection={"row"}>
          <FaCarSide style={{ marginRight: 5 }} />
          Fleet
        </Menu.Item>
        <Menu.Item onPress={() => navigate("/add-staff")} flexDirection={"row"}>
          <FiPlusSquare style={{ marginRight: 5 }} />
          Create New Staff
        </Menu.Item>
        <Menu.Item
          onPress={() => navigate("/createstaffgroup")}
          flexDirection={"row"}
        >
          <FiPlusSquare style={{ marginRight: 5 }} />
          Create New Staff Group
        </Menu.Item>
        <Menu.Item onPress={() => navigate("/add-car")} flexDirection={"row"}>
          <FiPlusSquare style={{ marginRight: 5 }} />
          Add New Car
        </Menu.Item>

        <Divider bg="black" />

        <Menu.Item
          onPress={() => navigate("/report-sales")}
          flexDirection={"row"}
        >
          <BsTable style={{ marginRight: 5 }} />
          Sales Report
        </Menu.Item>
        <Menu.Item
          onPress={() => navigate("/report-allocations")}
          flexDirection={"row"}
        >
          <FiBook style={{ marginRight: 5 }} />
          Allocations Report
        </Menu.Item>
        <Menu.Item
          onPress={() => navigate("/sales-details")}
          flexDirection={"row"}
        >
          <FiBook style={{ marginRight: 5 }} />
          Attendant Sales Details
        </Menu.Item>

        <Menu.Item          // 
          onPress={() => navigate("/report-admin-activity-log")}
          flexDirection={"row"}
        >
          <HiBookOpen style={{ marginRight: 5 }} />
          Admin Activity Log
        </Menu.Item>

        <Divider bg="black" />

        <Menu.Item
          onPress={() => dispatch(logoutMiddleware())}
          flexDirection={"row"}
          _text={{ color: "white" }}
          justifyContent={"center"}
          mt={2}
          mx={5}
          rounded="md"
          bg="red.600"
        >
          <FiLogOut color="white" style={{ marginRight: 5 }} />
          Log Out
        </Menu.Item>
      </Menu>
    </HStack>
  );
}
