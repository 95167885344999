import React from "react";
import { useNavigate } from "react-router-dom";

import {
  Center,
  VStack,
  Stack,
  Text,
  HStack,
  useToast,
  Box,
  Pressable,
  Spinner,
} from "native-base";

import "sweetalert2/src/sweetalert2.scss";

import { useDispatch, useSelector } from "react-redux";
import { getAllCars } from "../redux/middleware/staffMiddleware";

function Item({ label, value }) {
  return (
    <HStack
      space="5"
      flexWrap={"wrap"}
      alignItems={{ base: "flex-start", md: "center" }}
      justifyContent={{ md: "space-between" }}
      w="100%"
    >
      <Text flex={5}>{label}</Text>
      <Text>{value}</Text>
    </HStack>
  );
}

export default function Fleet() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toast = useToast();
  const staff = useSelector((state) => state.staff);

  React.useEffect(() => {
    dispatch(getAllCars());
  }, []);

  return (
    <Center py={10}>
      <VStack
        w={{ base: "90%", md: "70%" }}
        shadow="3"
        bg="white"
        rounded="md"
        overflow="hidden"
      >
        <VStack bg="teal.500" p={4}>
          <Text fontSize="2xl" color="white">
            FLEET
          </Text>
        </VStack>
        <Stack p={5} space={5}>
          {staff.isGetAllCarsLoading && <Spinner/>}
          {staff?.allCars?.length > 0 &&
            staff.allCars?.map((cars, index) => (
              <Box p={5} rounded="md" bg="amber.100" w="100%">
                <Item label="Car Model" value={cars.carModel} />
                <Item label="Car Number" value={cars.carNumber} />
                {cars?.dateCreated && (
                  <Item
                    label="Created On"
                    value={new Date(
                      cars.dateCreated.seconds * 1000
                    ).toDateString()}
                  />
                )}

                {cars?.dateEdited && (
                  <Item
                    label="Edited On"
                    value={new Date(
                      cars.dateEdited.seconds * 1000
                    ).toDateString()}
                  />
                )}

                <Center>
                  <Pressable
                    mt={2}
                    px="2"
                    py={1}
                    bg="green.500"
                    rounded="sm"
                    w="20%"
                    alignItems="center"
                    onPress={() => {
                      navigate(`/edit-car/${cars.id}`, {
                        state: cars,
                      });
                    }}
                  >
                    <Text fontSize="xs" color="white">
                      EDIT
                    </Text>
                  </Pressable>
                </Center>
              </Box>
            ))}
        </Stack>
      </VStack>
    </Center>
  );
}
