import React from "react";
import { useNavigate } from "react-router";
import { Center, useToast, HStack, Text, Hidden, Menu } from "native-base";
import DataTable from "react-data-table-component";
import { customStyles } from "../../customStyles";
import { attendantsColumn } from "../../utils/columns";
import { FilterComponent } from "../../utils/export";
import Swal from "sweetalert2";
import { db } from "../../firebase/firebase-config";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { collection, onSnapshot, query, where } from "@firebase/firestore";
import TableMenu from "../../components /TableMenuAttendant";

// redux imports
import { useSelector, useDispatch } from "react-redux";
import {
  getAllAttendants,
  validateAttendant,
  blockAttendant,
} from "../../redux/middleware/attendantMiddleware";
import {
  getAttendantsError,
  validateAttendantError,
  validateAttendantSuccess,
  blockAttendantError,
  blockAttendantSuccess,
  resetActionType,
} from "../../redux/reducers/AttendantReducer";

export default function Atendants() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const toast = useToast();
  const attendantState = useSelector((state) => state.attendants);
  const [attendants, setAttendants] = React.useState([]);

  //   send reset password email
  const resetPassword = (email) => {
    const auth = getAuth();

    Swal.fire({
      title: "Are you sure?",
      text: `You about to send a password reset email to ${email}`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
    }).then((result) => {
      if (result.isConfirmed) {
        sendPasswordResetEmail(auth, email)
          .then(() => {
            // Password reset email sent!
            // ..
            toast.show({
              title: "Success",
              description: "Password reset email sent!",
              status: "success",
              placement: "top",
            });
          })
          .catch((error) => {
            const errorMessage = error.message;
            // ..
            toast.show({
              title: "Error",
              description: errorMessage,
              status: "danger",
              placement: "top",
            });
          });
      }
    });
  };

  // get all attendants without test attendant
  React.useLayoutEffect(() => {
    const q = query(collection(db, "attendant"), where("refId", "!=", "40048"));

    const unsubScribe = onSnapshot(q, (snapshot) => {
      const att = [];
      snapshot.forEach((doc) => {
        att.push(doc.data());
      });

      setAttendants(att);
    });

    return () => {
      unsubScribe();
    };
  }, []);

  //   validate attendant event handler
  const validateAttendantHandler = (id, refId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You are about to validate this attendant to use the mobile app",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, validate attendant!",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(validateAttendant(id, refId));
      }
    });
  };

  //   block attendant event handler
  const blockAttendantHandler = (id, refId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You are about to block this attendant from using the mobile app",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, block attendant!",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(blockAttendant(id, refId));
      }
    });
  };

  //   side effects for the getAttendant, validateAttendant, blockAttendant
  React.useEffect(() => {
    if (attendantState.ACTION_TYPE === getAttendantsError.toString()) {
      toast.show({
        title: attendantState.getAttendantsMessage,
        status: "danger",
        placement: "top",
        duration: 4000,
      });
      dispatch(resetActionType());
      return;
    } else if (
      attendantState.ACTION_TYPE === validateAttendantError.toString()
    ) {
      Swal.fire({
        title: "Error",
        text: attendantState.validateAttendantMessage,
        icon: "error",
        confirmButtonColor: "#D33",
        confirmButtonText: "TRY AGAIN",
      }).then((result) => {
        if (result.isConfirmed) {
          return;
        }
      });
      dispatch(resetActionType());
    } else if (
      attendantState.ACTION_TYPE === validateAttendantSuccess.toString()
    ) {
      Swal.fire({
        title: "Success",
        text: attendantState.validateAttendantMessage,
        icon: "success",
        confirmButtonColor: "#3085d6",
        confirmButtonText: "OK",
      }).then((result) => {
        if (result.isConfirmed) {
          dispatch(getAllAttendants());
          return;
        }
      });
    } else if (attendantState.ACTION_TYPE === blockAttendantError.toString()) {
      Swal.fire({
        title: "Error",
        text: attendantState.blockAttendantMessage,
        icon: "error",
        confirmButtonColor: "#D33",
        confirmButtonText: "TRY AGAIN",
      }).then((result) => {
        if (result.isConfirmed) {
          return;
        }
      });
      dispatch(resetActionType());
    } else if (
      attendantState.ACTION_TYPE === blockAttendantSuccess.toString()
    ) {
      Swal.fire({
        title: "Success",
        text: attendantState.blockAttendantMessage,
        icon: "success",
        confirmButtonColor: "#3085d6",
        confirmButtonText: "OK",
      }).then((result) => {
        if (result.isConfirmed) {
          dispatch(getAllAttendants());
          return;
        }
      });
      dispatch(resetActionType());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attendantState.ACTION_TYPE]);

  // table states
  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);

  // fileter table values
  const filteredItems = attendants.filter(function (item) {
    return (
      (item.fullname &&
        item.fullname.toLowerCase().includes(filterText.toLowerCase())) ||
      (item.email &&
        item.email.toLowerCase().includes(filterText.toLowerCase())) ||
      (item.phone &&
        item.phone.toLowerCase().includes(filterText.toLowerCase())) ||
      (item.refId &&
        item.refId.toLowerCase().includes(filterText.toLowerCase()))
    );
  });

  // subheader for table
  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
      }
    };
    return (
      <HStack justifyContent="space-between" w="100%">
        <FilterComponent
          onFilter={(e) => setFilterText(e.target.value)}
          onClear={handleClear}
          filterText={filterText}
        />
      </HStack>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterText, resetPaginationToggle]);

  return (
    <Center py={20} px={{ base: 2, md: 5 }}>
      <div
        className=" shadow pt-3 col-12  bg-white"
        style={{ borderRadius: 10, overflow: "hidden" }}
      >
        <DataTable
          data={filteredItems}
          columns={[
            {
              name: "Actions",
              selector: (row) => row.title,
              sortable: true,
              cell: (row, index) => (
                <div data-tag="allowRowEvents">
                  <div
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    <TableMenu
                      onChangePassword={() => resetPassword(row.email)}
                      onEdit={() =>
                        navigate(`/edit-attendant/${row.refId}`, {
                          state: filteredItems[index],
                        })
                      }
                      renderBlockElement={
                        row.isVerified ? (
                          <Menu.Item
                            bg="red.500"
                            _hover={{ bg: "red.400" }}
                            onPress={() => {
                              //  find attendant by attendantId and create refId and id variables
                              const refId = filteredItems.find(
                                (attendant) =>
                                  attendant.attendantId === row.attendantId
                              ).refId;

                              const id = filteredItems.find(
                                (item) => item.attendantId === row.attendantId
                              ).attendantId;

                              blockAttendantHandler(id, refId);
                            }}
                          >
                            Block Attendant
                          </Menu.Item>
                        ) : (
                          <Menu.Item
                            bg="green.500"
                            _hover={{ bg: "green.400" }}
                            onPress={() => {
                              //  find attendant by attendantId and create refId and id variables
                              const refId = filteredItems.find(
                                (attendant) =>
                                  attendant.attendantId === row.attendantId
                              ).refId;

                              const id = filteredItems.find(
                                (item) => item.attendantId === row.attendantId
                              ).attendantId;

                              validateAttendantHandler(id, refId);
                            }}
                          >
                            Verify Attendant
                          </Menu.Item>
                        )
                      }
                    />
                  </div>
                </div>
              ),
            },
            ...attendantsColumn,
          ]}
          pagination
          paginationResetDefaultPage={resetPaginationToggle}
          subHeader
          subHeaderComponent={subHeaderComponentMemo}
          progressPending={attendantState.isGetAttendantsLoading}
          responsive
          highlightOnHover
          pointerOnHover
          customStyles={customStyles}
        />
      </div>

      <Hidden from="sm">
        <Text textAlign="center" px={5} mt={10} fontSize="sm">
          Please rotate your phone to landscape mode or increase window size for
          a better experience
        </Text>
      </Hidden>
    </Center>
  );
}
