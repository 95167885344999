import { createSlice } from "@reduxjs/toolkit";

const INITIAL_STATE = {
  ACTION_TYPE: "",
};

const navigationSlice = createSlice({
  name: "navigation",
  initialState: INITIAL_STATE,
  reducers: {
    goToLoading: (state) => {
      state.ACTION_TYPE = goToLoading.toString();
    },

    goToHome: (state) => {
      state.ACTION_TYPE = goToHome.toString();
    },

    goToLogin: (state) => {
      state.ACTION_TYPE = goToLogin.toString();
    },

    goToAdmin: (state) => {
      state.ACTION_TYPE = goToAdmin.toString();
    },
    goToUser: (state) => {
      state.ACTION_TYPE = goToUser.toString();
    },
  },
});

export const { goToLoading, goToHome, goToLogin, goToAdmin, goToUser } =
  navigationSlice.actions;
export default navigationSlice.reducer;
