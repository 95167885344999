import {
  loginStart,
  loginSuccess,
  loginError,
  logoutStart,
  logoutSuccess,
  logoutError,
  setCurrentUser,
} from "../reducers/AuthenticationReducer";

import { goToAdmin, goToUser, goToLogin } from "../reducers/NavigationReducer";

import { clearStaff } from "../reducers/StaffReducer";

import { db, auth } from "../../firebase/firebase-config";

import CryptoJS from "crypto-js";

import {
  collection,
  getDocs,
  query,
  where,
  addDoc,
  setDoc,
  doc,
  updateDoc,
} from "@firebase/firestore";

import { signInWithEmailAndPassword } from "@firebase/auth";
export const appAuthenticationMiddleware = (isLoggedIn) => {
  return async (dispatch) => {
    // dispatch(goToLoading());

    if (isLoggedIn === true) {
      const role = localStorage.getItem("role");

      // console.log('role', role)

      if (role !== null) {
        dispatch(routeToAppropraitePage(role));
        return;
      }
      return;
    }
    dispatch(goToLogin());
  };
};

export const loginMiddleware = (username, password) => {
  return async (dispatch) => {
    dispatch(loginStart());

    try {
      await signInWithEmailAndPassword(auth, username, password)
        .then(() => {
          getUser(username, password).then((user) => {
            if (user) {
              dispatch(loginSuccess());
              localStorage.setItem("role", user.role);
              localStorage.setItem("email", user.email);
              dispatch(routeToAppropraitePage(user.role));
            } else {
              dispatch(loginError({ message: "Invalid username or password" }));
            }
          });
        })
        .catch((error) => {
          dispatch(
            loginError({
              message: error.message,
            })
          );
        });
    } catch (error) {
      dispatch(loginError({ message: error.message }));
    }
  };
};

// get users (admins)
// check to see if email exist in attendant collection and return true or false
const getUser = (email, password) => {
  

  try {
    const q = query(
      collection(db, "users"),
      where("email", "==", email)
    );
    const getQuerySnapshot = async () => {
      const querySnapshot = await getDocs(q);

      const users = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));

      return users[0];
    };
    return getQuerySnapshot();
  } catch (error) {
    console.log(error.message);
  }
};

export const routeToAppropraitePage = (role) => {
  return function (dispatch) {
    switch (role) {
      case "admin":
        dispatch(goToAdmin());
        break;

      case "user":
        dispatch(goToUser());
        break;

      default:
        dispatch(goToLogin());
        break;
    }
  };
};

// stores role in local storage
export const storeRole = (role) => {
  return function (dispatch) {
    localStorage.setItem("role", role);
  };
};

export const logoutMiddleware = () => {
  return async (dispatch) => {
    dispatch(logoutStart());

    try {
      localStorage.removeItem("role");
      localStorage.removeItem("email");
      dispatch(clearStaff());
      dispatch(logoutSuccess());
      dispatch(goToLogin());
    } catch (error) {
      dispatch(logoutError({ message: error }));
    }
  };
};

// write current activity to firebase activity log collection
export const writeActivity = (id, activity) => {
  return async (dispatch) => {
    try {
      // write a subcollection of the activity log collection with activity

      const activityLog = collection(db, "activity-log");
      const activityLogDoc = doc(activityLog, id);
      const activityLogSubcollection = collection(activityLogDoc, "activity");

      await addDoc(activityLogSubcollection, activity);
    } catch (error) {
      console.log(error.message);
    }
  };
};
