import { combineReducers } from "redux";

import NavigationReducer from "./NavigationReducer";
import AuthenticationReducer from "./AuthenticationReducer";
import staffReducer from "./StaffReducer";
import AttendantReducer from "./AttendantReducer";


export default combineReducers({
  navigation: NavigationReducer,
  authentication: AuthenticationReducer,
  staff: staffReducer,
  attendants: AttendantReducer,

});
