import { createSlice } from "@reduxjs/toolkit";

const INITIAL_STATE = {
  isCreateStaffLoading: false,
  isUpdateStaffLoading: false,
  isGetStaffLoading: false,
  isAddStaffDetailLoading: false,
  isUpdateSpendingLimitLoading: false,
  isResetAmountSpentLoading: false,
  isGetStaffExpenseLoading: false,
  isBlockStaffLoading: false,
  isUnblockStaffLoading: false,
  isCreateStaffGroupLoading: false,
  isUpdateStaffGroupLoading: false,
  isAddCarLoading: false,
  isEditCarLoading: false,
  isGetAllCarsLoading: false,
  isDeleteUserLoading: false,

  editCarMessage: "",
  addCarMessage: "",
  getAllCarsMessage: "",
  createStaffMessage: "",
  createGroupMessage: "",
  updateStaffGroupMessage: "",
  updateStaffMessage: "",
  getStaffMessage: "",
  addStaffDetailsMessage: "",
  updateSpendingLimitMessage: "",
  resetSpentAmountMessage: "",
  getStaffExpenseMessage: "",
  blockStaffMessage: "",
  unblockStaffMessage: "",
  deleteUserMessage: "",

  allStaff: [],
  allStaffGroups: [],
  allCars: [],
  createdStaff: {},
  updatedStaffDetails: {},
  staffExpense: [],
};

const staffSlice = createSlice({
  name: "staff",
  initialState: INITIAL_STATE,
  reducers: {
    createStaffStart: (state) => {
      state.ACTION_TYPE = createStaffStart.toString();
      state.isCreateStaffLoading = true;
      state.createStaffMessage = "";
    },

    createStaffSuccess: (state, action) => {
      state.ACTION_TYPE = createStaffSuccess.toString();
      state.isCreateStaffLoading = false;
      state.createStaffMessage = action.payload.message;
      state.createdStaff = action.payload;
    },

    createStaffError: (state, action) => {
      state.ACTION_TYPE = createStaffError.toString();
      state.isCreateStaffLoading = false;
      state.createStaffMessage = action.payload.message;
      state.createdStaff = {};
    },

    updateStaffStart: (state) => {
      state.ACTION_TYPE = updateStaffStart.toString();
      state.isUpdateStaffLoading = true;
      state.updateStaffMessage = "";
    },

    updateStaffSuccess: (state, action) => {
      state.ACTION_TYPE = updateStaffSuccess.toString();
      state.isUpdateStaffLoading = false;
      state.updateStaffMessage = action.payload.message;
    },

    updateStaffError: (state, action) => {
      state.ACTION_TYPE = updateStaffError.toString();
      state.isUpdateStaffLoading = false;
      state.updateStaffMessage = action.payload.message;
    },

    getStaffStart: (state) => {
      state.ACTION_TYPE = getStaffStart.toString();
      state.isGetStaffLoading = true;
      state.getStaffMessage = "";
    },

    getStaffSuccess: (state, action) => {
      state.ACTION_TYPE = getStaffSuccess.toString();
      state.isGetStaffLoading = false;
      state.getStaffMessage = action.payload.message;
      state.allStaff = action.payload;
    },

    getStaffError: (state, action) => {
      state.ACTION_TYPE = getStaffError.toString();
      state.isGetStaffLoading = false;
      state.getStaffMessage = action.payload.message;
      state.allStaff = [];
    },

    addStaffDetailStart: (state) => {
      state.ACTION_TYPE = addStaffDetailStart.toString();
      state.isAddStaffDetailLoading = true;
      state.addStaffDetailsMessage = "";
    },

    addStaffDetailSuccess: (state, action) => {
      state.ACTION_TYPE = addStaffDetailSuccess.toString();
      state.isAddStaffDetailLoading = false;
      state.addStaffDetailsMessage = action.payload.message;
    },

    addStaffDetailError: (state, action) => {
      state.ACTION_TYPE = addStaffDetailError.toString();
      state.isAddStaffDetailLoading = false;
      state.addStaffDetailsMessage = action.payload.message;
    },

    clearStaff: (state) => {
      state.ACTION_TYPE = clearStaff.toString();
      state.allStaff = [];
      state.staff = {};
      state.createdStaff = {};
      state.updatedStaffDetails = {};
    },

    updateSpendingLimitStart: (state) => {
      state.ACTION_TYPE = updateSpendingLimitStart.toString();
      state.isUpdateSpendingLimitLoading = true;
      state.updateSpendingLimitMessage = "";
    },

    updateSpendingLimitSuccess: (state, action) => {
      state.ACTION_TYPE = updateSpendingLimitSuccess.toString();
      state.isUpdateSpendingLimitLoading = false;
      state.updateSpendingLimitMessage = action.payload.message;
    },

    updateSpendingLimitError: (state, action) => {
      state.ACTION_TYPE = updateSpendingLimitError.toString();
      state.isUpdateSpendingLimitLoading = false;
      state.updateSpendingLimitMessage = action.payload.message;
    },

    resetSpentAmountStart: (state) => {
      state.ACTION_TYPE = resetSpentAmountStart.toString();
      state.isResetAmountSpentLoading = true;
      state.resetSpentAmountMessage = "";
    },

    resetSpentAmountSuccess: (state, action) => {
      state.ACTION_TYPE = resetSpentAmountSuccess.toString();
      state.isResetAmountSpentLoading = false;
      state.resetSpentAmountMessage = action.payload.message;
    },

    resetSpentAmountError: (state, action) => {
      state.ACTION_TYPE = resetSpentAmountError.toString();
      state.isResetAmountSpentLoading = false;
      state.resetSpentAmountMessage = action.payload.message;
    },

    getStaffExpenseStart: (state) => {
      state.ACTION_TYPE = getStaffExpenseStart.toString();
      state.isGetStaffExpenseLoading = true;
      state.getStaffExpenseMessage = "";
    },

    getStaffExpenseSuccess: (state, action) => {
      state.ACTION_TYPE = getStaffExpenseSuccess.toString();
      state.isGetStaffExpenseLoading = false;
      state.getStaffExpenseMessage = action.payload.message;
      state.staffExpense = action.payload;
    },

    getStaffExpenseError: (state, action) => {
      state.ACTION_TYPE = getStaffExpenseError.toString();
      state.isGetStaffExpenseLoading = false;
      state.getStaffExpenseMessage = action.payload.message;
      state.staffExpense = [];
    },

    blockStaffStart: (state) => {
      state.ACTION_TYPE = blockStaffStart.toString();
      state.isBlockStaffLoading = true;
      state.blockStaffMessage = "";
    },

    blockStaffSuccess: (state, action) => {
      state.ACTION_TYPE = blockStaffSuccess.toString();
      state.isBlockStaffLoading = false;
      state.blockStaffMessage = action.payload.message;
    },

    blockStaffError: (state, action) => {
      state.ACTION_TYPE = blockStaffError.toString();
      state.isBlockStaffLoading = false;
      state.blockStaffMessage = action.payload.message;
    },

    unblockStaffStart: (state) => {
      state.ACTION_TYPE = unblockStaffStart.toString();
      state.isUnblockStaffLoading = true;
      state.unblockStaffMessage = "";
    },

    unblockStaffSuccess: (state, action) => {
      state.ACTION_TYPE = unblockStaffSuccess.toString();
      state.isUnblockStaffLoading = false;
      state.unblockStaffMessage = action.payload.message;
    },

    unblockStaffError: (state, action) => {
      state.ACTION_TYPE = unblockStaffError.toString();
      state.isUnblockStaffLoading = false;
      state.unblockStaffMessage = action.payload.message;
    },

    createStaffGroupStart: (state) => {
      state.ACTION_TYPE = createStaffGroupStart.toString();
      state.isCreateStaffGroupLoading = true;
      state.createStaffGroupMessage = "";
    },

    createStaffGroupSuccess: (state, action) => {
      state.ACTION_TYPE = createStaffGroupSuccess.toString();
      state.isCreateStaffGroupLoading = false;
      state.createStaffGroupMessage = action.payload.message;
    },

    createStaffGroupError: (state, action) => {
      state.ACTION_TYPE = createStaffGroupError.toString();
      state.isCreateStaffGroupLoading = false;
      state.createStaffGroupMessage = action.payload.message;
    },

    getStaffGroup: (state, action) => {
      state.ACTION_TYPE = getStaffGroup.toString();
      state.allStaffGroups = action.payload;
    },

    updateStaffGroupStart: (state) => {
      state.ACTION_TYPE = updateStaffGroupStart.toString();
      state.isUpdateStaffGroupLoading = true;
      state.updateStaffGroupMessage = "";
    },

    updateStaffGroupSuccess: (state, action) => {
      state.ACTION_TYPE = updateStaffGroupSuccess.toString();
      state.isUpdateStaffGroupLoading = false;
      state.updateStaffGroupMessage = action.payload.message;
    },

    updateStaffGroupError: (state, action) => {
      state.ACTION_TYPE = updateStaffGroupError.toString();
      state.isUpdateStaffGroupLoading = false;
      state.updateStaffGroupMessage = action.payload.message;
    },

    addCarStart: (state) => {
      state.ACTION_TYPE = addCarStart.toString();
      state.isAddCarLoading = true;
      state.addCarMessage = "";
    },

    addCarSuccess: (state, action) => {
      state.ACTION_TYPE = addCarSuccess.toString();
      state.isAddCarLoading = false;
      state.addCarMessage = action.payload.message;
    },

    addCarError: (state, action) => {
      state.ACTION_TYPE = addCarError.toString();
      state.isAddCarLoading = false;
      state.addCarMessage = action.payload.message;
    },

    editCarStart: (state) => {
      state.ACTION_TYPE = editCarStart.toString();
      state.isEditCarLoading = true;
      state.editCarMessage = "";
    },

    editCarSuccess: (state, action) => {
      state.ACTION_TYPE = editCarSuccess.toString();
      state.isEditCarLoading = false;
      state.editCarMessage = action.payload.message;
      state.allCars = action.payload;
    },

    editCarError: (state, action) => {
      state.ACTION_TYPE = editCarError.toString();
      state.isEditCarLoading = false;
      state.editCarMessage = action.payload.message;
      state.allCars = [];
    },

    getAllCarsStart: (state) => {
      state.ACTION_TYPE = getAllCarsStart.toString();
      state.isGetAllCarsLoading = true;
      state.getAllCarsMessage = "";
    },

    getAllCarsSuccess: (state, action) => {
      state.ACTION_TYPE = getAllCarsSuccess.toString();
      state.isGetAllCarsLoading = false;
      state.getAllCarsMessage = action.payload.message;
      state.allCars = action.payload;
    },

    getAllCarsError: (state, action) => {
      state.ACTION_TYPE = getAllCarsError.toString();
      state.isGetAllCarsLoading = false;
      state.getAllCarsMessage = action.payload.message;
      state.allCars = [];
    },

    deleteUserStart: (state) => {
      state.ACTION_TYPE = deleteUserStart.toString();
      state.isDeleteUserLoading = true;
      state.deleteUserMessage = "";
    },

    deleteUserSuccess: (state, action) => {
      state.ACTION_TYPE = deleteUserSuccess.toString();
      state.isDeleteUserLoading = false;
      state.deleteUserMessage = action.payload.message;
    },

    deleteUserError: (state, action) => {
      state.ACTION_TYPE = deleteUserError.toString();
      state.isDeleteUserLoading = false;
      state.deleteUserMessage = action.payload.message;
    },

    resetActionType: (state) => {
      state.ACTION_TYPE = resetActionType.toString();
    },
  },
});

export const {
  createStaffStart,
  createStaffSuccess,
  createStaffError,
  updateStaffStart,
  updateStaffSuccess,
  updateStaffError,
  getStaffStart,
  getStaffSuccess,
  getStaffError,
  addStaffDetailStart,
  addStaffDetailSuccess,
  addStaffDetailError,
  updateSpendingLimitStart,
  updateSpendingLimitSuccess,
  updateSpendingLimitError,
  resetSpentAmountStart,
  resetSpentAmountSuccess,
  resetSpentAmountError,
  getStaffExpenseStart,
  getStaffExpenseSuccess,
  getStaffExpenseError,
  blockStaffStart,
  blockStaffSuccess,
  blockStaffError,
  unblockStaffStart,
  unblockStaffSuccess,
  unblockStaffError,
  clearStaff,
  resetActionType,
  createStaffGroupStart,
  createStaffGroupSuccess,
  createStaffGroupError,
  updateStaffGroupError,
  updateStaffGroupStart,
  updateStaffGroupSuccess,
  getStaffGroup,
  addCarStart,
  addCarSuccess,
  addCarError,
  editCarStart,
  editCarSuccess,
  editCarError,
  getAllCarsStart,
  getAllCarsSuccess,
  getAllCarsError,
  deleteUserStart,
  deleteUserSuccess,
  deleteUserError,
} = staffSlice.actions;
export default staffSlice.reducer;
