// function that returns yesterdays date
export const yesterday = () => {
  let date = new Date();
  date.setDate(date.getDate() - 1);
  return date;
};

// function that returns first day of last week
export const lastWeekStart = () => {
  let date = new Date();
  date.setDate(date.getDate() - 7);
  return date;
};

// function that returns last day of last week
export const lastWeekEnd = () => {
  let date = new Date();
  date.setDate(date.getDate() - 1);
  return date;
};

// function that returns the last day of last month
export const lastMonthStart = () => {
  let date = new Date();
  date.setDate(1);
  date.setMonth(date.getMonth() - 1);
  return date;
};

// function that returns the first day of last month
export const lastMonthEnd = () => {
  let date = new Date();
  return new Date(date.getFullYear(), date.getMonth() , 0);
};

// function that returns first day of this month
export const thisMonthStart = () => {
  let date = new Date();
  date.setDate(1);
  return date;
};

// function that returns last day of this month
export const thisMonthEnd = () => {
  let date = new Date();
  return new Date(date.getFullYear(), date.getMonth() + 1, 0);
};

// function that returns first day of last year
export const lastYearStart = () => {
  let date = new Date();
  date.setDate(1);
  date.setMonth(0);
  date.setFullYear(date.getFullYear() - 1);
  return date;
};

// function that returns last day of last year
export const lastYearEnd = () => {
  return new Date(new Date().getFullYear() - 1, 11, 31);
};

// function that returns first day of this year
export const thisYearStart = () => {
  let date = new Date();
  date.setDate(1);
  date.setMonth(0);
  return date;
};

// function that returns last day of this year
export const thisYearEnd = () => {
  return new Date(new Date().getFullYear(), 11, 31);
};

// ! convert to date
export const convertDate = (value) => {
  try {
    return (
      value.toDate().toString().split(" ")[1] +
      " " +
      value.toDate().toString().split(" ")[2] +
      " " +
      value.toDate().toString().split(" ")[3] +
      " " +
      value.toDate().toString().split(" ")[4]
    );
  } catch (error) {
    console.log(error);
    return "";
  }
};
