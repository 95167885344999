/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

import {
  Center,
  VStack,
  Stack,
  Text,
  Button,
  useToast,
  Checkbox,
  HStack,
} from "native-base";
import { CustomInput as Input } from "../components /CustomInput";

import { useDispatch, useSelector } from "react-redux";
import { createStaff } from "../redux/middleware/staffMiddleware";
import {
  resetActionType,
  createStaffError,
  createStaffSuccess,
} from "../redux/reducers/StaffReducer";

import Swal from "sweetalert2";
import "sweetalert2/src/sweetalert2.scss";

export default function CreateStaff() {
  const dispatch = useDispatch();
  const staff = useSelector((state) => state.staff);
  const authState = useSelector((state) => state.authentication);
  const navigate = useNavigate();
  const toast = useToast();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);
  const [canUseApp, setCanUseApp] = useState(false);
  const role = "admin";

  useEffect(() => {
    if (staff.ACTION_TYPE === createStaffSuccess.toString()) {
      if (isAdmin === true && canUseApp === false) {
        Swal.fire({
          title: "Success",
          text: "Admin Created Successfully",
          icon: "success",
          confirmButtonText: "Done",
        }).then(() => {
          dispatch(resetActionType());
          navigate("/");
        });
      } else {
        Swal.fire({
          title: "Success",
          text: "Staff Created Successfully",
          icon: "success",
          confirmButtonText: "ADD DETAILS",
        }).then(() => {
          dispatch(resetActionType());

          navigate(`/add-staff-details/${staff.createdStaff.localId}`, {
            state: staff.createdStaff,
          });
        });
      }
    }
    if (staff.ACTION_TYPE === createStaffError.toString()) {
      Swal.fire({
        title: "Error",
        text: staff.createStaffMessage,
        icon: "error",
        confirmButtonText: "OK",
      }).then(() => {
        dispatch(resetActionType());
      });
    }
  }, [staff.ACTION_TYPE]);

  console.log(canUseApp);

  const onCreateStaff = () => {
    if (!email || !password || !confirmPassword) {
      toast.show({
        title: "Field cannot be empty!",
        status: "danger",
        duration: 9000,
        placement: "top",
      });
      return;
    } else if (
      // eslint-disable-next-line no-useless-escape
      email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/) === null
    ) {
      toast.show({
        title: "Please input valid email!",
        status: "danger",
        duration: 9000,
        placement: "top",
      });
      return;
    } else if (password !== confirmPassword) {
      toast.show({
        title: "Password does not match!",
        status: "danger",
        duration: 9000,
        placement: "top",
      });
      return;
    } else if (password.length < 6) {
      toast.show({
        title: "Password must be at least 6 characters!",
        status: "danger",
        duration: 9000,
        placement: "top",
      });
      return;
    }

    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "YES, CREATE IT!",
      cancelButtonText: "CANCEL",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(
          createStaff(
            email,
            password,
            isAdmin,
            role,
            authState.currentUser.id,
            canUseApp,
          )
        );
      }
    });
  };

  return (
    <Center py={10}>
      <VStack
        w={{ base: "90%", md: "70%" }}
        shadow="3"
        bg="white"
        rounded="md"
        overflow="hidden"
      >
        <Center bg="teal.500" p={4}>
          <Text fontSize="2xl" color="white">
            CREATE STAFF
          </Text>
        </Center>

        <Stack py={10} alignItems="center" space={5}>
          <Input label="Staff Email" value={email} onChangeText={setEmail} />

          <Input
            label="Password"
            helperText="Password must be at least 6 characters"
            value={password}
            onChangeText={setPassword}
            type="password"
          />
          <Input
            label="Confirm Password"
            value={confirmPassword}
            onChangeText={setConfirmPassword}
            type="password"
          />

          <HStack w={{ base: "90%", md: "80%" }} space={4}>
            <Checkbox value={isAdmin} onChange={() => setIsAdmin(!isAdmin)} />
            <Text>Create staff as admin dashboard user</Text>
          </HStack>

          {isAdmin && (
            <HStack w={{ base: "90%", md: "80%" }} space={4}>
              <Checkbox
                value={canUseApp}
                onChange={() => setCanUseApp(!canUseApp)}
              />
              <Text>Can also use mobile application</Text>
            </HStack>
          )}

          <Button
            colorScheme="emerald"
            onPress={onCreateStaff}
            // isLoading={staff.isCreateStaffLoading}
            _loading={{ bg: "emerald.500" }}
            isLoadingText="Creating Staff..."
          >
            CREATE STAFF
          </Button>
        </Stack>
      </VStack>
    </Center>
  );
}
