import React from "react";

import { useNavigate, useLocation } from "react-router-dom";

import { Center, VStack, Stack, Text, Button, useToast } from "native-base";
import { CustomInput as Input } from "../components /CustomInput";

import Swal from "sweetalert2";

// redux

import { useDispatch, useSelector } from "react-redux";
import { editCar } from "../redux/middleware/staffMiddleware";
import {
  editCarError,
  editCarSuccess,
  resetActionType,
} from "../redux/reducers/StaffReducer";

export default function EditCar() {
  const navigate = useNavigate();
  const location = useLocation();
  const toast = useToast();
  const dispatch = useDispatch();
  const staffState = useSelector((state) => state.staff);
  const authState = useSelector((state) => state.authentication);

  const [carModel, setCarModel] = React.useState(location.state.carModel);
  const [carNumber, setCarNumber] = React.useState(location.state.carNumber);

  const onEditCar = () => {
    if (carModel === "" || carNumber === "") {
      toast.show({
        description: "Please fill all fields",
        status: "danger",
        placement: "top",
        duration: 3000,
      });
      return;
    }

    const data = {
      carModel,
      carNumber,
      dateEdited: new Date(),
    };

    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",

      cancelButtonColor: "#d33",
    }).then((result) => {
      if (result.value) {
        dispatch(
          editCar(
            location.state.id,
            data,
            location.state.carNumber,
            authState.currentUser.id
          )
        );
      }
    });
  };

  React.useEffect(() => {
    if (staffState.ACTION_TYPE === editCarSuccess.toString()) {
      Swal.fire({
        title: "Success",
        text: staffState.editCarMessage,
        icon: "success",
        confirmButtonText: "OK",
      }).then(() => {
        dispatch(resetActionType());
        navigate("/fleet");
      });
    } else if (staffState.ACTION_TYPE === editCarError.toString()) {
      Swal.fire({
        title: "Error",
        text: staffState.editCarMessage,
        icon: "error",
        confirmButtonText: "OK",
      }).then(() => {
        dispatch(resetActionType());
      });
    }
  }, [staffState.ACTION_TYPE]);

  return (
    <Center py={10}>
      <VStack
        w={{ base: "90%", md: "70%" }}
        shadow="3"
        bg="white"
        rounded="md"
        overflow="hidden"
      >
        <Center bg="teal.500" p={4}>
          <Text fontSize="2xl" color="white">
            EDIT CAR
          </Text>
        </Center>

        <Stack py={10} alignItems="center" space={5}>
          <Input
            label="Car Model"
            value={carModel}
            onChangeText={setCarModel}
          />

          <Input
            label="Car Number"
            value={carNumber}
            onChangeText={setCarNumber}
          />

          <Button
            colorScheme="emerald"
            onPress={onEditCar}
            // isLoading={staffState.isEditCarLoading}
            _loading={{ bg: "emerald.500" }}
            isLoadingText="Editting Car..."
          >
            EDIT CAR
          </Button>
        </Stack>
      </VStack>
    </Center>
  );
}
