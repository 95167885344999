import React from "react";
import { Menu, IconButton } from "native-base";
import { FiMoreVertical } from "react-icons/fi";

export default function TableMenu({ onChangePassword, onEdit, renderBlockElement }) {
  return (
    <Menu
      w="190"
      trigger={(triggerProps) => {
        return (
          <IconButton
            colorScheme="gray"
            accessibilityLabel="More options menu"
            rounded="full"
            {...triggerProps}
            p={2}
            icon={<FiMoreVertical size={20} />}
          />
        );
      }}
    >
      <Menu.Item onPress={onChangePassword}>Change Password</Menu.Item>
      <Menu.Item onPress={onEdit}>Edit Attendant</Menu.Item>
      {renderBlockElement}
    </Menu>
  );
}
