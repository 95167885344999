import React from "react";
import { Menu, IconButton } from "native-base";
import { FiMoreVertical } from "react-icons/fi";

export default function TableMenu({
  onView,
  viewText,
  editText,
  onEdit,
  onExpense,
  expenseText,
  onResetPassword,
  renderDelete,
}) {
  return (
    <Menu
      w="190"
      trigger={(triggerProps) => {
        return (
          <IconButton
            colorScheme="gray"
            accessibilityLabel="More options menu"
            rounded="full"
            {...triggerProps}
            p={2}
            icon={<FiMoreVertical size={20} />}
          />
        );
      }}
    >
      <Menu.Item onPress={onView}>{viewText}</Menu.Item>
      <Menu.Item onPress={onEdit}>{editText}</Menu.Item>
      <Menu.Item onPress={onResetPassword}>RESET PASSWORD</Menu.Item>
      <Menu.Item onPress={onExpense}>{expenseText}</Menu.Item>
      {renderDelete}
    </Menu>
  );
}
