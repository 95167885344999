import {
  Center,
  FlatList,
  Text,
  Box,
  HStack,
  Heading,
  Pressable,
  Spinner,
} from "native-base";
import React from "react";
import { auth, db } from "../../firebase/firebase-config";
import {
  collection,
  getDocs,
  onSnapshot,
  query,
  doc,
  where,
  collectionGroup,
} from "@firebase/firestore";
import TimeAgo from "react-timeago";

import { useSelector } from "react-redux";

export default function AdminActivityLog() {
  const authState = useSelector((state) => state.authentication);

  const [activity, setActivity] = React.useState([]);
  const [admins, setAdmins] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);

  const [adminEmail, setAdminEmail] = React.useState(
    authState?.currentUser?.email
  );
  const [adminId, setAdminId] = React.useState(authState?.currentUser?.id);

  // get all admins
  React.useLayoutEffect(() => {
    const q = query(collection(db, "users"), where("role", "==", "admin"));

    const unsubScribe = onSnapshot(q, (snapshot) => {
      const data = [];
      snapshot.forEach((doc) => {
        data.push({
          id: doc.id,
          email: doc.data().email,
        });
      });

      setAdmins(data);
    });

    return () => {
      unsubScribe();
    };
  }, []);

  console.log(admins);

  // get admin data
  React.useEffect(() => {
    const docRef = doc(db, "activity-log", adminId);

    const q = query(collection(docRef, "activity"));

    const unsubScribe = onSnapshot(q, (snapshot) => {
      setIsLoading(true);
      const data = [];
      snapshot.forEach((doc) => {
        data.push(doc.data());
      });

      setActivity(
        data.sort(
          (a, b) =>
            new Date(b.date * 1000).getTime() -
            new Date(a.date * 1000).getTime()
        )
      );
      setIsLoading(false);
    });

    return () => {
      unsubScribe();
    };
  }, [adminId]);

  return (
    <Center py={10}>
      <Box w="100%" px={{ base: 5, md: 10 }}>
        <Heading mb={5}>Admin Activity Log</Heading>

        {authState?.currentUser?.email === "donewelllifeghana@gmail.com" && (
          <FlatList
            data={admins}
            mb={5}
            horizontal
            keyExtractor={(item) => item.id}
            renderItem={({ item, index }) => (
              <Pressable
                mr={4}
                px={5}
                py={2}
                bg={item.email === adminEmail ? "blue.500" : "blue.200"}
                rounded="sm"
                onPress={() => {
                  setAdminEmail(item.email);
                  setAdminId(item.id);
                }}
              >
                <Text color={"gray.100"}>{item.email}</Text>
              </Pressable>
            )}
          />
        )}

        <>
          {isLoading ? (
            <Center my={50}>
              <Spinner />
            </Center>
          ) : (
            <>
              <Text fontSize="lg" mb={5}>
                Admin: {adminEmail}
              </Text>
              <FlatList
                data={activity}
                mb={5}
                keyExtractor={(item, index) => index.toString()}
                ListEmptyComponent={() => (
                  <Center my={50}>
                    <Text fontSize="lg" mb={5}>
                      No Activity Logs to show
                    </Text>
                  </Center>
                )}
                renderItem={({ item }) => (
                  <HStack p={5} mb={5} rounded="sm" bg="gray.200">
                    <Box flex={8}>
                      <Text>{item.activity}</Text>
                    </Box>
                    <Box flex={2}>
                      <Text>
                        <TimeAgo
                          live={true}
                          date={new Date(item.date.seconds * 1000)}
                          style={{ fontStyle: "Questrial", fontSize: ".8rem" }}
                        />
                      </Text>
                    </Box>
                  </HStack>
                )}
              />
            </>
          )}
        </>
      </Box>
    </Center>
  );
}
