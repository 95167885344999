import React from "react";
import { useNavigate } from "react-router";
import {
  Box,
  Center,
  Button,
  Stack,
  HStack,
  Circle,
  Heading,
  Text,
  Pressable,
  Menu,
  Spinner,
  Badge,
} from "native-base";
import { GoPerson } from "react-icons/go";
import { FaRegMoneyBillAlt } from "react-icons/fa";
import { FiChevronDown } from "react-icons/fi";
import { BiGasPump } from "react-icons/bi";
import {
  ResponsiveContainer,
  CartesianGrid,
  AreaChart,
  Area,
  XAxis,
  YAxis,
  Tooltip,
} from "recharts";
import { db } from "../../firebase/firebase-config";
import { collection, onSnapshot, query, where } from "@firebase/firestore";
import { ShakeLittle } from "reshake";

import { useDispatch } from "react-redux";
import { setCurrentUser } from "../../redux/reducers/AuthenticationReducer";

// date filters
import {
  getFirstDayOfMonth,
  getLastDayOfMonth,
  getFirstDayOfWeek,
  getLastDayOfWeek,
  getFirstDayOfYear,
  getLastDayOfYear,
} from "../../utils/dashboardDateFilters";

// ! convert to date
const convertDate = (value) => {
  try {
    return (
      value.toDate().toString().split(" ")[1] +
      " " +
      value.toDate().toString().split(" ")[2] +
      " " +
      value.toDate().toString().split(" ")[3]
    );
  } catch (error) {
    console.log(error);
    return "";
  }
};

const COLORS = [
  "#0088FE",
  "#005cc4",
  "#09466e",
  "#3b77bb",
  "#00c6ff",
  "#0088FE",
  "#005cc4",
  "#09466e",
  "#3b77bb",
  "#00c6ff",
];

export default function Dashboard() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // loading
  const [isLoading, setIsLoading] = React.useState(false);

  // dashboard states
  const [attendantSales, setAttendantSales] = React.useState([]);
  const [newAttendants, setNewAttendants] = React.useState([]);
  const [activeAttendantSales, setActiveAttendantSales] = React.useState([]);
  const [inactiveAttendantSales, setInactiveAttendantSales] = React.useState(
    []
  );
  // const [inactiveAttendants, setInactiveAttendants] = React.useState([]);

  const [allAttendantSales, setAllAttendantSales] = React.useState([]);

  // selection state
  const [filteredData, setFilteredData] = React.useState([]);
  const [selectedFilter, setSelectedFilter] = React.useState("This Month");

  // sales chart states
  const [staffType, setStaffType] = React.useState("all");

  const [salesData, setSalesData] = React.useState([]);

  console.log(salesData);

  // set current loggedin user from users snapshot
  React.useLayoutEffect(() => {
    setIsLoading(true);
    const q = query(
      collection(db, "users"),
      where("email", "==", localStorage.getItem("email"))
    );

    const unsubScribe = onSnapshot(q, (snapshot) => {
      const currentUser = [];
      snapshot.forEach((doc) => {
        currentUser.push({
          id: doc.id,
          email: doc.data().email,
          role: doc.data().role,
        });
      });

      dispatch(setCurrentUser(currentUser[0]));
    });

    return () => {
      unsubScribe();
    };
  }, []);

  //  listen to snapshop of attendants (active)
  React.useLayoutEffect(() => {
    setIsLoading(true);
    const q = query(
      collection(db, "attendant"),
      where("isVerified", "==", true),
      where("refId", "!=", "40048")
    );

    const unsubScribe = onSnapshot(q, (snapshot) => {
      const attendantList = [];
      snapshot.forEach((doc) => {
        const name = doc.data().fullname;
        const value = parseFloat(doc.data().totalSales);
        const litres = parseFloat(doc.data().totalLitreSales);

        attendantList.push({ name: name, value: value, litres: litres });
      });

      setAttendantSales(attendantList);

      setIsLoading(false);
    });

    return () => {
      unsubScribe();
    };
  }, []);

  // listen to snapshot of new attendants that are not verified
  React.useLayoutEffect(() => {
    const q = query(
      collection(db, "attendant"),
      where("isVerified", "==", false),
      where("refId", "!=", "40048")
    );

    const unsubScribe = onSnapshot(q, (snapshot) => {
      const attendantList = [];
      snapshot.forEach((doc) => {
        const data = doc.data();

        attendantList.push(data);
      });
      setNewAttendants(attendantList);
    });

    return () => {
      unsubScribe();
    };
  }, []);

  // listen to snapshot for attendant sales data active
  React.useLayoutEffect(() => {
    const q = query(
      collection(db, "attendant-sales"),
      where("pinConfirmed", "==", true),
      where("isVerified", "==", false),
      where("refId", "!=", "40048")
    );

    const unsubScribe = onSnapshot(q, (snapshot) => {
      const data = snapshot.docs.map((doc) => ({
        name: doc.data().attendantName,
        id: doc.data().attendantId,
        value: parseFloat(doc.data().amountSpent),
        day: convertDate(doc.data().validationTime),
        litres: parseFloat(doc.data().litres),
      }));

      const res = Array.from(
        data
          .reduce((acc, { value, ...r }) => {
            const key = JSON.stringify(r);
            const current = acc.get(key) || { ...r, value: 0 };
            return acc.set(key, {
              ...current,
              value: parseFloat(current.value) + parseFloat(value),
            });
          }, new Map())
          .values()
      );

      setInactiveAttendantSales(res);
    });

    return () => {
      unsubScribe();
    };
  }, []);

  // listen to snapshot for attendant sales data inactive
  React.useLayoutEffect(() => {
    const q = query(
      collection(db, "attendant-sales"),
      where("pinConfirmed", "==", true),
      where("isVerified", "==", true),
      where("refId", "!=", "40048")
    );

    const unsubScribe = onSnapshot(q, (snapshot) => {
      const data = snapshot.docs.map((doc) => ({
        name: doc.data().attendantName,
        id: doc.data().attendantId,
        value: parseFloat(doc.data().amountSpent),
        day: convertDate(doc.data().validationTime),
        litres: parseFloat(doc.data().litres),
      }));

      const res = Array.from(
        data
          .reduce((acc, { value, ...r }) => {
            const key = JSON.stringify(r);
            const current = acc.get(key) || { ...r, value: 0 };
            return acc.set(key, {
              ...current,
              value: parseFloat(current.value) + parseFloat(value),
            });
          }, new Map())
          .values()
      );

      setActiveAttendantSales(res);
    });

    return () => {
      unsubScribe();
    };
  }, []);

  // listen to snapshot for all attendants
  React.useLayoutEffect(() => {
    const q = query(
      collection(db, "attendant-sales"),
      where("pinConfirmed", "==", true),
      where("refId", "!=", "40048")
    );

    const unsubScribe = onSnapshot(q, (snapshot) => {
      const data = snapshot.docs.map((doc) => ({
        name: doc.data().attendantName,
        id: doc.data().attendantId,
        value: parseFloat(doc.data().amountSpent),
        day: convertDate(doc.data().validationTime),
        litres: parseFloat(doc.data().litres),
      }));

      const res = Array.from(
        data
          .reduce((acc, { value, ...r }) => {
            const key = JSON.stringify(r);
            const current = acc.get(key) || { ...r, value: 0 };
            return acc.set(key, {
              ...current,
              value: parseFloat(current.value) + parseFloat(value),
            });
          }, new Map())
          .values()
      );

      setSalesData(res);
      setAllAttendantSales(res);
    });

    return () => {
      unsubScribe();
    };
  }, []);

  // custom tooltip
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <Box
          rounded="md"
          p={4}
          bg={
            staffType === "all"
              ? "green.400"
              : staffType === "active"
              ? "blue.400"
              : "red.400"
          }
        >
          <Text fontSize={"xs"}>{`${label}`}</Text>
          <Text fontSize={"xs"}>{`GH₵ ${payload[0].value}`}</Text>
          <Text fontSize={"xs"}> {`${payload[0]?.payload.litres} Ltrs`}</Text>
          <Text
            fontSize={"xs"}
          >{`${(payload[0]?.payload.name).toLowerCase()}`}</Text>
        </Box>
      );
    }

    return null;
  };

  return (
    <Box p={{ base: 5, md: 10, lg: 10 }}>
      {isLoading ? (
        <Center h="90vh">
          <Spinner color={"red.500"} />
          <Text color="gray.400">getting data ...</Text>
        </Center>
      ) : (
        <>
          {/*  attendants sales chart  */}

          <Box w="100%" py={10} rounded="md" bg="white" shadow="md">
            {/* header section with menu and active controllers */}
            <HStack
              px={5}
              mb={5}
              justifyContent="space-between"
              alignItems="flex-start"
            >
              <Box>
                {/* header */}
                <Heading mb={2} fontSize="xl">
                  Sales Breakdown
                </Heading>
                {/* active Controllers */}
                <HStack space={2}>
                  <Pressable
                    bg={staffType === "all" ? "green.500" : "green.300"}
                    py={1}
                    px={5}
                    rounded="sm"
                    onPress={() => {
                      setStaffType("all");
                      setSalesData(allAttendantSales);
                      setFilteredData([]);
                    }}
                  >
                    <Text color="white">all</Text>
                  </Pressable>

                  <Pressable
                    bg={staffType === "active" ? "blue.500" : "blue.300"}
                    py={1}
                    px={5}
                    rounded="sm"
                    onPress={() => {
                      setStaffType("active");
                      setSalesData(activeAttendantSales);
                      setFilteredData([]);
                    }}
                  >
                    <Text color="white">active</Text>
                  </Pressable>

                  <Pressable
                    bg={staffType === "inactive" ? "red.500" : "red.300"}
                    py={1}
                    px={5}
                    rounded="sm"
                    onPress={() => {
                      setStaffType("inactive");
                      setSalesData(inactiveAttendantSales);
                      setFilteredData([]);
                    }}
                  >
                    <Text color="white">inactive</Text>
                  </Pressable>
                </HStack>
              </Box>
              {/* Menu Button */}
              <Menu
                trigger={(triggerProps) => {
                  return (
                    <Pressable
                      accessibilityLabel="More options menu"
                      {...triggerProps}
                      flexDirection="row"
                      alignItems="center"
                      borderWidth={1}
                      px={5}
                      py={1}
                      rounded="sm"
                    >
                      <Text fontSize="md" color="gray.500">
                        {selectedFilter}
                      </Text>
                      <FiChevronDown color="gray" size={15} />
                    </Pressable>
                  );
                }}
              >
                {["This Week", "This Month", "This Year"].map((item) => {
                  return (
                    <Button
                      variant="ghost"
                      colorScheme="white"
                      rounded="none"
                      size="md"
                      onPress={() => {
                        setSelectedFilter(item);
                        if (item === "This Week") {
                          setFilteredData(
                            salesData
                              .filter(
                                (attendantData) =>
                                  new Date(attendantData.day) >=
                                    new Date(getFirstDayOfWeek()) &&
                                  new Date(attendantData.day) <=
                                    new Date(getLastDayOfWeek())
                              )
                              .sort((a, b) => new Date(a.day) - new Date(b.day))
                          );
                        } else if (item === "This Month") {
                          setFilteredData(
                            salesData
                              .filter(
                                (attendantData) =>
                                  new Date(attendantData.day) >=
                                    new Date(getFirstDayOfMonth()) &&
                                  new Date(attendantData.day) <=
                                    new Date(getLastDayOfMonth())
                              )
                              .sort((a, b) => new Date(a.day) - new Date(b.day))
                          );
                        } else if (item === "This Year") {
                          setFilteredData(
                            salesData
                              .filter(
                                (attendantData) =>
                                  new Date(attendantData.day) >=
                                    new Date(getFirstDayOfYear()) &&
                                  new Date(attendantData.day) <=
                                    new Date(getLastDayOfYear())
                              )
                              .sort((a, b) => new Date(a.day) - new Date(b.day))
                          );
                        }
                      }}
                    >
                      {item}
                    </Button>
                  );
                })}
              </Menu>
            </HStack>
            {/* total sales and total litres and attendant count */}
            <Stack
              flexDirection={"row"}
              justifyContent={"space-around"}
              w="100%"
              shadow="md"
              space={{ base: 5, md: 5 }}
              flexWrap="wrap"
            >
              {/* total sales  */}
              <Box px={4} py={3} bg="white" rounded="sm" mb={5}>
                <HStack alignItems="center" space="4" flexWrap={"wrap"}>
                  <Circle
                    size="sm"
                    bg={
                      staffType === "all"
                        ? "teal.100"
                        : staffType === "active"
                        ? "blue.100"
                        : "red.100"
                    }
                    my={2}
                  >
                    <FaRegMoneyBillAlt
                      size="1.5em"
                      color={
                        staffType === "all"
                          ? "green"
                          : staffType === "active"
                          ? "blue"
                          : "red"
                      }
                    />
                  </Circle>
                  <Box alignItems="flex-start">
                    <Text
                      fontSize="xs"
                      color={
                        staffType === "all"
                          ? "green.400"
                          : staffType === "active"
                          ? "blue.400"
                          : "red.400"
                      }
                    >
                      {staffType === "all"
                        ? "Total Attendant Sales"
                        : staffType === "active"
                        ? "Active Attendant Sales"
                        : "Inactive Attendant Sales"}
                    </Text>
                    <Heading
                      textAlign="center"
                      fontSize="lg"
                      color={
                        staffType === "all"
                          ? "green.700"
                          : staffType === "active"
                          ? "blue.700"
                          : "red.700"
                      }
                    >
                      GH₵{" "}
                      {filteredData.length > 0
                        ? filteredData
                            .reduce((a, b) => +a + +b.value, 0)
                            .toFixed(2)
                        : salesData
                            .filter(
                              (attendantData) =>
                                new Date(attendantData.day) >=
                                  new Date(getFirstDayOfMonth()) &&
                                new Date(attendantData.day) <=
                                  new Date(getLastDayOfMonth())
                            )
                            .reduce((a, b) => +a + +b.value, 0)
                            .toFixed(2)}
                    </Heading>
                  </Box>
                </HStack>
              </Box>
              {/* total liters  */}
              <Box px={4} py={3} bg="white" rounded="sm" mb={5}>
                <HStack alignItems="center" space="4" flexWrap={"wrap"}>
                  <Circle
                    size="sm"
                    bg={
                      staffType === "all"
                        ? "teal.100"
                        : staffType === "active"
                        ? "blue.100"
                        : "red.100"
                    }
                    my={2}
                  >
                    <BiGasPump
                      size="1.5em"
                      color={
                        staffType === "all"
                          ? "green"
                          : staffType === "active"
                          ? "blue"
                          : "red"
                      }
                    />
                  </Circle>
                  <Box alignItems="flex-start">
                    <Text
                      fontSize="xs"
                      color={
                        staffType === "all"
                          ? "green.400"
                          : staffType === "active"
                          ? "blue.400"
                          : "red.400"
                      }
                    >
                      {staffType === "all"
                        ? "Total Attendant Litres"
                        : staffType === "active"
                        ? "Active Attendant Litres"
                        : "Inactive Attendant Litres"}
                    </Text>
                    <Heading
                      textAlign="center"
                      fontSize="lg"
                      color={
                        staffType === "all"
                          ? "green.700"
                          : staffType === "active"
                          ? "blue.700"
                          : "red.700"
                      }
                    >
                      {filteredData.length > 0
                        ? filteredData
                            .reduce((a, b) => +a + +b.litres, 0)
                            .toFixed(2)
                        : salesData
                            .filter(
                              (attendantData) =>
                                new Date(attendantData.day) >=
                                  new Date(getFirstDayOfMonth()) &&
                                new Date(attendantData.day) <=
                                  new Date(getLastDayOfMonth())
                            )
                            .reduce((a, b) => +a + +b.litres, 0)
                            .toFixed(2)}
                      Ltrs
                    </Heading>
                  </Box>
                </HStack>
              </Box>
              {/* active and inactive attendants  */}
              <Box px={4} py={3} bg="white" rounded="md" mb={5}>
                <HStack alignItems="center" space="4" flexWrap={"wrap"}>
                  <ShakeLittle>
                    <Pressable onPress={() => navigate("/attendants")}>
                      {newAttendants.length > 0 && (
                        <Badge
                          position="absolute"
                          zIndex={5}
                          right={-5}
                          rounded="md"
                          colorScheme="red"
                        >
                          {newAttendants.length}
                        </Badge>
                      )}
                      <Circle size="sm" bg="blue.100" my={2}>
                        <GoPerson size="1.5em" color="blue" />
                      </Circle>
                    </Pressable>
                  </ShakeLittle>
                  <Box alignItems="flex-start">
                    <Heading textAlign="center" fontSize="lg">
                      {attendantSales.length > 0 ? attendantSales.length : 0}
                    </Heading>
                    <Text fontSize="xs" color="gray.400">
                      Attendants
                    </Text>
                  </Box>
                </HStack>
              </Box>
            </Stack>

            {/* sales line chart  */}
            <ResponsiveContainer width="100%" height={350}>
              {activeAttendantSales.length > 0 ? (
                <AreaChart
                  // width={500}
                  // height={500}
                  data={
                    filteredData.length > 0
                      ? filteredData
                      : salesData
                          .filter(
                            (attendantData) =>
                              new Date(attendantData.day) >=
                                new Date(getFirstDayOfMonth()) &&
                              new Date(attendantData.day) <=
                                new Date(getLastDayOfMonth())
                          )
                          .sort((a, b) => new Date(a.day) - new Date(b.day))
                  }
                  margin={{
                    top: 5,
                    right: 5,
                    left: 5,
                    bottom: 5,
                  }}
                >
                  <XAxis dataKey="day" />
                  <YAxis dataKey="value" />
                  <Tooltip content={<CustomTooltip />} />
                  {/* <Tooltip
                    formatter={(value, litres, name) => {
                      return `GH₵ ${value} ${litres}Ltrs  by ${name}`; 
                    }}
                  /> */}
                  <CartesianGrid
                    vertical={false}
                    strokeDasharray="3 3"
                    stroke="#aab8c2"
                  />
                  <Area
                    type="monotone"
                    dataKey="value"
                    fill={
                      staffType === "all"
                        ? "#26C55E"
                        : staffType === "active"
                        ? "#3B82F6"
                        : "#EF4444"
                    }
                  />
                </AreaChart>
              ) : (
                <Center w="100%" h="100%">
                  <Text color="gray.400">No Sales have been made yet</Text>
                </Center>
              )}
            </ResponsiveContainer>
          </Box>
        </>
      )}
    </Box>
  );
}
