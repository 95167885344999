import React from "react";
import { Routes, Route, Navigate } from "react-router";
import Login from "../pages/auth/Login";
import Error from "../pages/Error";

export default function AuthLayout() {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
}
