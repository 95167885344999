import React from "react";
import { useNavigate, useLocation } from "react-router";
import {
  Center,
  VStack,
  Stack,
  Text,
  Button,
  useToast,
  HStack,
  Box,
  ChevronLeftIcon,
} from "native-base";
import { CustomInput as Input } from "../../components /CustomInput";
import Swal from "sweetalert2";


// redux imports
import { useDispatch, useSelector } from "react-redux";
import { editAttendant } from "../../redux/middleware/attendantMiddleware";
import {
  editAttendantError,
  editAttendantSuccess,
  resetActionType,
} from "../../redux/reducers/AttendantReducer";

const Editattendant = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const toast = useToast();
  const attendantState = useSelector((state) => state.attendants);

  console.log(location.state);

  const [fullname, setFullname] = React.useState(location.state.fullname);
  const [phone, setPhone] = React.useState(location.state.phone);
  const [isVerified, setIsVerified] = React.useState(location.state.isVerified);

  //   handle  edit attendant
  const handleSubmit = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(
          editAttendant(location.state.attendantId, { fullname, phone })
        );
      }
    });
  };

  //  side effects of the redux actions
  React.useLayoutEffect(() => {
    if (attendantState.ACTION_TYPE === editAttendantSuccess.toString()) {
      toast.show({
        title: "Success",
        description: attendantState.editAttendantMessage,
        status: "success",
        duration: 3000,
        placement: "top",
      });
      dispatch(resetActionType());
      navigate("/attendants");
    } else if (attendantState.ACTION_TYPE === editAttendantError.toString()) {
      toast.show({
        title: "Error",
        description: attendantState.editAttendantMessage,
        status: "error",
        duration: 3000,
        placement: "top",
      });
      dispatch(resetActionType());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attendantState.ACTION_TYPE]);

  return (
    <Center py={10}>
      <VStack
        w={{ base: "90%", md: "70%" }}
        shadow="3"
        bg="white"
        rounded="md"
        overflow="hidden"
      >
        <Center bg="teal.500" p={4}>
          <Text fontSize="2xl" color="white">
            EDIT ATTENDANT
          </Text>

          <Button
            position="absolute"
            left={5}
            variant="outline"
            colorScheme="gray"
            size="sm"
            onPress={() => navigate(-1)}
          >
            <ChevronLeftIcon color="white" size="sm" />
          </Button>

          <Box
            position="absolute"
            right={5}
            bg={isVerified ? "green.500" : "red.500"}
            px={5}
            py={2}
            rounded="sm"
          >
            {isVerified ? "ACTIVE" : "INACTIVE"}
          </Box>
        </Center>

        <Stack py={10} alignItems="center" space={5}>
          <HStack w={{ base: "90%", md: "80%" }} space={5}>
            <Text>Attendant ID:</Text>
            <Text>{location.state.email}</Text>
          </HStack>
          <HStack w={{ base: "90%", md: "80%" }} space={5}>
            <Text>Email:</Text>
            <Text>{location.state.email}</Text>
          </HStack>

          <Input
            label="Full Name"
            value={fullname}
            onChangeText={setFullname}
            disabled={isVerified}
          />
          <Input
            label="Phone Number"
            value={phone}
            onChangeText={setPhone}
            disabled={isVerified}
          />

          <Button
            mt={5}
            isLoading={attendantState.isEditAttendantLoading}
            isLoadingText="Updating Staff"
            _loading={{ bg: "teal.500" }}
            onPress={handleSubmit}
          >
            UPDATE ATTENDANT
          </Button>
        </Stack>
      </VStack>
    </Center>
  );
};

export default Editattendant;
